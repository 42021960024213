.global-dashboard {
  .dateInput {
    border: none;
    &:hover {
      cursor: pointer;
    }
  }
  .fa-calendar {
    &:hover {
      cursor: pointer;
    }
  }
}
