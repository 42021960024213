app-header,
.header {
  header {
    background: $header-topbar-background-color;
    color: $font-color-navigation;
  }
  .top-bar {
    background: $header-background-color;
    .center-error-message {
      width: 50vw;
      height: 40px;
      position: absolute;
      left: calc(25vw - 1px);
      top: 3px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      .alert-danger {
        margin-top: 2px;
      }
    }
  }
  .main-nav {
    line-height: 50px;
    height: 50px;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        float: left;
        color: $font-color-navigation;
        padding: 0 10px;
        font-size: 1.05rem;
        font-weight: $font-weight;
        height: 50px;
        @include transition(all 0.2s ease-in-out);

        &:hover {
          cursor: pointer;
          background-color: darken($header-topbar-background-color, 5);
        }

        &.active {
          font-weight: 500;
          background-color: darken($header-topbar-background-color, 5);
        }

        &:last-child {
          border-right: 0;
        }

        span, i {
          display: block;
          height: 50px;
          line-height: 50px;
          text-decoration: none;
        }
      }
    }
  }
  app-toggle-switch {
    .toggle-switch {
      margin-top: 11px;
      float: right;
      padding-right: 30px;
    }
  }

  #hamburger-button {
    position: relative;
    z-index: 9999;
    display: inline-block;
    margin-top: 1.3rem;
    vertical-align: center;
    height: 15px;
    width: 20px;
    margin-left: 5%;
    &:hover {
      cursor: pointer;
    }
    > span {
      display: block;
      height: 3px;
      margin-bottom: 3px;
      width: 20px;
      background-color: black;
      -webkit-transition-timing-function: linear; /* Safari and Chrome */
      transition-timing-function: linear;
      transition-duration: .250s;
    }
    &.open {
      > span {
        &:first-child {
          -webkit-transform: translateY(6px) rotate(45deg); /* Chrome, Safari, Opera */
          transform: translateY(6px) rotate(45deg);
        }
        &:nth-child(2) {
          -webkit-transform: rotateY(90deg); /* Safari */
          transform: rotateY(90deg);
        }
        &:last-child {
          -webkit-transform: translateY(-6px) rotate(-45deg); /* Chrome, Safari, Opera */
          transform: translateY(-6px) rotate(-45deg);
        }
      }

    }

  }
  .burger-wrapper {
    background: white;
    opacity: 0.9;
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 885;
    top: 0;
  }
  .burger-nav {
    position: absolute;
    z-index: 888;
    height: 50vh;
    width: 300px;
    left: 0;
    top: -50vh;
    transition: 350ms;
    background-color: white;
    &.open {
      top: 0;
      height: 100vh;
      box-shadow: $shadow-base-div;
    }
    ul {
      list-style: none;
      margin-top: 40px;
      padding-left: 0px;
      &.hidden {
        display: none;
      }
      li {
        padding: 5px;
        text-align: left;
        font-size: 1.25rem;
        text-transform: uppercase;
        font-weight: 600;
        width: 300px;
        color: $font-color;
        text-align: center;
        &:hover {
          cursor: pointer;
          background: #fefefe;
          font-weight: 800;
        }
      }
    }
  }

  .product-logo {
    margin: 5px;
    height: 40px;
    width: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
    img {
      height: 28px;
    }
  }

  .btn-group {
    position: relative;
    float: right;
    margin-top: 10.5px;
    .toggle-btn {
      height: 30px;
      line-height: 30px;
    }
  }

  .top-bar {
    position: absolute;
    top: 50px;
    left: 0px;
    right: 0px;
    padding: 10px;
    height: 50px;
    padding: 5px 10px;
    background-color: $header-background-color;

    .mobile-logo {
      img {
        height: 35px;
      }
    }

    .firm-logo {
      float: right;
      margin-top: 2px;

      img {
        height: 30px;
      }
    }

    .controls {
      float: right;
    }
    .master-view {
      border: none;
      position: absolute;
      min-width: fit-content;
      width: 12vw;
      height: 30px;
      right: calc(50% - 12vw/2);
      margin-top: 3px;
    }

  }

  header {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    background: $header-topbar-background-color;
    height: 50px;
    line-height: 40px;

    .cart-btn {
      float: right;
    }

    .nx-dropdown-button {
      float: right;
      margin-right: 20px;
    }
  }

  .current-user {
    float: right;
    margin-right: 25px;

    i {
      color: $ci-color-5;
      font-size: 20px;
      float: left;
      padding-top: 6px;
    }

    div {
      display: inline-block;
      float: left;
      font-size: 16px;
      font-weight: bold;
      color: $ci-color-8;
      padding: 5px 0 0 5px;
    }
  }

}

