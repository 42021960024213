.custom-dropdown {
    background-color: transparent !important;
    border-radius: 0px;
    overflow: hidden;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1rem;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid lighten($font-color,50);
    &.disabled {
        background-color: transparent;
        select {
            background-color: transparent;
            border: none;
        }
        &:after {
            display: none;
        }
    }
    select,
    &:after {
        background: transparent;
    }
    &:after {
        content: "\f0d7";
        font-family: "FontAwesome", sans-serif;
        color: $font-color;
        position: absolute;
        right: 1rem;
        top: 1rem;
        background: transparent;
        font-size: 1.5rem;
        z-index: 1;
        text-align: center;
        width: 2rem;
        height: 100%;
        pointer-events: none;
        box-sizing: border-box;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    select {
        border: none;
        width: 100%;
        color: $font-color;
        background-color: transparent;
        text-align: left;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0px;
        outline: none;
        padding: 1rem;
        padding-left: 0;
        padding-bottom: 0.6rem !important;
        font-weight: 400 !important;
        option {
            font-weight: normal;
            line-height: 1.1em;
            white-space: nowrap;
            -webkit-appearance: button;
            text-overflow: ellipsis;
            background: transparent;
            padding: 0 15px;
            margin: 0 5px;

            &:disabled {
                color: $ci-color-16;
                font-weight: bold;
            }
        }

        &:active {
            border-bottom: 0;
            outline: none;
        }

        &:disabled {
            color: $ci-color-16;
        }

        &:hover {
            outline: none;
            //box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
        }
    }

}
