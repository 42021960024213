.global-country-config {
  .add-country {
    float: right;
  }

  .country-row {
    border-top: 1px solid $color-grey;
    padding-top: 10px;
    padding-bottom: 10px;
    .fa-pencil {
      font-size: 1.3rem;
      cursor: pointer;
    }
    .country-options:hover {
      color: $highlight-color;
    }
    &:last-child {
      border-bottom: 1px solid $color-grey;
    }
  }
}
