.article-number {
  .checkbox {
    margin-top: 15px;
  }
}

.print-settings {
  position: relative;
  transition: all 0.5s;
  &.move-up {
    // 5 rem = navbar height
    transform: translateY(calc(-100% + 5rem));
  }

  &.move-down {
    transform: translateY(0%);
  }
}