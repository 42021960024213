app-club-setting,
.club-setting {
  .club-setting-title {
    margin: 0px -12px 0px;
    cursor: pointer;
    h1{
      padding: 10px;
      border-bottom: 2px solid $font-color;
      strong{
        font-weight: 600;
        .fa-angle-down,
        .fa-angle-up {
          float: right;
          font-size: 1.8rem;
        }
      }
    }
  }

  .clickable {
    cursor: pointer;
  }
}
