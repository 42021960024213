.assembly-categories,
.work-categories,
.technical-categories{
  .assemblyTitle {
    padding-bottom: 10px;
    padding-left: 3px;
    font-weight: bold;
    font-size: 18px;
  }
  padding-bottom: 10px;
  margin-right: $panel-right-margin;
  .assembly-category,
  .work-category,
  .technical-category{

    width: 32%;
    margin: 3px 0.5%;
    display:inline-block;
    cursor: pointer;
    position: relative;
    transition: all 150ms ease-in;
    height: 120px;
    text-align: center;
    border-radius: 2px ;
    background: darken($background-color,3) ;
    color: $ci-color-11;
    border:1px solid darken($background-color,5);

    &.selected{
      background: $highlight-color !important;
      color: $highlight-color-contrast!important;
      border: none;
    }
    &:hover{
      color: $highlight-color;
      z-index: 2;
      box-shadow: 1px 3px 6px rgba(0,0,0,0.3);
    }
    &.inactive {
      color: lighten($color-black,50) !important;
      background:transparent !important;
      box-shadow: none;
      cursor: not-allowed !important;
      opacity: 0.5;
    }
    &:hover:not(.inactive),
    &:hover:not(.selected) {
      color: $highlight-color;
      img{

      }
    }
    &:hover,
    &.inactive{
      .image-wrapper{
        display: none!important;

      }
      .image-wrapper-hover{
        display: inherit !important;

      }
    }
    .image-wrapper-hover{
      display: none;

    }
    .image-wrapper,
    .image-wrapper-hover{
      font-size: 6rem;
      float: left;
      height: 80%;
      text-align: center;
      position: absolute;
      left: 0px;
      top: 10px;
      right:0;
      margin:0px auto;
      z-index: 300;

      &.body-graphic {
        margin-top: -10px;
        font-size: 9rem;
      }

      .center-helper {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }

    }
    .name-wrapper{
      width: 100% !important;
      vertical-align: middle;
      z-index: 500;
      position: absolute;
      bottom:0px;
      font-weight: 400;
      float: left;
      word-wrap: break-word;
    }

    .name-wrapper-no-image {
      width: 100% !important;
      vertical-align: middle;
      height: 60%;
      z-index: 500;
      position: absolute;
      bottom: 0px;
      left: 0;
      color: $font-color;
      font-weight: 400;
      float: left;
      word-wrap: break-word;
    }
  &.tile-disabled {
    cursor: default;
    pointer-events: none;
    .name-wrapper-no-image {
      color: #bababa;
    }
    &:hover {
      box-shadow: none;
    }
  }
}
}
.articles {
  .assembly-categories {
    margin-right: 0;
  }
}
