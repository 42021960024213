.logo {
  .file-input {
    background-color: transparent;
    border-bottom: none;
  }

  .option_info {
    margin-left: 28px;
    margin-bottom: 10px;
    margin-top: -10px;
  }

  .spacer {
    margin-bottom: 25px;
  }

  hr {
    margin-top: 0px;
  }

  .subHeader {
    font-size: 1.2rem;
  }
}
