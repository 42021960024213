app-article-toggle{
  .iconbox{
    color: $color-white;
    line-height: 3rem;
    margin-left: 1rem;
    display: inline-flex;
    margin: 0px auto;
    width: 170px;
    left: 43vw;
    right: 43vw;
    position: absolute;
    top: 0px;
    height: 50px;
    .router{
      padding: 5px;
      &:hover{
        cursor: pointer;
        background: lighten($header-background-color,10);
      }
      i{
        font-size: 2.8rem;
      }
      .count{
        position: relative;
        top: -20px;
        left: -10px;
        font-weight: 900;
        font-size: 1.4rem;
        color: $color-success;
      }
      .description{
        float: right;
        line-height: 40px;
        display: block;
      }
    }
  }


}
