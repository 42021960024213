.suppliers {

  .clickable {
    cursor: pointer;
  }

  .club-setting-title {
    margin: 12px 6px 0px;
    cursor: default;
  }

  .supplier-head {
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 300;
    margin-left: -12px !important;
    margin-right: -12px !important;
  }
  #SPECIAL, #STANDARD {
    min-height: 50px;
  }
  .drag-list {
    width: auto;
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: 0 !important;
    .supplier-list {
      margin-left: 0 !important;
      margin-right: 0 !important;
      .customer-number {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &.disabled {
      border-top: none;
      .supplier-list {
        cursor: not-allowed;
      }
      .row:first-child {
        .col-1 {
          &:first-of-type {
            &:before {
              content: "";
            }
          }
        }
      }
    }
    .row:first-child {
      .col-1 {
        &:first-of-type {
          &:before {
            font-family: FontAwesome;
            content: "\f005";
            color: $font-color;
            font-size: 1.5rem;
            margin-left: 16px;
            vertical-align: sub;
          }
        }
      }
    }
  }
  .drag-list#SPECIAL{
    .row:first-child {
      .col-1 {
        &:first-of-type {
          &:before {
            content: '';
          }
        }
      }
    }
  }

  .center-wrapper {
    margin-left: auto;
    margin-right: auto;
    width: 88px;
    i {
      margin-right: 0;
    }
  }
}
