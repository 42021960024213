//=== Icons for Availability ===//

$available: url('~assets/images/global/article-available.png') no-repeat top left;
$not-available: url('~assets/images/global/article-not-available.png') no-repeat top left;
$other-shop: url('~assets/images/global/article-other-shop.png') no-repeat top left;
$asap: url('~assets/images/global/article-asap.png') no-repeat top left;
$connection-broken: url('~assets/images/global/article-connection-error.png') no-repeat top left;
$special-order: url('~assets/images/global/article-special-order.png') no-repeat top left;
$own-stock: url('~assets/images/global/article-own-stock.png') no-repeat top left;
$invalid-credentials: url('~assets/images/global/user-cancel.svg') no-repeat top left;
$backorder: url('~assets/images/global/article-backorder.png') no-repeat top left;
