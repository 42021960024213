.assortment {


  input {
    &::placeholder {
      color: $font-color !important;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $font-color !important;
    }

    &::-ms-input-placeholder {
      color: $font-color !important;
    }
  }

  .search-icon {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .no-results {
    text-align: center;
    font-size: medium;
    padding-top: 10px;
  }

  .delete-confirmation {
    border-top: 1px solid $color-grey-light;
  }

  .btn-group {
    display: inline-block;
  }

  .btn-group .base-btn {
    clear: none;
    float: left;
    display: inline-block;
    line-height: 2rem;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &.active {
      background: $highlight-color;
      color: $highlight-color-contrast;
    }
  }

  .deactivated-assortments {
    opacity: 0.6 !important;
    filter: grayscale(100%) !important;
  }

  .grouped {
    .base-btn {
      cursor: default !important;
      opacity: 0.4 !important;
      filter: grayscale(100%) !important;
    }
  }


  .drag-box {
    cursor: default !important;
    padding: 5px;

    &:hover {
      background-color: darken($background-color, 3) !important;
    }

    .fa-trash:hover {
      color: $color-danger;
    }

    [class*="col"] {

      .checkbox {
        label {
          margin-bottom: 0;
        }
      }

      .info-text {
        display: none;
      }

      &:hover {
        .info-text {
          cursor: pointer !important;
          display: block;
        }
      }
    }
  }
}