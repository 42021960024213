/*
      TODO: NEW THEME

      1. open ../styles/app.scss
      2. define new theme as class
         sample: body.themename {}
      3. import theme scss file in new class:
         sampple: @import '../theme/themename';

      ============
      result:

        body.themename {
          @import '../theme/themename';
        }

      ============
*/

// ======================= LOAD GLOBAL ELEMENTS =========================== //

@import '../styles/modules/all';

// =========================== CUSTOM STYLES ============================== //


/*
  Posible variable can be found in following SCSS Files:

  ../styles/modules/_colors.scss
  ../styles/modules/_fonts.scss
  ../styles/modules/_variables.scss
 */

.logo,
.product-logo{
  background-image: url("/assets/images/theme/automeister.png");
}

.top-bar {
  .fa-caret-down {
    top: 0!important;
  }
}
$header-background-color: #252121;
$highlight-color: #60B55A;

$car-selection-background: url('/assets/images/theme/backgrounds/pexels-photo.jpg') no-repeat center center fixed;

// @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');
$fallback: sans-serif;
$main-font: 'Roboto', $fallback;
$condensed-font: 'Roboto', $fallback;
$font-size: 13.5px;
$font-weight: 400;





// ================================ LAST ELEMENTS ========================== //

/*               MUST BE LOADED IN EVERY THEME AFTER OVERWRITES              */

// ======================================================================== //


@import 'includes';
