.cart-position-row {
  border-bottom: 1px solid #cccccc;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 0;
  margin-left: 0;

  .cursor-pointer {
    cursor: pointer;
  }

  .fit-content {
    height: fit-content;
  }

  .edit-additional-name {
    .material-form-input {
      display: inline-block;
      input {
        width: 14rem;
      }
    }
    .fa-check {
      position: absolute;
      top: 5rem;
      left: 15rem;
      cursor: pointer;
    }
    .fa-times {
      position: absolute;
      top: 5rem;
      left: 18rem;
      font-size: 1.6rem;
      color: $color-danger;
      cursor: pointer;
    }
  }

  .preorder-wrapper {
    margin-left: -15px;
    margin-right: -15px;
    border-top: 1px solid $color-grey-light;
    padding-top: 1rem;
    .dealer {
      .position-wrapper {
        .already-ordered {
          font-weight: bold;
          font-size: 1.1rem;
        }
        .preorder-position {
          border-bottom: 1px solid $color-grey-light;
          margin-left: 0;
          margin-right: 0;
          padding-top: 1rem;
          padding-bottom: 1rem;
          .dealer-logo {
            display: inline-block;
            width: 95px;
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
    .show-more-button {
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
      margin-bottom: 0.5rem;
      cursor: pointer;
      text-align: center;
      background-color: darken(white, 5);
    }
  }

  .article-dealer {
    border-top: 1px solid $color-grey-light;
    padding-top: 10px;
    padding-bottom: 5px;
    margin-top: 5px;

    .cursor-default {
      cursor: default;
    }

    .dealer {
      line-height: 30px;
      min-height: 30px;
      .already-ordered-count {
        right: 0;
        bottom: 0;
        position: absolute;
      }
      .edit-icon {
        display: inline-block;
      }
      .dealer-logo {
        display: inline-block;
        .dealer-image {
          img {
            height: 25px;
          }
          display: inline-block;
        }
      }
      .availability-panel {
        display: inline-block;
      }
    }
  }

  .col, .col-1, .col-2, .col-3, .col-4 {
    padding-left: 0;
    padding-right: 0;
    .custom-checkbox {
      position: relative;
      top: calc(50% - 11px);
      label {
        margin-left: 8px;
      }
    }

    .sellin-price {
      position: relative;
      top: calc(50% - 18px);
    }
    .sellout-price {
      position: relative;
      top: calc(50% - 10px);
    }
    .total-sellin-price {
      position: relative;
      top: calc(50% - 18px);
    }
    .total-sellout-price {
      position: relative;
      top: calc(50% - 10px);
    }

    .cart-amount-input {
      position: relative;
      top: calc(50% - 10px);
    }

    .quantity-wrapper {
      height: 30px;
      position: relative;
      top: calc(50% - 15px);
    }
  }
  .fa-exclamation-triangle {
    font-size: 1.8rem;
    color: $color-danger;
    position: relative;
    top: calc(50% - 10px);
  }
  .fa-check {
   font-size: 1.8rem;
    color: $color-success;
    position: relative;
    top: calc(50% - 10.5px);
  }
  .cart-table-icon {
    font-size: 1.8rem;
    color: #696F71;
    position: relative;
    top: calc(50% - 12px);

    &.fa-trash-o {
      &:hover {
        cursor: pointer;
        color: darken(#696F71, 10);
      }
    }
  }

  .additional-description {
    color: $color-grey;
  }
}

