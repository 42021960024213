$header-topbar-height: 60px;

$footer-height: 40px;
$footer-gap: 30px;

$logo-width: 100%;

$form-element-height: 39px;
$form-element-fontsize: 14px;

$tab-width: 200px;
$tab-height: 40px;

$colored-border-thickness: 2px;

$shadow-base:0px 17px 20px 3px rgba(0,0,0,0.2), 0px 10px 0px rgba(0,0,0,0.2);
$shadow-base-light: 0px 1px 3px rgba(0,0,0,0.12), 0px 1px 2px rgba(0,0,0,0.24);
$shadow-base-extra-light: 0px 0px 0px rgba(0,0,0,0.12), 0px 1px 0px rgba(0,0,0,0.24);
$shadow-base-div: 0px 1px 10px rgba(0,0,0,0.12), 0px 1px 10px rgba(0,0,0,0.24);
$shadow-base-car: 0px 4px 20px 3px rgba(0, 0, 0, 0.25);
$shadow-base-card: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);

$car-selection-background: $color-white;
$card-bg: $color-white;

$button-top-margin: 2rem;
$panel-right-margin: -1%;