.global-orderhistory {
  .margin {
    margin-top: 210px;
    padding-top: 0;

    .app-loading {
      height: 74vh;
    }

    &.hasResuls {
      border-top: 1px solid $color-grey-light;

      .global-order-row {
        li {
          &:first-child {
            border-top: none;
          }
        }
      }
    }

    &.showPagination {
      margin-bottom: 40px;
      padding-bottom: 0;
    }
  }

  .order-name {
    font-weight: 600;
    font-size: 1.2rem;
  }

  .fa-shopping-cart {
    font-size: 2rem;
  }

  .base-btn {
    margin-top: $button-top-margin;

    &.success {
      margin-left: 10px;
    }
  }

  .dropdown-container {
    margin-top: 8px;
  }

  .global-pagination {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: $background-color;
    border-top: 1px solid darken($background-color, 20);
    height: 40px;
  }

  .historySearchBar {
    margin-left: 15px;
    margin-right: 15px;

    .base-btn {
      margin-top: 0 !important;
    }

    .custom-search-dropdown-input {
      .dropdown-container {
        display: inline-block;
        width: 170px;
      }
      .material-form-input {
        display: inline-block;
        margin-left: 10px;
        width: calc(100% - 180px);
      }
    }

    h1 {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }

  .min-height-500 {
    min-height: 500px;
  }
}
