.work-articles {
  app-no-results {
    .no-results {
      .panel-content {
        top: 20vh;
      }
    }
  }

  .skip {
    float: right;
    margin-top: 130px;
    .fa-angle-double-right {
      font-size: 1.5rem;
      padding-left: 10px;
      padding-bottom: 2px;
    }
  }

  .filterBar {
    min-height: 30px;
    background-color: #fff;
    margin-top: 10px;
    display: inline-block;
    width: calc(50vw - 12px);
    margin-left: -12px;
    .fa-angle-down,
    .fa-angle-up {
      font-size: 20px;
      margin: 5px 5px 0 5px;
      line-height: 26px;

      position: absolute;
      top: 0;
      right: 20px;
      &:hover {
        cursor: pointer;

      }
    }

    .chipBar {
      width: 50vw;
      padding-left: 10px;
      border-top: 1px solid #cccccc;
      padding-top: 5px;

      &.open {
        width: calc(50vw - 10px);
        padding-top: 20px;
      }
      .filterType {
        margin-left: 10px;
        font-weight: bold;
        border-bottom: 1px solid #c1c1c1;
        padding-bottom: 10px;
        padding-top: 10px;
        &:nth-last-child(1) {
          border-bottom: none;
        }
        &:nth-child(0) {
          padding-top: 0px;
        }
      }
    }
  }

  .no-results {
    .panel-content{
      top:45vh;
    }
  }

  .buttonBar{
    text-align: right;
    animation: all 1s ease-in-out;
    margin-bottom: 30px;
    button {
      margin-right: 1rem;
    }
  }

  .search-result {
    margin-top: 20px;
  }
}