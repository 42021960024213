table {
    width: 100%;
}

.simple-table {
    width: 100%;

    thead {
        th {
            border-bottom: 1px solid $ci-color-10;
            color: $font-color;
            padding: 1.25rem 0.5rem;
            font-weight: 300;

            &:first-child {
                padding-left: 15px;
            }

            &:last-child {
                padding-right: 15px;
            }
        }
    }

    tbody {

        tr {
            &:nth-child(odd) {
                background-color: $ci-color-odd;
            }

            &:nth-child(even) {
                background-color: $ci-color-even;
            }
            &:hover{
                background: darken($background-color,3);
            }
            td {
                border-bottom: 1px solid $ci-color-10;
                line-height: 1rem;
                padding: 0.8rem 0.5rem;
                font-weight: 400;
                color: $font-color-dark;
                &:first-child {
                    padding-left: 15px;
                }

                &:last-child {
                    padding-right: 15px;
                }
            }
        }
    }
    &.first-highlight{
        thead {
            th {
                border-bottom: none !important;
            }
        }
        tbody > tr:first-child {
            &.tableDragBox {
                td{
                    &:first-of-type{
                        border-top-left-radius: 5px;
                        &:before {
                            font-family: FontAwesome;
                            content: "\f005";
                            color: $font-color;
                            font-size: 1.5rem;
                        }
                    }
                    &:last-of-type {
                        border-top-right-radius: 5px;
                    }
                    background: darken($background-color,10);
                }
            }
        }
    }
}
