/*
      TODO: NEW THEME

      1. open ../styles/app.scss
      2. define new theme as class
         sample: body.themename {}
      3. import theme scss file in new class:
         sampple: @import '../theme/themename';

      ============
      result:

        body.themename {
          @import '../theme/themename';
        }

      ============
*/

.articles {
  .filterBar {
    margin: 0px;
    width: 100% !important;
  }
}

// ======================= LOAD GLOBAL ELEMENTS =========================== //

@import '../styles/modules/all';

// =========================== CUSTOM STYLES ============================== //


/*
  Posible variable can be found in following SCSS Files:

  ../styles/modules/_colors.scss
  ../styles/modules/_fonts.scss
  ../styles/modules/_variables.scss
 */

.logo,
.product-logo {
  //todo: change image
  background-image: url("/assets/images/theme/anag.png");
}

.car-selection .vin-search .material-form-input {
  max-width: 270px !important;

  input {
    padding-left: 5px !important;
  }
}

.car-selection .registration-ui input {
  border-bottom: 1px solid !important;
}

$anag_white: white;
$anag_blue: #002855;
$anag_dark_blue: #002855; //0e487b
$anag_light_blue: #3C6491;
$header-background-color: $anag_blue;
$header-font-color: $anag_white;
$highlight-color: #002855;
$color-red: #C6162D;
$border-radius: 2px;

$car-selection-background: url('/assets/images/theme/backgrounds/tim-mossholder-680992-unsplash.jpg') no-repeat center center fixed;

$fallback: sans-serif;
$main-font: 'Roboto', $fallback;
$condensed-font: 'Roboto', $fallback;
$font-size: 13px;
$font-weight: 400;


.price {
  color: $highlight-color;
}

.left-sidebar {
  .tab-content {
    margin-top: 30px !important;
  }

  .justify-content-start {
    position: fixed;
    width: calc(25vw - 1px);
    top: 110px;
    background: white !important;
    z-index: 100;
    background-color: white !important;

    li {
      width: 50% !important;
    }
  }
}

.autovista .headerBar .carButton {
  width: 75px !important;
}

/*
  Header Bar color and border
 */
.center-panel {
  box-shadow: none !important;
  border-left: 5px solid $header-background-color;
  border-right: 5px solid $header-background-color;

  app-order {
    display: contents;
  }

  .item-list {
    .order-item.selected {
      border-left-width: 0.5rem;
      border-right-width: 0.5rem;
    }
  }
}

.cart-btn {
  background-color: $anag_light_blue !important;

  &:hover {
    background-color: $anag_dark_blue !important;
    color: white !important;
  }
}

.header {
  .main-nav ul li:first-of-type {
    &:hover {
      background-color: white !important;
    }
  }

  header {
    .user-menu.notification-button.noMessages .fa-envelope {
      color: $anag_blue !important;
    }

    .toggle-switch input#toggle + label {
      background-color: $anag_white !important;
    }
  }

  .toggle-switch {
    .textLeft,
    .textRight {
      font-weight: 800;

      &.active {
        color: $anag_dark_blue !important;
        display: inline-block;
        font-weight: 800;
      }
    }
  }

  .main-nav ul li {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
}

.top-bar {
  background: $anag_dark_blue !important;

  .search-field {
    right: -9px;
    width: calc(25vw - 2rem);

    .fa-caret-down {
      top: 0 !important;
    }
  }

  button,
  input,
  car-history-dropdown,
  .selected,
  .fa {
    border: none !important;
    color: $header-font-color !important;
    font-weight: 400 !important;
    font-size: $font-size !important;
  }

  .direct-search {
    .selected,
    .fa {
      color: $font-color !important;
    }

    .search-field {
      i.fa-search {
        color: $header-font-color !important;
        padding: 28px 0 0 8px !important;

        &:before {
          margin-top: -21px;
          left: 7px;
        }
      }
    }
  }

  .car-info {
    .fa {
      color: $font-color !important;
    }
  }

  .car-history-dropdown .nx-dropdown-menu {
    top: 50px !important;
  }

  button:focus {
    border: none !important;
  }

  input,
  select {
    border: 1px solid $anag_white !important;
    padding-left: 1rem !important;
    border-top-right-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    @include placeholder {
      color: transparentize($header-font-color, .5) !important;
    }
  }

  .fa-search {
    border-left: 1px solid $anag_white !important;
    height: 100%;
    width: 15px;
  }

  .search-history {
    top: 30px !important;
    width: 90% !important;
  }
}

.direct-search {
  .search-field {
    .fa-search {
      width: 31px;
      color: $header-font-color !important;
    }
  }
}

.cartSearch {
  .material-form-input {
    margin-top: 0px !important;
  }

  .fa-search {
    margin-right: 30px !important;
  }
}

/*
  FONT
 */
.material-form-input label,
.name-wrapper-no-image, .name-wrapper,
.collapsible-panel .collapsible-panel-header .text,
.car-details-header table td,
.header .main-nav ul li,
.heading,
.nx-dropdown-menu ul li,
.search-history ul li,
.item-list li,
.right-panel-empty span,
.nx-dropdown-button span.selected,
.nav.nav-tabs .nav-link,
.maintenance-header,
.work-header,
button, input, optgroup, select, textarea,
.collapsible-panel .collapsible-panel-content {
  font-weight: 400 !important;
  font-family: $main-font;
  font-size: $font-size !important;
}

.sidebar-opener-wrapper {
  .sidebar-opener {
    ul li {
      color: $color-white !important;
    }
  }
}

ul.custom-tree {
  li {
    font-size: $font-size !important;

    .open {
      color: $color-red !important;
    }
  }
}

.app-articles-row {
  .col-2 {
    .price {
      color: $highlight-color;
    }
  }
}

/*
center panel header in line
 */
.center-panel {
  .heading {
    top: 0px !important;
    padding-bottom: 5px !important;
    height: 45px;
    line-height: 45px;
    color: $font-color;
    background: white;
    border-bottom: 1px solid $header-background-color;
  }

  .center-content {
    top: 50px !important;
    height: calc(100vh - 100px - 7rem - 4px) !important;

    &.show-quick-filter {
      top: 140px !important;
      height: calc(100vh - 130px - 7rem) !important;
    }
  }
}

/*
left panel
 */
.nav.nav-tabs {
  margin-top: -10px !important;

  a {
    text-align: left !important;

    &.disabled {
      display: none;
    }
  }
}

/*
right panel
 */

.right-sidebar,
.right-sidebar {
  background: #f2f2f2 !important;

  .car-details .car-name {
    font-weight: 500 !important;
    margin: 10px 0px 10px 0px !important;
  }

  .article-details-header {
    margin-top: 10px;
  }
}

/*
selected row
 */
.availability-panel .quantity-change {
  line-height: 32px;
}

app-articles-search-result,
.app-articles-search-result {
  .row.selected {
    border-left: 0px !important;;
    border-right: 0px !important;;
    background-color: #f2f2f2 !important;
  }
}

.articleSize {
  cursor: pointer;

  .rowSizeSelect {
    min-width: 80px !important;
  }
}

.articles .searchResultSupplier {
  &::after {
    position: relative !important;
    right: 5px !important;
  }
}

.searchResultSupplierSelect {
  margin-right: 5px !important;
}

.heading {
  button {
    margin-top: -4px;
  }
}

.maintenance-header {
  padding-top: 10px !important;
}

.card.warning-recall-text {
  background: $color-red !important;
  color: $anag_white !important;
}

.expired {
  .card.warning-recall-text {
    background: $anag_light_blue !important;
    color: $highlight-color !important;
  }
}

.assembly-categories .assembly-category,
.assembly-categories .work-category,
.assembly-categories .technical-category,
.work-categories .assembly-category,
.work-categories .work-category,
.work-categories .technical-category,
.technical-categories .assembly-category,
.technical-categories .work-category,
.technical-categories .technical-category {
  color: $anag_light_blue !important;

  .name-wrapper-no-image {
    color: $anag_light_blue !important;
  }

  &:hover {
    color: $anag_dark_blue !important;

    .name-wrapper-no-image {
      color: $anag_dark_blue !important;
    }
  }
}


.work-header {
  margin: -12px 0px 0px 0px !important;
}

.flex-container {
  width: 100%;

  .static-content {
    width: 100%;
  }
}

.maintenance-data {
  .innerTabContent {
    margin: 10px 20px 0 10px !important;
  }
}

.maintenance-header,
.work-header {
  border-bottom: 1px solid $anag_blue !important;
}

.maintenance-data,
.work-data {
  .innerTabContent {
    padding-top: 55px !important;
  }

  .tabs {
    .viewport {
      border-bottom: none !important;

      .tabList {
        .listItem {
          border-radius: 15px;
          padding: 5px 15px;
          border: 1px solid #ccc;
          background: white;
          color: #4a4a4a;
          margin-right: 5px;
          font-weight: 600;

          &:after {
            display: none;
          }

          &.finished {
            //line-height: 1rem;
            border-color: $color-success;
            background-color: $color-success;
            color: white;
            border: 1px solid $color-success !important;
          }

          &.selected {
            //line-height: 2rem;
            border: 1px solid #ccc;
            margin-top: 6px !important;
            max-height: 32px !important;
            color: white;
            background-color: $anag_blue;
            box-shadow: none !important;

            &:hover {
              background-color: $anag_blue !important;
            }
          }

          &:first-child {
            margin-left: 1rem;
          }

          &:last-child {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

/*
  Firm Inputs
 */

.verbundarbeit {
  input {
    margin-top: 14px !important;
  }
}

input, optgroup, textarea {
  border: 1px solid !important;
  border-color: #a0a0a0 !important;
}

.show-search {
  input {
    padding-left: .5rem !important;
    margin-top: .5rem;
  }
}

.heading {
  #search-open,
  .fa-times {
    margin-top: .5rem !important;
    border: 0 !important;
    padding: 3px 12px 3px 8px !important;
    border-left: 0px !important;
    border-top-right-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    font-size: 1em !important;
    color: #a0a0a0 !important;
  }
}

.material-form-input {
  label {
    left: 10px !important;
  }
}

.amount-controls {
  input {
    border: 0px !important;
  }
}

.verbundarbeit label input {
  position: relative;
  top: 5px;
}

.home {
  .card {
    .logo {
      margin: 3rem auto 2rem auto !important;
    }

    padding-bottom: 1rem;
  }
}

// ================================ LAST ELEMENTS ========================== //

/*               MUST BE LOADED IN EVERY THEME AFTER OVERWRITES              */

// ======================================================================== //


@import 'includes';
