[ng-reflect-ngb-tooltip] + .tooltip {
  z-index: 99999 !important;
  .tooltip-arrow{
    top: 2.5rem;
  }
  .tooltip-arrow,
  .tooltip-inner{
    //background-color: white !important;
    //color: $font-color !important;
    padding: 1rem;
    z-index: 99999 !important;
  }
}