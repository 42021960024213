.preload-car-selection {

  .pre-selected-ktype-car {
    .row {
      margin-bottom: 0;
    }
  }

  .row {
    margin-bottom: 1rem;
  }

  .left.row {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .base-btn {
    i.fa.fa-check {
      vertical-align: middle!important;
    }
    span {
    font-size: 12px;
    }
  }
  i.fa.fa-car::before {
    font-size: 22px;
  }
  .col-9 {
    span {
      vertical-align: middle;
    }
  }
}
