.noData {
  display: block;
  cursor: not-allowed !important;
  opacity: 0.5;
  &:hover {
    opacity: 1;
    cursor: not-allowed !important;
  }
}

.changeHeader {
  margin-left: 15px;
}