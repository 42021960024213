.registration-ui {

  .material-form-input {
    margin-left: 10px;
  }

  background: linear-gradient(to bottom, #f8d038 0%, #f5ca2e 100%);
  padding: .25em 1em .25em 1.75em;
  font-weight: bold;
  font-size: 2em;
  border-radius: 5px;
  border: 1px solid #000;
  box-shadow: 1px 1px 1px #ddd;
  position: relative;
  font-family: helvetica, ariel, sans-serif;
  margin-bottom: 1rem;

  input {
    background: transparent !important;
    font-weight: 900 !important;
    font-size: 1.5rem !important;
    border-bottom: none !important;
  }


  //hr bei Schweiz nicht blau machen, blau nur bei EU Ländern
  .registration-ui:not(.ch) hr {
    background: #063298 !important;
  }


  &:before {
    text-align: center;
    content: ' ';
    display: block;
    width: 40px;
    height: 100%;
    background: #063298;
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    font-size: .75em;
    line-height: 100px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    border-radius: 30px;
    border: 1px dashed #f8d038;
  }


  &.france {
    &:before {
      content: 'F';
      color: white;
    }

    background: white;
  }

  &.uk {
    &:before {
      content: 'GB';
      color: #f8d038;
    }
  }

  &.nl {
    &:before {
      content: 'NL';
      color: #f8d038;
    }
  }

  &.es {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);;

    &:before {
      content: 'E';
      color: white;
    }
  }

  &.pt {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);;

    &:before {
      content: 'P';
      color: white;
    }
  }

  &.it {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);;

    &:before {
      content: 'I';
      color: white;
    }
  }

  &.ch {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);;

    &:before {
      background-image: url('/assets/images/global/swiss_crest.svg');
      background-size: 33px 36px;
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      content: '';
      margin-left: 7px;
      margin-right: 7px;
      color: white;
    }

    &:after {
      border: none !important;
    }
  }

  //swiss -> TypeNo != licence
  &.swiss {
    background: white;

    &:before {
      padding-left: 14px;
      color: white;
      content: '';
      background: unset;
    }

    &:after {
      border: none;
      background-image: url('/assets/images/global/swiss_crest.svg');
      width: 33px;
      height: 36px;
      background-size: 33px 36px;
      top: 12px;
      border-radius: 0
    }
  }
}
