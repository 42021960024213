.default-loading-page {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;


  #preloader {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  #loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $highlight-color;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  #loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $highlight-color;
    -webkit-animation: spin 1.75s linear infinite;
    animation: spin 1.75s linear infinite;
  }
  #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $highlight-color;
    -webkit-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
  }
  @-webkit-keyframes spin {
    0%   {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0%   {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
.inline-full-height {
  .default-loading-page {
    height: calc(100% - 3rem);
    position: relative;
  }
}

.modal-spinner {
  .default-loading-page {
    position: relative;
    height: 200px;
  }
}
