app-cart-list,
.saved-carts {
  position: relative;
  height: 100% !important;

  .carts-container {
    padding-top: 20px;
    height: 100%;

    .scrollable-cart-list {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 100%;
      height: calc(100% - 5rem);
    }

  }

  .orders-container {
    padding-top: 20px;
  }

  .fa-trash-o {
    float: right;
    position: relative;
    font-size: 1.3rem;
    padding-right: 15px;
    color: $ci-color-12;
  }
}
