.dms-car-selection {
  .dms-car-type {
    min-height: 70px;
    border-bottom: 1px solid;
    border-color: #d6dbdc;
    align-items: center;

    &.disabled {
      display: none;
    }

    .select-car-from-cart {
      margin-right: -10px;
      float: right;
      height: 30px;
      margin-top: -4px;
    }

    .car-param {
      margin-left: 18px;
      font-weight: bold;
    }

    .multiple-cars {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &:last-child {
      border-bottom: none;
    }

    &.manually {
      height: auto;
    }

    .icon {
      span {
        font-size: 1.2rem;
      }
    }

    button {
      float: right;
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    .alert {
      margin-bottom: 0 !important;
      .fa {
        font-size: 14px;
      }
    }

    .fa {
      font-size: 2rem;
      margin-left: 20px;
    }
  }
}
