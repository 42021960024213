.home,
.work-data,
.technical-data,
.maintenance,
.graphicalPartSearch,
.page-maintenance,
.tyre {
  height: 100%;
  width: 100%;
  .logo{
    height: 80px;
    width: auto;
    margin: 2rem auto !important;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .car-selection-wrapper{
    margin: 0px auto;
    width: 100%;
    //max-width: 1200px;
    background: $car-selection-background;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    .car-selection-area{
      padding: 1rem 25%;
    }
  }
}
.searchable{
  margin-left: 0px !important;
}

app-car-selection,
.car-selection {
  display: block;

  .border-bottom {
    border-bottom: 1px solid darken($background-color,5);
  }

  .car-selection-margin {
    margin: 0 !important;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;

    .base-btn {
      width: 100%;
      background-color: $highlight-color;
      color: $highlight-color-contrast;
    };

    .fa-car, .fa-times {
      font-size: 2rem;
    }

    .line-height {
      line-height: 30px;
    }
  }

  .vin-text {
    color: $color-grey;
  }

  .vin-search {
    .fa-euro {
      font-size: 2rem;
      line-height: 92px;
    }

    .material-form-input {
      max-width: 230px;
    }

    .vin-center {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    input {
      padding-left: 0;
    }

    .base-btn {
      margin-top: 55px;
    }
  }


  .card{
    width: 100%;
    .car-selection{
      padding: 0 2rem 1rem 2rem;
    }
  }
  .form-select-group{
    border-bottom: 1px solid darken($background-color,5);
    padding: 1rem 0;
    &:last-of-type{
      border-bottom: none;
    }
    .left-label{
      font-weight: 600;
      font-size: 1.15rem;
      position: relative;
      top:1rem;
    }
    .form-select-item{
      margin-bottom: 2rem;
    }
  }

  .base-btn {

    opacity: 1;
    -webkit-transition: opacity 300ms ease-out;
    -moz-transition: opacity 300ms ease-out;
    -o-transition: opacity 300ms ease-out;
    transition: opacity 300ms ease-out;

    &:disabled {
      opacity: 0.05;
    }
    white-space: nowrap;
    i {
      vertical-align: baseline;
      font-size: 16px;
      top: -2px;
      position: relative;
    }

    span {
      font-weight: normal;
      vertical-align: baseline;
      font-size: 14px;
      top: -2px;
      position: relative;
    }
    &:hover {
      //color: $highlight-color;
    }
  }

  .kba-padding-hsn {
    padding-right: 6px;
  }

  .kba-padding-tsn {
    padding-left: 6px;
  }

  .search-label {
    color: $ci-color-7;
    font-weight: normal;
    margin-bottom: 3px;
  }

  .car-search-results {
    width: 100%;
    border: 1px solid $ci-color-4;
    border-radius: 5px;

    tr {
      font-size: 16px;

      &:nth-of-type(even) {
        background-color: $ci-color-3;
      }

      &:nth-of-type(odd) {
        background-color: $ci-color-10;
      }

      td {
        padding: 9px 15px;
        vertical-align: middle;
      }

    }
  }

  .vehicle-type-button {
    text-align: center;
    background-color: transparent;
    color: lighten($font-color,50);
    font-size: 2rem;
    float: left;
    text-align: left;
    margin: 2rem 0 0 0;
    cursor: pointer;
    width: 30%;
    &.active {
      color: $highlight-color;
    }

    &:hover {
      color: $highlight-color;
    }
  }

  .description {
    margin: 10px 0;
    color: $ci-color-1;
    margin-left: 20px;
    font-weight: 400;
  }

  .car-selection-model-selection {

    .fa-car {
      padding-right: 10px;
    }

    .model-button-back {
      float: right;
      margin: 20px;
    }

    margin-top: 30px;

    .search-label {
      color: $font-color;
      font-weight: 600;
      font-size: 1.2rem;
      padding-left: 15px;
      padding-bottom: 20px;
    }

    .container {
      max-width: 50vw !important;
      .row {
        padding: 5px;
        color: $ci-color-15;
        cursor: pointer;
        box-shadow: $shadow-base-extra-light;
        border-top: 1px solid darken($background-color, 20);
        transition: all 0.2s;

        &.button-row {
          &:hover {
            border-left: 0px;
            border-right: 0px;
            cursor: default;
            box-shadow: none;
          }
        }

        &:active,
        &:hover {
          background-color: white;
          box-shadow: $shadow-base-light;
          border-left: 0.2rem solid $highlight-color;
          border-right: 0.2rem solid $highlight-color;
          cursor: pointer;
        }
      }

    }

  }
}
