.gallery {

  background-color: rgba(0, 0, 0, 0.75);
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: absolute;
  z-index: 1000;

  .background {
    background-color: white;
  }

  .header-title {
    border-bottom: 2px solid $highlight-color;
    color: $font-color;
    font-weight: 600;
  }

  .gallery-button {
    background-color: transparent;
    border: none;
    color: $font-color;
    float: right;
    font-size: 20px;
    &:hover {
      cursor: pointer;
      color: $highlight-color;
    }
    &:focus,
    &:active {
      outline: none;
    }
  }

  .gallery-center {
    margin-top: 100px;
    margin-left: 24vw;
    margin-right: 25vw;

    .border-line {
      position: absolute;
      width: 100%;
      margin-top: 10px;
      margin-left: -15px;
      margin-right: 5px;
      border-bottom: 2px solid #bdbdbd;
    }

    .fa-angle-left,
    .fa-angle-right {
      font-size: 80px;
      color: #bdbdbd;
      position: absolute;
      bottom: 45%;
      z-index: 1000;
      &:hover {
        color: $highlight-color;
        cursor: pointer;
      }
    }

    .fa-angle-right {
      right: 18px;
    }

    .fa-angle-left {
      margin-left: 5px;
    }

    .gallery-helper {
      position: relative;
      min-height: 600px;
      margin-left: auto;
      margin-right: auto;
      display: block;

      .gallery-image {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: block;
        margin: auto;
        max-width: 600px;
        max-height: 600px;
      }
    }



    .gallery-image-small {
      margin-top: 20px;
      margin-right: 20px;
      max-width: 100px;
      max-height: 100px;

      &:hover {
        border: 2px solid $highlight-color;
        cursor: pointer;
      }

      &.active {
        border: 2px solid $highlight-color;
      }
    }
  }
}