.app-articles-row {

  min-height: 150px;
  position: relative;
  overflow: hidden;
  //&.selected{
  //  &:after{
  //    content: '';
  //    z-index: 1;
  //    display: block;
  //    width: 2000px;
  //    height: 500px;
  //    background: $highlight-color;
  //    position: absolute;
  //    animation: rotatemagic 0.25s ease-in 0s both;
  //  }
  //}


  .availability-panel {
    .fa-spinner {
      margin-right: 10px;
    }
  }

  padding: 0.5rem 1rem;
  margin: 0 -1rem;
  //box-shadow: $shadow-base-extra-light;
  border-top: 1px solid darken($background-color,20);
  transition:
          all        0.2s;

  &.smallArticleRow {
    min-height: 105px;
  }

  &.center {
    text-align: center;
  }

  .image-col{
    padding-top: 2rem;
    z-index: 2;
    background: $background-color;
    height: 90%;
    position: absolute;
    width: 25%;
    overflow: hidden;
    text-align: center;
    img{
      max-height: 100px !important;
    }
    &.image-col-small {
      height: 88%;
      img {
        max-height: 70px !important;
      }
      padding-top: 0;
    }
  }

  .image {
    background-size: cover;
    margin-top: 10px;
    margin-bottom: 10px;
    background-position: center center;
    height: 100vh;
    max-height: 150px;
  }

  .article-no {
    margin-top: 8px;
  }

  .brand, .product-name, .article-no, .description, .gtin {
    font-weight: 300;
    color: $ci-color-1;
  }
  .brand, .article-no {
    font-size: 1.15em;
    font-weight: 500;
    color: darken($ci-color-1,25);
  }
  .product-name{
    font-style: italic;
    font-weight: 300;
  }
  .description {
    min-height: 150px;
    font-weight: 500;
    color: lighten($ci-color-1,25);
    font-size: 1.05em;
    &.small {
      min-height: 40px;
    }
  }
  .padding-top {
    margin-top: 10px;
    z-index: 2;

    &.padding-small {
      margin-top: 0px;
      padding-top: 0px;
    }
  }

  .smallRow {
    padding-top: 0px;
    margin-top: 0px;
  }

  .article-attributes {
    min-height: 100px;

    &.small {
      padding-right: 15px;
      min-height: 40px;
      font-size: 0.9rem;
      line-height: 1.5rem;
      .attribute {
        float: left;
      }
    }

    .value {
      font-weight: bold;
    }

    .important {
      font-weight: bold;

      .value {
        color: $ci-color-7;
      }
    }

  }



  .price {
    font-weight: bold;
    font-size: 1.3rem;
    text-align: right;
    color: $price-color;
    small{
      color: $font-color;
      font-size: 0.7em;
    }
  }
}

@keyframes animatop{
  0%{
    opacity: 0;
    bottom: -500px;}
  100%{
    opacity: 0;
    bottom: 0px;}
}

@keyframes animainfos{
  0%{
    bottom:  10px;}
  100%{
    bottom: -42px;}
}

@keyframes rotatemagic{
  0%{
    opacity: 0;
    transform: rotate(0deg);
    top: -00px;
    right: 500px;
  }
  50%{
    opacity: .5
  }
  100%{
    opacity: 0;
    transform: rotate(0deg);
    top: 00px;
    right: -200px;
  }
}
