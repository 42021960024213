app-availability {
  .article-details-header {
    margin-bottom: 20px;

    .article-name {
      font-weight: bold;
      font-size: 16px;
    }

    .price, .recommended-price {
      font-size: 16px;

      span {
        display: inline-block;
        margin-right: 5px;
      }
    }

    .price {
      margin-bottom: 10px;
    }

    .quantity {
      margin-bottom: -20px;
      .input-group {
        margin-bottom: 10px;
        padding-bottom: 5px;
        .input-group-addon,
        .form-control {
          width: 50%;
          border: 0px;
          background-color: white;
          color: $font-color-dark;
          text-align: left;
          padding: 5px 15px;
        }
        .form-control {
          border-radius: 5px;
          margin: 5px;
          width: 80%;
        }
        .input-group-addon {
          font-weight: bold;
        }
      }
    }
  }

  .font-color {
    color: $font-color;
  }

  .availability-panel {
    border-top: 0px;
    margin-bottom: 0px;
    margin-top: -10px;

    .row {
      margin-right: 0px;

    }
    .article-details-header {
      margin-bottom: 20px;

      .article-name {
        font-weight: bold;
        font-size: 16px;
      }

      .price, .recommended-price {
        font-size: 16px;

        span {
          display: inline-block;
          margin-right: 5px;
        }
      }

      .price {
        margin-bottom: 10px;
      }

      .quantity {
        margin-bottom: -20px;
        .input-group {
          margin-bottom: 10px;
          padding-bottom: 5px;
          .input-group-addon,
          .form-control {
            width: 50%;
            border: 0px;
            background-color: white;
            color: $font-color-dark;
            text-align: left;
            padding: 5px 15px;
          }
          .form-control {
            border-radius: 5px;
            margin: 5px;
            .cart {
              border-top: 1px solid lightgrey;
              line-height: 47px;
            }

          }
        }
      }
    }
  }
}
