.filter {
  border-bottom: 1px solid $color-border;
  margin: 0 -12px;
  .filterBar {
    min-height: 30px;
    background-color: #fff;
    margin-bottom: 10px;
    display: inline-block;
    width: 50vw;
    .fa-angle-down,
    .fa-angle-up {
      font-size: 20px;
      margin: 5px 5px 0 5px;
      line-height: 26px;

      position: absolute;
      top: 0;
      right: 20px;
      &:hover {
        cursor: pointer;

      }
    }
    .chipBar {
      width: 50vw;
      padding-left: 10px;
      padding-top: 5px;

      &.open {
        width: calc(50vw - 10px);
        padding-top: 20px;
      }
      .filterType {
        margin-left: 10px;
        font-weight: bold;
        border-bottom: 1px solid #c1c1c1;
        padding-bottom: 10px;
        padding-top: 10px;
        &:nth-last-child(1) {
          border-bottom: none;
        }
        &:nth-child(0) {
          padding-top: 0px;
        }
      }
    }
  }
}
