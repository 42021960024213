[ng-reflect-ngb-tooltip] + .tooltip {
  z-index: 99999 !important;
  .tooltip-arrow{
    top: 2.5rem;
  }
  .tooltip-arrow,
  .tooltip-inner{
    //background-color: white !important;
    //color: $font-color !important;
    padding: 1rem;
    z-index: 99999 !important;
  }
}

.ui-tooltip {
  right: 0;
  left: 20px;
  position: absolute;
  display: inline-block;
  border-radius: 5px;
  min-width: 200px;
  max-width: 300px;
  min-height: 35px;
  background-color: $font-color;
  color: $color-white;
  z-index: 1000;
  &::after {
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
  }

  &.align-center {
    margin-left: auto;
    margin-right: auto;
    bottom: -80px;
    &::after {
      top: -12px;
      left: 50%;
      border-style: solid;
      border-color: transparent transparent $font-color transparent;
    }
  }

  &.left {
    right: 125%;
    left: -94%;
    &::after {
      top: 42%;
      right: -12px;
      border-style: solid;
      border-color: transparent transparent transparent $font-color;
    }
  }

  &.right {
    left: 100%;
    &::after {
      top: 42%;
      left: -12px;
      border-style: solid;
      border-color: transparent $font-color transparent transparent;
    }
  }

  &.top {
    bottom: 30px;
    border-bottom-left-radius: 0;
    &::after {
      top: 100%;
      left: 0;
      border-style: solid;
      border-color: $font-color transparent transparent transparent;
    }
  }

  &.top-left {
    bottom: 35px;
    left: -225px;
    &::after {
      top: 100%;
      left: 94%;
      border-style: solid;
      border-color: $font-color transparent transparent transparent;
    }
  }

  &.bottom {
    bottom: -46px;
    border-top-left-radius: 5px;
    &::after {
      top: -12px;
      left: 12px;
      border-style: solid;
      border-color: transparent transparent $font-color transparent;
    }
  }
  &.bottom_filter {
    bottom: -68px;
    left: 25px;
    border-top-left-radius: 5px;
    &::after {
      top: -12px;
      left: 12px;
      border-style: solid;
      border-color: transparent transparent $font-color transparent;
    }
  }
  &.center {
    bottom: -50px;
    left:0;
    &::after {
      top: -12px;
      left: 50%;
      border-style: solid;
      border-color: transparent transparent $font-color transparent;
    }
  }
}
.ui-tooltip-text {
  text-align: left;
  padding: 10px;
  font-family: Roboto, sans-serif;
}