/* You can add global styles to this file, and also import other style files */

// compass mixins
@import '../../node_modules/compass-mixins/lib/_compass.scss';
@import "../../node_modules/@angular/cdk/overlay";

html,body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/*

    Load global default theme as fallback

*/
@import '../theme/base';

@import "responsive/all";



.GDRP-THEME {
    //@import '../theme/gdrp';

    font-family: $main-font !important;
    font-size: $font-size;
    color: $font-color;
    font-weight: $font-weight;
    background: $background-color;
  }
