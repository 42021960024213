.autovista {
  z-index: 100;

  .selectedCategory {
    border-left: 3px solid $highlight-color;
    border-right: 3px solid $highlight-color;
    transition: 200ms all ease-in-out;
  }

  .av-tooltip{
    position: fixed;
    background: white;
    padding: 0.25em 1em;
    border-radius: 5px;
    background: $ci-color-6;
    color: white;
    font-weight: bold;
  }

  .svg {
    height: 70vh;
    min-height: 80vh;
  }
  svg {
    g[objecttype="DS"] {
      &:hover {

        g,
        path,
        polyline,
        polygon {
          cursor: pointer;
        }
      }
    }
    g[objecttype="PS"] {
      g,
      path,
      polyline,
      polygon {
        cursor: pointer;
      }

      &:hover {

        g,
        path,
        polyline,
        polygon {
          cursor: pointer;
        }
      }
    }
  }

  .headerPanel {
    z-index: 100
  }
  .svgPanel {
    z-index: 100;
    margin-top: 25px;
    overflow: hidden;

    &.show-with-footer {
      .svgController {
        bottom: 170px !important;
      }
    }

    .svgController {
      position: absolute;
      right: 50px;
      bottom: 123px;
      .fa {
        background: #fefefeeb;
        padding: 5px;
        border: 1px solid $color-grey-light;
        border-radius: 5px;
        font-size: 2rem;
        position: absolute;
        min-width: 40px;
        min-height: 40px;
        text-align: center;
        padding-top: 8px;
        color: $ci-color-6;
        &:hover {
          cursor: pointer;
          background-color: $color-grey-light;
        }
      }

      .fa-arrow-up {
        right: 19px;
        bottom: 32px;
      }
      .fa-arrow-down {
        right: 19px;
        bottom: -56px;
      }
      .fa-arrow-left {
        right: 63px;
        bottom: -12px;
      }
      .fa-arrow-right {
        right: -25px;
        bottom: -12px;
      }
      .fa-plus {
        padding-top: 10px;
        right: -25px;
        bottom: 32px;
      }
      .fa-minus {
        padding-top: 10px;
        right: 63px;
        bottom: 32px;
      }
      .fa-times {
        right: 19px;
        bottom: -12px;
      }
    }

  }
  .footerPanel {
    z-index: 100;
    min-height: 60px;
    position: absolute;
    bottom: 0px;
    top: calc(90vh - 70px) !important;
    overflow: hidden
  }

  .header {
    background-color: #fafafa;
    height: 30px;
    line-height: 30px;
    padding-left: 20px;
    .fa-times {
      float: right;
      margin-right: 15px;
      line-height: 30px;
      font-size: 1.5em;
      cursor: pointer;
      &:hover {
        color: $color-danger;
      }
    }
  }

  .headerBar {
    background: #fafafa;
    padding-left: 7px;
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    color: $font-color;
    border-bottom: 1px solid $color-grey;
    line-height: 30px;

    .fa-car {
      margin-left: 10px;
      margin-right: 5px;
    }

    .carType {
      cursor: default;
      padding-top: 5px;
    }

    .carButton {
      position: absolute;
      top: 0px;
      right: 0;
      width: 70px;
      height: 30px;
      font-size: 12px;
      cursor: pointer;
      background-color: transparent;
      border: none;
      outline: none;
      color: $font-color;
    }
  }

  .footerBar {
    border-top: 1px solid $color-grey;
    padding: 15px;
    background-color: #fafafa;
    position: absolute;
    width: 50vw;
    left: 0;
    right: 0;
    height: 72px;

    .checkbox {
      display: inline-block;
      margin-right: 10px;
    }
  }
}

.autovista-details {
  margin-top: -10px;

  .side-selection {
    margin-top: 10px;
    border-bottom: 1px solid $color-grey;
    margin-left: 10px;
    margin-right: 0;
    .side-text {
      margin-top: 10px;
      span {
        font-size: 1.4rem;
        margin-left: -15px;
      }
    }
  }

  .article-alert {
    margin-left: 10px !important;
    margin-top: 10px;
  }

  .article-list {
    cursor: pointer;
    margin-top: 20px;
    .article {
      font-size: 1.1rem;
      margin-left: 10px;
      .fa-circle {
        font-size: 0.5rem;
        position: absolute;
        top: 7px;
        &.selected {
          color: $highlight-color;
        }
      }
      &:hover {
        color: black;
      }

      .article-name {
        margin-left: 10px;
      }
    }
  }

  .details-tab {
    margin-left: 10px;
    .alert-info {
      margin-top: 10px;
    }

    .workPositionCol {
      input {
        margin-right: 0 !important;
      }
    }

    .availability-panel {
      border-top: 0px;
      margin-bottom: 0px;
      margin-top: -10px;

      .row {
        margin-right: 0px;
        margin-left: 0px;

      }
      .article-details-header {
        margin-bottom: 20px;

        .article-name {
          font-weight: bold;
          font-size: 16px;
        }

        .price, .recommended-price {
          font-size: 16px;

          span {
            display: inline-block;
            margin-right: 5px;
          }
        }

        .price {
          margin-bottom: 10px;
        }

        .quantity {
          margin-bottom: -20px;
          .input-group {
            margin-bottom: 10px;
            padding-bottom: 5px;
            .input-group-addon,
            .form-control {
              width: 50%;
              border: 0px;
              background-color: white;
              color: $font-color-dark;
              text-align: left;
              padding: 5px 15px;
            }
            .form-control {
              border-radius: 5px;
              margin: 5px;
              .cart {
                border-top: 1px solid lightgrey;
                line-height: 47px;
              }

            }
          }
        }
      }
    }
  }

  table {
    width: 100%;

    &.general-data {
      td {
        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-left: 17px;
        }

        .oe-numbers-list {
          height: 100px;
          overflow: auto;
        }
      }
    }

    td {
      padding: 0 15px;
      width: 50%;
    }
  }

  .striped-table,
  .info-table{
    width: 100%;

    &.document-table {
      td:first-child {
        &:hover {
          cursor: pointer;
        }
      }
    }

    tbody {
      tr {

        &:nth-child(odd) {
          background-color: $ci-color-odd;
        }

        &:nth-child(even) {
          background-color: $ci-color-even;
        }
        &.loading{
          td{
            text-decoration: inherit;
          }
        }

        td {
          padding: 10px 15px;
          line-height: 2rem;
          .custom-checkbox {
            float: right;
            width: unset;
          }
          label{
            display: block !important;
          }
          &:last-child {
            i {
              @include transition(all 0.2s ease-in-out);
              color: $ci-color-8;
              font-size: 16px;
              text-align: right;

              &:hover {
                color: $ci-color-1;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .nav-item {
    width: 50%;
    .nav-link {
      height: 45px;
      line-height: 30px;
      border: 1px;
      text-align: center;
      font-family: "Roboto", sans-serif;
      background-color: #f0f0f0;
      color: #3c3c3b !important;
      border-radius: 0px;
      border-color: gray;
      border-bottom: 3px solid #f0f0f0;
      border-left: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
      &.active {
        font-weight: 600;
        color: #5CB8B2 !important;
        border-left: none;
        border-right: none;
        background-color: transparent;
        border-bottom: none;
      }
    }
  }

  .alert-info {
    margin-right: 10px;
    margin-left: -5px;
  }

  .side {
    display: inline-block;
    float: right;
    margin-right: -15px;
    border: 1px solid $color-grey;

    .sideButton {
      outline: none;
      height: 25px;
      min-width: 30px;
      font-size: 16px;
      display: inline-block;
      line-height: 26px;
      border-left: 1px solid $color-grey;
      cursor: pointer;
      &:nth-child(1) {
        border-left: none;
      }
      span {
        display: block;
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
      }
      &.active {
        color: white;
        background-color: $highlight-color;
      }
    }
  }

  .separator {
    display: block;
    background-color: $color-grey;
    height: 3px;
    margin-left: -5px;
    margin-right: 5px;
    margin-bottom: 10px;
  }

  .detailImage {
    max-width: 180px;
    max-height: 150px;
    height: auto;
    border: 1px solid $color-grey;
    background-color: white;
    float: right;
    margin-right: 10px;
  }

  .options {
    padding-bottom: 5px;
  }

  .articleName {
    font-weight: bold;
    font-size: 1.4rem;
  }
  .articlePrice {
    font-size: 1.3rem;
    color: $highlight-color;
    &.price_label{
      margin-right: 5px;
      font-weight: bold;
      font-size: 12px;
    }
  }

  .checkbox {
    float: right;
  }

  .infoType {
    font-weight: bold;
  }

  .spacer {
    margin-bottom: 10px;
  }

  .collapsible-panel {
    margin-right: 10px !important;
    margin-left: -5px !important;
  }

  .oeNumber {
    &:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }

  .fa-external-link {
    margin-left: 10px;
  }
}

@keyframes showCarList {
  from {margin-top: 20px; opacity: 0.5}
  to {margin-top: 40px; opacity: 1}
}

.autovista-carList {
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  .carList {
    animation-name: showCarList;
    animation-duration: 350ms;
    opacity: 1;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    width: 50vw;
    background-color: white;
    box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.25);
    min-width: 820px;

    .header {
      height: 34px;
      border-bottom: 2px solid $highlight-color;
      .fa-car {
        margin-left: 10px;
      }
      .title {
        margin-left: 10px;
        line-height: 34px;
        font-weight: bold;
      }
      .fa-times {
        margin-right: 10px;
        line-height: 34px;
        float: right;
        font-size: 1.5rem;
        &:hover {
          cursor: pointer;
          color: $color-danger;
        }
      }
    }

    &.info {
      min-height: 350px;
    }

    .noResult {
      .alert-info {
        margin-top: 25px;
        margin-left: 15px;
        margin-right: 15px;
      }
      .base-btn {
        float: right;
        display: block;
        margin-right: 15px;
        position: unset;
        margin-top: 0px;
      }
    }

    ul {
      list-style: none;
      margin-left: -40px;
      li {
        border-bottom: 1px solid $color-grey;
        &:hover {
          background-color: #f2f2f2;
          cursor: pointer;
          font-weight: bold;
        }
        span {
          padding-left: 20px;
          padding-top: 5px;
          padding-bottom: 5px;
          .no-padding {
            padding: 0;
          }
        }
        &:nth-child(1) {
          border-top: none;
        }
      }
    }
    .car {
      display: block;
      padding-right: 20px;
    }
  }
}

.autovista-left-tree {
  .custom-tree {
    margin-top: 15px;
    .list {
      font-weight: normal;
      cursor: pointer;
      &.active {
        .fa-circle {
          color: $highlight-color;
        }
      }
      .subCustomList {
        .fa-circle {
          color: $color-black;
        }
        .subList {
          padding: 0 0 0.7em 0.7em;
          &.active {
            font-weight: bold;
            .fa-circle {
              color: $highlight-color;
            }
          }
        }
      }
    }

    .listContainer {
      border-top: 1px solid $color-grey;
      padding-top: 10px;

      &:nth-child(1) {
        border-top: none;
        padding-top: 0px;
      }
    }

  }


}
