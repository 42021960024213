.global-ordermail {
  background-color: rgba(0, 0, 0, 0.75);
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: absolute;
  z-index: 1000;

  .background {
    background-color: white;
    max-height: 82vh;
  }

  .global-ordermail-button {
    background-color: transparent;
    border: none;
    color: $font-color;
    float: right;
    font-size: 20px;
    &:hover {
      cursor: pointer;
      color: $highlight-color;
    }
    &:focus,
    &:active {
      outline: none;
    }
  }

  .header-title {
    border-bottom: 2px solid $highlight-color;
    color: $font-color;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .global-ordermail-center {
    margin-top: 100px;
    margin-left: 24vw;
    margin-right: 25vw;
    .global-ordermail-content {
      background-color: white;
      margin-left: -15px;
      margin-right: -15px;
      .mail-header {
        padding: 5px;
        .font-color-red {
          color: $color-danger;
        }
        .font-color-green {
          color: $color-success;
        }
        .mail-header-padding-left {
          padding-left: 25px;
        }
      }
      .mail-content-wrapper {
        padding: 15px;
        .mail-content {
          box-shadow: inset 0 0 3px 1px rgba(0,0,0,.125);
          padding: 15px;
        }
      }
      .mail-attachments {
        border-top: 1px solid rgba(0,0,0,.125);
        padding: 15px;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
