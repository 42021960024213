@mixin clearfix() {
  content: "";
  clear: both;
  display: block;
  visibility: hidden;
  height: 0;
}

// change scrollbar style for ie and chrome
//@include scrollbars(.7em, #7c7779);

// font-awesome fix
.fa {
  transform: rotate(0.001deg);
}

html, body {
  font-family: $main-font !important;
  font-size: $font-size;
  color: $font-color;
  font-weight: $font-weight;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
  line-height: 20px;
  background: $background-color;

  .container-fluid,
  .profile,
  .userdata,
  .user_dashboard {
    height: 100%;
    padding: 0px !important; // global padding to side
    .left-panel {
      .nav-link.active{
        background: transparent !important;
      }
      width: 25%;
      padding-top: 10px;
      //background: darken($background-color, 2) !important;
      .panel-content {
        top: 0;
        padding-top: 10px;
        padding-left: 10px;
      }
    }

    .right-panel {
      width: 25%;
      padding-top: 10px;
      background: darken($background-color, 2) !important;
      .panel-content {
        top: 0;
        padding-top: 10px;
        padding-left: 10px;
      }
    }

    .center-panel {
      width: 50%;
    }

    .heading {
      height: 34px;
      line-height: 34px;
      vertical-align: middle;
      position: absolute;
      top: 0;
      padding: 0 1.5rem;
      width: 100%;
      background: darken($background-color, 2);

      .fa-th {
        display: inline-block;
        margin-right: 5px;
      }

      .fa-caret-right {
        display: inline-block;
        margin: 0 8px;
      }

      &.left {
        left: 0;
        right: 10px;
      }

      &.right {
        left: 10px;
        right: 0;
      }

    }
  }
}

.align-right {
  float: right;
}

.align-left {
  float: left;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.full-height {
  display: block !important;
  height: 100% !important;
}

.nx-overflow-dotted {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hoverable,
.clickable {
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
}

@media screen {
  #printSection {
    display: none;
  }
}

@media print {
  //@page {
  //  //size: landscape
  //}

  body * {
    visibility: hidden;
  }

  #printSection, #printSection * {
    visibility: visible;
  }

  #printSection {
    position: absolute;
    left: 0;
    top: 0;
  }

  .print-hide {
    display: none;
  }
}

.blue-tooltip {
  width: 200px;

  .tooltip-arrow {
    border-right-color: $ci-color-18;
  }

  .tooltip-inner {
    text-align: left;
    background-color: $ci-color-18;
  }
}

.pending-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
}

.please-wait {
  color: $color-white;
  text-align: center;
  font-size: 30px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  width: 185px;
  margin-left: -92.5px;
  left: 50%;
  font-style: italic;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
}

.lightbox {
  cursor: pointer;
  display: none;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s ease-out;

  img {
    max-width: 90%;
    max-height: 80%;
    width: auto !important;
    height: auto;
    margin: 10% auto;
  }

  &.show {
    outline: none;
    display: block;
    visibility: visible;
    opacity: 1;
  }
}

.tree {
  height: 100%;
}

.ui-widget-header {
  border-left: none;
  border-right: none;
  border-top: none;
}

.ui-tabview-panels {
  border: 1px solid #ccc;
  border-top: none;
  background-color: $ci-color-3;
}

.ui-tabview.ui-tabview-top > .ui-tabview-nav {
  padding-left: 0 !important;
}

.ui-tabview .ui-tabview-nav > li.ui-state-active {
  background-color: $ci-color-3;
}

