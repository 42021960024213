.work-item{
  .clickable{
    cursor: pointer;
  }
  app-checkbox {
    position: absolute;
    z-index: 500;
  }
  .col-1 {
    margin-right: -25px;
  }
  .highlight {
    font-weight: 600 !important;
  }
}

