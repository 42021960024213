.club-supplier-config {


  .material-form-input {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;

    input {
      padding-left: 0;
    }
  }

  .checkbox {
    display: inline-block;
    margin-left: 15px;
  }

  .fa-times {
    position: absolute;
    color: $color-danger;
    top: 20px;
    right: 25px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .resetButton {
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .arrowButton {
    i {
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 30px;
    }
  }

  @media (max-width: 1560px) {
    .arrowButton {
      margin-left: auto;
      margin-right: auto;
      float: none !important;
      display: block;
      margin-bottom: 10px;
    }
  }

  .success {
    margin-top: 10px;
    margin-right: 10px;
  }

  .cursor-default {
    cursor: default;
  }

  .club-setting-title {
    margin: 5px 10px 0px;
    cursor: default;
  }

  .allSupplier {
    border: 1px solid $color-grey;
    margin-left: 10px;

    .allSupplierBox {
      overflow-y: scroll;
      height: 600px;

      .allSupplierItem {
        padding: 0.5rem 1rem;
        border-top: 1px solid $color-grey;

        &:hover {
          background-color: #f2f2f2;
          cursor: pointer;
        }

        &:last-child {
          border-bottom: 1px solid $color-grey;
        }

        &.selected {
          background-color: #fafafa;
          border-left: 0.2rem solid #5CB8B2;
          border-right: 0.2rem solid #5CB8B2;
        }
      }
    }
  }

  .clubSupplier {
    border: 1px solid $color-grey;
    margin-right: 10px;

    .fa-times {
      margin-right: 10px;
    }

    .clubSupplierBox {
      overflow-y: scroll;
      height: 600px;

      .clubSupplierItem {
        padding: 0.5rem 1rem;
        border-top: 1px solid $color-grey;

        &:hover {
          background-color: #f2f2f2;
          cursor: pointer;
        }

        &:last-child {
          border-bottom: 1px solid $color-grey;
        }

        &.selected {
          background-color: #fafafa;
          border-left: 0.2rem solid #5CB8B2;
          border-right: 0.2rem solid #5CB8B2;
        }
      }
    }
  }
}

