.work-header {
  margin: -12px -12px 10px -12px;
  padding: 15px 1.5rem 15px 1.5rem;
  border-bottom: 1px solid #ccc;
  background-color: #fafafa;
  width: 100%;

  .base-btn {
    position: absolute;
    top: 0;
    right: 15px;
  }

  .successRow {
    margin: 10px -27px -28px;
    .buttonBar {
      .base-btn {
        position: relative;
        float: right;
        background-color: $color-side-panel-background;
        margin-left: 10px;
        margin-top: 6px;
        .fa-shopping-cart {
          color: $color-success;
        }
      }
    }
  }
}
