.external-tools {
  .list-wrapper {
    margin-left: -12px;
    margin-right: -12px;
    &.drag-list {
      margin-left: -12px;
      margin-right: -12px;
      width: auto;
      .drag-box {
        margin-left: 0px;
        margin-right: 0px;
        &.selected {
          border-left: 5px solid $highlight-color;
          border-right: 5px solid $highlight-color;
        }
      }
    }
  }
}

.external-firm-tools {
  .add-external-tool {
    float: right;
    cursor: pointer;
  }
  .tool-row {
    padding: 10px 5px;
    border-top: 1px solid $color-grey-light;
    .tool-options {
      .fa {
        margin-right: 20px;
        cursor: pointer;
        font-size: 1.2rem;
      }
    }
    .alert-danger {
      width: 100%;
      margin-top: 10px;
      margin-bottom: -5px;
    }
    &:last-child {
      border-bottom: 1px solid $color-grey-light;
    }
  }
}
