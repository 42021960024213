.external-tools-wrapper {
  .external-tool-icon {
    color: $font-color;
    .fa:before {
      content: '\f110' !important;
    }
  }
  &.car-details {
    padding-right: 0;
    margin-top: 10px;
    .row {
      margin-left: 0;
      margin-bottom: 10px;
      margin-top: 10px;
      z-index: 1;
      position: relative;
      width: 100%;
      padding: 1.3rem;
      font-size: 1.3em;
      font-weight:600;
      background: $color-neutral-contrast;
      color: $highlight-color;
      border: 1px solid $color-grey-light;
    }
  }

  &.car-infos {
    width: 100%;
    margin-top: 10px;
    .row {
      color: $highlight-color;
      font-weight: bold;
      margin-bottom: 10px;
      padding: 5px;
      margin-left: 0;
      width: 100%;
      border: 1px solid $color-grey-light;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.external-icon-wrapper {
  &.DIGITAL_SERVICEBOOK {
    .fa:before {
      content: '\f02d';
    }
  }

  &.MACS_DIA {
    .fa:before {
      content: '\f1b9';
    }
  }

  &.N4_DIA {
    .fa:before {
      content: '\f1b9';
    }
  }

  &.WUERTH_IT {
    .fa:before {
      content: '\f02d';
    }
  }
}
