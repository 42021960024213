.global-order-row {
  .app-articles-row {
    min-height: 80px;
    .centerItem {
      margin-left: auto;
      margin-right: auto;

      &.fa-spinner {
        font-size: 2rem;
        height: 25px;
      }
    }
  }

}