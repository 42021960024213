.sceleton-result {
  .sceleton-item {
    padding: 5px;
    height: 2rem;
    margin-bottom: 3px;
    width: 100%;
    color: transparent;
    background: linear-gradient(100deg, #f1f1f1 30%, #fafafa 50%, #f1f1f1 70%);
    background-size: 400%;
    animation: sceleton-loading 1.2s ease-in-out infinite;

    .fake-item{
      border-radius: 2px;
      margin-right: 10px;
      width: 125px;
      height: 90%;
      float:left;
      background-color:#e9e9e9;
    }
  }

  @keyframes sceleton-loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

}