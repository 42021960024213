.btn-flat {
  border: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  transition: .3s ease-out;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .5px;
  padding: .5rem;
  font-size: 1rem;
  border-radius: 2px;
  outline: 0;
  line-height: 3rem;
  background-color: transparent;
  color: $highlight-color;

  .fa{
    line-height: 3.3rem;
    text-align: center;
    margin: auto;
    font-size: 1.8rem;
    float: left;
  }

  &:disabled{
    opacity: 0.15;
  }

  &:hover{
    border-bottom: 1px solid $highlight-color;
  }

  &.round {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 30px;
    margin-top: 5px;
    position: relative;
    padding: 0;
    z-index: 98;
    -webkit-box-shadow: 0 6px 10px 0 rgba(0,0,0,0.3);
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.3);
    display: flex;
    transition: 0.2s;
    text-align: center;

  }
  &.primary {
    background-color: $highlight-color;
    color: $highlight-color-contrast;
  }
  &.light {
    background-color: $header-background-color;
    color: $background-color;
  }

  &:focus{
    box-shadow: none;
    outline: 0;
  }

}

.btn-reset-filter {
  border: none;
  background: transparent;
  font-weight: 400;
  margin-top: -5px;
  font-size: 1rem;
  display: block;
  text-align: right;
  color: $highlight-color;
  width: 100%;
  margin-bottom: 1rem;
  padding-left: 3px;
  cursor: pointer;
  &:active,
  &:focus {
    outline: 0 !important;
    outline-offset: 0 !important;
    background-image: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.toggle-btn {
  background-color: $color-grey-light;
  border: none;
  color: $font-color-dark;
  margin-bottom: 10px;
  outline: none;
  &.active {
    background-color: $highlight-color !important;
    color: white !important;
    font-weight: bold;
  }
  &:focus {
    border: none;
    outline: 0px;
  }
}


.cart-btn {
  background-color: $highlight-color;
  color: $highlight-color-contrast;
  padding: 0 0 0 15px;
  height: 50px;
  line-height: 30px;
  border-radius: 0px;
  border: 0px ;
  outline: 0 !important;
  cursor: pointer;
  &:hover {
    background-color: $highlight-color-contrast;
    color: $highlight-color;
  }
  span {
    //display: inline-block;
    display: none;
    margin-right: 20px;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
  }

  i{
    line-height: 50px;
    margin-right:1rem;
    font-size: 1.85rem;
  }
  i.fa.fa-clipboard {
      margin-left: .5rem;
      margin-right: .5rem;
  }

  .count {
    font-size: 20px;
    //width: 30px;
    padding-right: 1rem;
    height: 30px;
    line-height: 30px;
    border-radius: 0px;
    float: right;
    font-weight: bold;
    margin-top: 10px;
  }
}


.base-btn {
  position: relative;
  background-color: darken($color-neutral-contrast,10);
  color: $color-neutral;
  font-size: 1rem;
  line-height: 2.5rem;
  //text-transform: uppercase;
  font-weight: 400;
  //border-radius: 4px;
  border: none;
  padding: 0px 1rem;
  @include transition(all 0.2s ease-in-out);

  i {
    width: 20px;
    text-align: center;
    font-size: 1.2rem;
    position: relative;
    margin-right: 5px;
    vertical-align: middle;
  }

  &:hover {
    cursor: pointer;
    opacity: .8;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }


  &.primary{
    background-color: $highlight-color;
    color: $highlight-color-contrast;
    margin-top: $button-top-margin;
    &:disabled {
      cursor: not-allowed;
      background-color: $color-grey-dark;
    }
  }

  &.success{
    background-color: $color-success;
    color: $color-success-contrast;
    margin-top: $button-top-margin;
    &:disabled {
      cursor: not-allowed;
      background-color: $color-grey-dark;
    }
  }

  &.warning{
    background-color: $color-warning !important;
    color: $color-warning-contrast !important;
  }

  &.error{
    background-color: $color-danger !important;
    color: $color-danger-contrast !important;
    margin-top: $button-top-margin;
  }

  &.btn-text{
    background: none;
    columns: $font-color;
    padding: 0px !important;
    font-weight: bold;
  }
  &.button-top-margin {
    margin-top: $button-top-margin;
  }
  &.no-top-margin {
    margin-top: 0!important;
  }
}

.pulse {
  animation: btn-pulse 2s 1;
  -webkit-animation: btn-pulse 2s 1;
}

.print-btn {
  @extend .base-btn;
  background-color: transparent !important;
  height: 100%;
  i {
    vertical-align: baseline;
  }

  &:hover {
    cursor: pointer;
    color: $highlight-color !important;
  }
  span {
    font-family: Roboto, sans-serif;
  }
}

.icon-btn {
  @extend .base-btn;
  background-color: transparent !important;
  color: $font-color !important;

  &:hover
  {
    cursor: pointer;
    color: $highlight-color !important;
  }
}
.order-btn {
  @extend .base-btn;
  background-color: $color-success;
  color: $color-success-contrast;
  width: 280px;
  line-height: 3.5rem;
  font-size: 1.5rem;
  height: auto;
  margin: 0 auto;
  display: block;

  &:hover {
    background-color: darken($color-success, 5%);
  }

  &:disabled {
    background-color: $ci-color-12;
    color: $ci-color-16;
  }

  &.packing-slip {
    background-color: $color-info!important;
    &:hover {
      background-color: darken($color-info, 5%);
      color: $color-white;
    }
  }
}


@keyframes btn-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($highlight-color, 0.7);
  }

  70% {
    box-shadow: 0 0 0 5px rgba($highlight-color, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba($highlight-color, 0.7);
  }
}
