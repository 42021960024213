// Large devices (desktops, less than 1200px)
.sidebar-opener{
  display: none;
}
$timing: 500ms;
@media (max-width: 1199.98px) {
  .card{
    margin: 1rem 0;
  }
  .top-bar{
    .fa-th-list,
    .fa-eject,
    .fa-book,
    .fa-info-circle{
      font-size: 1.5em !important;
      margin: 0 .5em !important;
    }
    .fa-th-left{
      left: 265px !important;
    }
    .fa-th-list {
      margin-right: 0 !important;
    }
    .external-tools_icon{
      left: 373px !important;
    }
    .fa-info-circle{
      left: 350px !important;
    }
    .car-history-dropdown .nx-dropdown-button .selected {
      padding-right: 40px;
    }
    .eject_icon {
      left: 315px !important;
      .fa-eject{
        position: relative !important;
        left: 0px !important;
        top: 0px !important;
        &.browserIsIE{
          top: -31px !important;
        }
      }
    }
  }
  .car-selection-wrapper {
    .car-selection-area,
    .panel-content{
      bottom: 0px;
      top: 0px;
      height: 100%;
      padding: 0px !important;
      app-car-selection{
        height: 100%;
        .card{
          height: 100%;
          margin: 0px;
        }
      }
    }
  }

  app-articles-for-work {
    .tabsetShadow{
        width: 33vw !important;
    }
  }
  .center-panel{
    -webkit-transition: border-left $timing linear;
    -moz-transition: border-left $timing linear;
    -o-transition: border-left $timing linear;
    transition: border-left $timing linear;
    width: 67.5vw!important;
    border-right: 1rem solid $header-background-color;
    app-articles-search-result .row.selected, .app-articles-search-result .row.selected{
      border-left: none !important;
      border-right: none !important;
    }
    &.right{
      border-left: 2rem solid $header-background-color;
    }
    &.left{
      border-left: 1rem solid $header-background-color;
    }
    .heading{
      top: 0px !important;
    }
    .panel-content{
      margin-top: 0px !important;
    }
  }
  .right-panel{
    width: 0vw !important;
    -webkit-transition: width $timing linear;
    -moz-transition: width $timing linear;
    -o-transition: width $timing linear;
    transition: width $timing linear;
    border-right: 1rem solid $header-background-color;
    .panel-content{
      display: none;
    }
    &.right{
      width: 33vw !important;
      display: table-cell;
      .panel-content{
        display: inherit;
      }
    }
  }
  .left-panel{
    width: 0vw !important;
    -webkit-transition: width $timing linear;
    -moz-transition: width $timing linear;
    -o-transition: width $timing linear;
    transition: width $timing linear;
    .panel-content{
      display: none;
    }
    &.left{
      width: 33vw !important;
      display: table-cell;
      .panel-content{
        display: inherit;
      }
    }
    .nav{
      display: none; // hide the tab panel header
    }
  }
  .sidebar-opener-wrapper{
    .sidebar-opener{
      display: block;
      position: absolute;
      left: 0;
      top: 50vh;
      z-index: 884;
      ul{
        list-style: none;
        position: relative;
        transform-origin: top left;
        transform: rotate(-90deg);
        li{
          display: block;
          float: right;
          padding: 3px 10px 5px 10px;
          background: $header-background-color;
          color: $highlight-color-contrast;
          cursor: pointer;
          margin-right: 1rem;
          line-height: 2rem;
          font-weight: 600;
          font-size:1.15rem;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          &:hover{
            //background: darken($highlight-color,5);
          }
          &.disabled{
            opacity: 1;
            color: transparentize($highlight-color-contrast, .5);
          }
        }
      }
      ul,
      li,
      .fa-chevron-right{
        -webkit-transition: all $timing linear;
        -moz-transition: all $timing linear;
        -o-transition: all $timing linear;
        transition: all $timing linear;
      }
      .fa-chevron-right{
        transform: rotate(270deg);
      }
      &.left{
        ul{
          left: 33vw;
          margin-left: -21px;
        }
      }
      &.right{
        ul{
          left: 0;
          margin-bottom: -10px;
          li{
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            .fa-chevron-right{
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }
}
