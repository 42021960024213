.createTemplate {
  .no-margin {
    margin: 0;
  }
  .custom-dropdown {
    margin-top: 6px;
    margin-bottom: 0;
    &::after {
      top: 1.5rem;
    }
  }

  .dropdown-padding-top {
    &.type-aw {
      padding-top: 0 !important;
      margin-bottom: 15px;
      .custom-dropdown {
        margin-left: -15px;
        width: calc(50vw - 24px);
        &::after {
          right: -2px !important;
        }
      }
    }
  }
}
