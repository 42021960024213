.hero-icon {
  &.size-1 {
    height: 1rem;
  }
  &.size-2 {
    height: 2rem;
  }
  &.size-3 {
    height: 3rem;
  }
  &.size-4 {
    height: 4rem;
  }
  &.size-5 {
    height: 5rem;
  }

  &.highlight-color {
    color: $highlight-color !important;
  }
}