.nav{
  &.nav-tabs{
    border-bottom: 1px solid $highlight-color;
    .nav-link{
      font-family: $condensed-font;
      background-color: $color-white;
      color: $highlight-color !important;
      border-radius: 0px;
      border-color: $highlight-color;
      &:hover{
        text-decoration: none;
      }
      &.active{
        color: $color-white !important;
        background-color: $highlight-color;
      }
    }
  }
}
.tab-content{
  height: 100%;
  padding-top: 10px;

}
.articleTabSet,
.left-panel {
  .nav{
    &.nav-tabs{
      border: 1px;
      border-bottom: 0px solid $ci-color-4;
      margin-left: -10px;
      margin-top: -9px;
      .nav-item {
        width: 50%;
      }
      .nav-link{
        height: 45px;
        line-height: 30px;
        border: 1px;
        text-align: center;
        font-family: $condensed-font;
        background-color: #f0f0f0;
        color: $font-color !important;
        border-radius: 0px;
        border-color: gray;
        border-bottom: 3px solid $color-tab-background-inactive;
        border-left: 1px solid $color-tab-border-inactive;
        border-right: 1px solid $color-tab-border-inactive;
        &:hover{
          text-decoration: none;
        }
        &.active{
          font-weight: 600;
          color: $highlight-color !important;
          border-left: none;
          border-right: none;
          background-color: $color-side-panel-background;
          border-bottom: 3px solid $highlight-color;
        }
      }
    }
  }
  .tab-content{
    margin-left:0px;
    padding-top: 1px;
    padding-bottom: 1px;
    height: 100%;
    .tab-pane {
      height: 100%;
    }
  }

  .button-header {
    height: 5rem;
    border-bottom: 1px solid $color-grey-light;
    .base-btn {
      margin-top: 20px;
      position: inherit;
    }
  }

  .scroll-content {
    height: calc(100% - 8rem);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 1rem;
  }
}

.articleTabSet {
  .nav {
    &.nav-tabs{
      margin-right: 0px;
      .nav-link {
        &.disabled {
          color: #b9b9b9 !important;
          cursor: not-allowed !important;
          opacity: 0.5 !important;
          &:hover {

          }
        }
      }
    }
  }

  .tab-content {
    margin-right: 0;
  }
}

.center-panel {
  .nav{
    &.nav-tabs{
      border: 1px;
      border-bottom: 0px solid $ci-color-4;
      margin-right: 13px;
      .nav-link{
        border: 1px;
        text-align: center;
        font-family: $condensed-font;
        background-color: white;
        color: $font-color !important;
        border-radius: 0px;
        border-color: $highlight-color;
        &:hover{
          text-decoration: none;
        }
        &.active{
          color: $highlight-color !important;
          border-bottom: 3px solid $highlight-color;
        }
      }
    }
  }
  .tab-content{
    margin-left:0px;
    margin-right:13px;
    border-top: 1px solid #d3d3d34f;
    padding-top: 1px;
    padding-bottom: 1px;
  }
}

.right-panel{
  .nav{
    &.nav-tabs{
      border: 1px;
      border-bottom: none;
      margin: 0 -5px 0 -10px;
      .nav-link{
        border: 1px;
        text-align: center;
        font-family: $condensed-font;
        background-color: $color-side-panel-background;
        color: $font-color !important;
        border-radius: 0px;
        border-color: gray;
        border-bottom: 1px solid darken($color-side-panel-background,7);
        border-left: 1px solid darken($color-side-panel-background,3);
        border-right: 1px solid darken($color-side-panel-background,3);
        &:hover{
          text-decoration: none;
        }
        &.active{
          color: $highlight-color !important;
          font-weight: bold;
          border-bottom: 0px solid $highlight-color;
          background: $background-color;
          border: 1px solid $background-color;
          border-top: 1px solid darken($color-side-panel-background,3);
        }
      }
    }
  }
  .tab-content{
    margin: 0px 0 0 -10px;
    padding: 10px;
    height: 100%;
    background: $background-color;
    .collapsible-panel-header {
      .text {
        font-size: 1rem;
      }
    }
  }
}
