$size: 10px;

.center-content{
  .loader{
    padding: 50% 0 !important;
  }
}
.container-box {
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
  height: 100%;
  background: transparentize($background-color, 0.1);
  .loader {
    height: 100%;
    display: block;
    padding: 100% 0;
    overflow: hidden;
    &:after{
      clear: both;
    }
    span {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      left: 50%;
      margin-left: -10px;
      -webkit-animation: 3s infinite linear;
      -moz-animation: 3s infinite linear;
      -o-animation: 3s infinite linear;
      &:nth-child(1) {
        background: darken($highlight-color,20);
        -webkit-animation: kiri 1.2s infinite linear;
        -moz-animation: kiri 1.2s infinite linear;
        -o-animation: kiri 1.2s infinite linear;

      }
      &:nth-child(2) {
        z-index: 100;
        background: $highlight-color;
      }
      &:nth-child(3) {
        background: lighten($highlight-color,20);
        -webkit-animation: kanan 1.2s infinite linear;
        -moz-animation: kanan 1.2s infinite linear;
        -o-animation: kanan 1.2s infinite linear;
      }
    }

  }

}

@-webkit-keyframes kanan {
  0% {
    -webkit-transform: translateX(20px);
  }

  50% {
    -webkit-transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(20px);
    z-index: 200;
  }
}

@-moz-keyframes kanan {
  0% {
    -moz-transform: translateX(20px);
  }

  50% {
    -moz-transform: translateX(-20px);
  }

  100% {
    -moz-transform: translateX(20px);
    z-index: 200;
  }
}

@-o-keyframes kanan {
  0% {
    -o-transform: translateX(20px);
  }

  50% {
    -o-transform: translateX(-20px);
  }

  100% {
    -o-transform: translateX(20px);
    z-index: 200;
  }
}

@-webkit-keyframes kiri {
  0% {
    -webkit-transform: translateX(-20px);
    z-index: 200;
  }
  50% {
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(-20px);
  }
}

@-moz-keyframes kiri {
  0% {
    -moz-transform: translateX(-20px);
    z-index: 200;
  }
  50% {
    -moz-transform: translateX(20px);
  }
  100% {
    -moz-transform: translateX(-20px);
  }
}

@-o-keyframes kiri {
  0% {
    -o-transform: translateX(-20px);
    z-index: 200;
  }
  50% {
    -o-transform: translateX(20px);
  }
  100% {
    -o-transform: translateX(-20px);
  }
}



