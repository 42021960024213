.graphicalPartSearch {
  height: 100%;

  .spinner {
    margin-top: 20px;
    width: 2.5rem;
    margin-left: auto;
    margin-right: auto;
    .fa-spinner {
      font-size: 2.5rem;
    }
  }

  .white-background {
    background-color: $color-white;
  }

  .fa-info-circle {
    margin-right: 10px;
  }

  .left-panel-empty {
    background-color: #f5f5f5;
    min-height: 45px;
    margin-top: -10px;
    margin-left: -10px;
    margin-right: -2px;
    span {
      display: block;
      line-height: 45px;
      text-align: center;
      height: 45px;
      color: #a8a8a8;
    }
  }
}
