/*
      TODO: NEW THEME

      1. open ../styles/app.scss
      2. define new theme as class
         sample: body.themename {}
      3. import theme scss file in new class:
         sampple: @import '../theme/themename';

      ============
      result:

        body.themename {
          @import '../theme/themename';
        }

      ============
*/

// ======================= LOAD GLOBAL ELEMENTS =========================== //

@import '../styles/modules/all';

// =========================== CUSTOM STYLES ============================== //


/*
  Posible variable can be found in following SCSS Files:

  ../styles/modules/_colors.scss
  ../styles/modules/_fonts.scss
  ../styles/modules/_variables.scss
 */

.logo,
.product-logo {
  background-image: url("/assets/images/theme/driver.png");
}

$header-background-color: #222222;

$highlight-color2: #fed100;
$highlight-color: #222222;
$header-topbar-background-color: black;
$font-color-navigation: white;
$font-color: black;
$background-color: #fff;
$price-color: #222222;


$car-selection-background: url('/assets/images/theme/backgrounds/driver.jpg') no-repeat center center fixed;

$fallback: sans-serif !important;
$main-font: 'ARIAL', $fallback;
$condensed-font: 'ARIAL', $fallback;

.left-sidebar,
.right-sidebar,
.left-panel .nav.nav-tabs .nav-link.active {
  background-color: white !important;
}

.assembly-category {
  background: #f5f5f5 !important;

  .name-wrapper-no-image {
    //color: white !important;
  }
}

.direct-search {
  .search-field {
    .fa-caret-down {
      &:before {
        margin-top: -12px !important;
      }
    }
  }

  .fa-search {
    width: 5px !important;
  }
}

.supplier-card {
  .header {
    background: $highlight-color !important;
    color: $font-color-navigation !important;
  }
}

.main-nav ul li {
  text-transform: uppercase;
  font-weight: 800 !important;

  &:hover,
  &.active {
    font-weight: 800 !important;
    //text-decoration: underline;
    color: $highlight-color2 !important;
  }
}

.recall-text {
  color: $highlight-color2;
}

.cart-btn {
  .count,
  i {
    color: $highlight-color2 !important;
  }

  &:hover {
    .count, i {
      color: black !important;
    }
  }
}

.cartSearch {
  .fa-search {
    margin-right: 25px !important;
  }
}

.app-articles-search-result {
  .row {
    &.selected,
    &:hover {
      box-shadow: none;
      border-left: 0.2rem solid $highlight-color2 !important;
      border-right: 0.2rem solid $highlight-color2 !important;
    }
  }
}

.d-inline-block {
  .dropdown-toggle {
    &:focus {
      i {
        color: $highlight-color2 !important;
      }
    }
  }

  .dropDownMenu {
    .dropdown-item {
      &:hover {
        i {
          color: $highlight-color2 !important;
        }
      }
    }
  }
}

ul.custom-tree {
  ul {
    li {
      i {
        &.open {
          color: $highlight-color2 !important;
        }
      }
    }
  }
}

.form-control {
  &.numberType {
    padding-left: 0px !important;
    border: 1px solid black !important;
  }
}

.direct-search {
  .search-field {
    .form-control {
      border: 1px solid white !important;
      padding-left: 10px !important;
    }

    .fa-search {
      width: 30px
    }
  }
}

.main-search-form {
  .search-input {
    border: 1px solid #e6e5e5 !important;
    padding-left: 10px !important;
    margin-top: 2px;
  }
}

.wizard {
  .wizard-block {
    .material-form-input {
      input {
        padding: 10px 10px 8px 5px !important;
      }
    }
  }
}

.material-form-input {
  input {
    border: 1px solid #888 !important;
    height: 38px;
    padding: 15px 10px 8px 5px !important;
    font-size: 1.2rem !important;

    &:focus ~ label,
    &:valid ~ label {
      width: 100%;
      position: absolute;
      top: 10px !important;
      left: 6px !important;
    }

    &:disabled ~ label {
      padding-top: 15px !important;
      top: 10px !important;
    }
  }

  hr {
    display: none !important;
  }

  label {
    left: 6px !important;
    text-align: left;
    color: #8c8b8b !important;
  }
}

.material-form-input.disabled::after {
  padding-right: 10px !important;
  top: 0.3rem !important;
}

#hamburger-button {
  span {
    background-color: $font-color-navigation !important;
  }

  &.open {
    span {
      background-color: $font-color !important;
    }
  }
}

app-toggle-switch,
.toggle-switch {
  .textLeft,
  .textRight {
    color: $color-grey-light !important;

    &.active {
      color: $highlight-color2 !important;
    }
  }
}

.cart {
  .print-preview {
    top: -240px !important;
  }
}

.notification-message {
  color: $font-color
}

// ================================ LAST ELEMENTS ========================== //

/*               MUST BE LOADED IN EVERY THEME AFTER OVERWRITES              */

// ======================================================================== //


@import 'includes';

