.partner {
  height: 100%;
  width: 100%;
  .logoUrl {
    height: auto;
    width: auto;
    max-width: 100px;
    max-height: 70px;
    margin-left: auto;
    margin-right: auto;
  }

  .cursor-default {
    cursor: default;
  }

  .active {
    color: $highlight-color !important;
  }

  .center-panel {
    width: 50vw !important;
    height: 100vh !important;
  }

  .nav-tabs, .tab-content {
    margin-right: 8px;
  }
}
