.page-maintenance {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $car-selection-background;

  .center-card {
    padding-top: 1px;
    width: 50vw;
    margin-top: 12rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background: white;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: .25rem;
    .logo {
      width: 300px;
    }
    .card-content {
      display: block;
      padding-bottom: 20px;
      line-height: 57px;
      .fa-cog {
        font-size: 3rem;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
}
