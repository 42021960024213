.user-notifications {
  min-height: 350px;
  .notification-row {
    border-top: 1px solid #d6dbdc;
    padding-top: 10px;
    padding-bottom: 10px;
    &.WARNING, &.WARN {
      border-left: 10px solid $color-warning;
    }
    &.INFO {
      border-left: 10px solid $color-info;
    }
    &.ERROR {
      border-left: 10px solid $color-danger;
    }
    .notification-message {
      padding-left: 10px;
    }
    .notification-option {
      font-size: 1.6rem;
    }
    &:last-of-type {
      border-bottom: 1px solid #d6dbdc;
    }
  }
}
