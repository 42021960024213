@import "buttons";
@import "card";
@import "item-list";
@import "toggle-switch";
@import "draggable";


.spinner-circle-swish{
  bottom: 1rem !important;
}
