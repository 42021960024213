.club-setting-vin, .numberplate-search-config {
  .content {

    .cursor-default {
      cursor: default;
    }

    .checkbox {
      margin-left: 15px;
    }

    .club-setting-title {
      margin: 5px 10px 0px;
      cursor: default;
    }

    .sortableList {
      margin-left: 0;
    }

    .base-btn {
      margin-right: 10px;
    }
  }
}
