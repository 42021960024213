app-toggle-switch {
  .toggle-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    opacity: 1;

    &.disabled {
      cursor: not-allowed !important;
      opacity: 0.5;
      filter: grayscale(1);

      label{
        cursor: not-allowed !important;
      }
    }

    input[type="checkbox"] {
      margin-right: 10px !important;
    }

    label {
      margin-bottom: 0 !important;
    }

    .active {
      color: $highlight-color;
      font-weight: bold;
    }

    .textRight,
    .textLeft {
      height: 100%;
      line-height: 100%;
    }

    .textRight {
      margin-left: 10px; // Abstand zum Schalter
    }

    .textLeft {
      margin-right: 10px; // Abstand zum Schalter
    }

    input#toggle {
      max-height: 0;
      max-width: 0;
      opacity: 0;
    }

    input#toggle + label {
      background-color: #ccc;
      display: block;
      position: relative;
      box-shadow: inset 0 0 0 1px #ccc;
      text-indent: -5000px;
      height: 14px;
      width: 25px;
      border-radius: 8px;
      color: $color-white;
      font-size: 14px;

      &:hover {
        cursor: pointer;
      }
    }

    input#toggle + label:before {
      content: "";
      position: absolute;
      display: block;
      height: 14px;
      width: 14px;
      top: 0;
      left: 0;
      border-radius: 8px;
      background: rgba(19, 191, 17, 0);
      -moz-transition: .25s ease-in-out;
      -webkit-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      background: $ci-color-14;
    }

    input#toggle + label:after {
      content: "";
      position: absolute;
      display: block;
      height: 14px;
      width: 14px;
      top: 0;
      left: 0;
      border-radius: 8px;
      background: $color-white;
      @include transition(.25s ease-in-out);
      box-shadow: inset 0 0 0 1px #ccc;
    }

    input#toggle:checked + label:before {
      width: 25px;
      background: $highlight-color;
    }

    input#toggle:checked + label:after {
      left: 11px;
      box-shadow: inset 0 0 0 1px $highlight-color;
    }


    .gray {
      .nx-toggle-switch {
        input#toggle:checked + label:before {
          width: 25px;
          background: $ci-color-14;
        }

        input#toggle:checked + label:after {
          left: 11px;
          box-shadow: inset 0 0 0 1px red;
        }
      }
    }

    &.BIG {
      input#toggle + label {
        height: 28px !important;
        width: 50px !important;
        border-radius: 14px !important;

        &:hover {
          cursor: pointer !important;
        }
      }

      input#toggle + label:before {
        height: 28px !important;
        width: 28px !important;
        border-radius: 14px !important;
      }

      input#toggle + label:after {
        height: 28px !important;
        width: 28px !important;
        border-radius: 14px !important;
      }

      input#toggle:checked + label:before {
        width: 50px !important;
      }

      input#toggle:checked + label:after {
        left: 22px !important;
      }
    }
  }
}
