.searchFilter {
  .chip {
    position: relative;
    top: -10px;
    left: -5px;
  }
}
.chip {
  background-color: darken($background-color,5);
  border-radius: 15px;
  min-width: 60px;
  display: inline-block;
  color: $font-color;
  height: 25px;
  margin: 5px 0 0 5px;
  line-height: 25px;
  padding-left: 10px;
  font-weight: 400;
  border: 1px solid darken($background-color,8);
  animation: fadeIn 0.2s linear;
  cursor: pointer;
  .fa {
    float: right;
    padding-right: 10px;
    padding-left: 10px;
    line-height: 24px;
    color: lighten($font-color,10);
    margin-left: 5px;
    margin-right: -2px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  &.active{
    background-color: $color-neutral;
    color: $color-neutral-contrast;
    border: 1px solid darken($color-neutral,8);
    font-weight: 400;
    &:hover {
      .fa{
        &:hover {
          background-color: darken($color-neutral,10);
        }
      }
    }
  }
  &.enabled{
    background-color: darken($background-color,5);
    color: $font-color;
    border: 1px solid darken($background-color,8);
    &:hover {
      .fa{
        &:hover {
          background-color: darken($background-color,10);
        }
      }
    }
  }
  &.enabeld_no_icon {
    background-color: darken($background-color,5);
    color: $font-color;
    border: 1px solid darken($background-color,8);
    &:hover {
      .fa{
        display: none;
      }
    }
  }
  &.disabled{
    opacity: 0.4 !important;
    cursor: pointer !important;
    background-color: darken($background-color,10);
    color: $font-color;
    border: 1px solid darken($background-color,8);
    display: inline-block !important;
  }
  &.disabled_no_icon {
    opacity: 0.4;
    cursor: not-allowed !important;
    background-color: darken($background-color,10);
    color: $font-color;
    border: 1px solid darken($background-color,8);
    padding-right: 10px !important;
    display: inline-block !important;
    .fa {
      display: none;
    }
  }
  &.completed{
    background-color: $color-success;
    color: $color-success-contrast;
    border: 1px solid darken($color-success,8);
    &:hover {
      .fa{
        &:hover {
          background-color: darken($color-success,10);
        }
      }
    }
  }
  &.selected {
    color: $font-color;
    border-color: $font-color;
    background: $background-color;
    &:hover {
      .fa {
        &:hover {
          background-color:$font-color !important;
          color: $background-color !important;
        }
      }
    }
  }
  &:hover {
    color: $font-color;
    background-color: lighten($background-color,10);
    cursor: pointer;
    .fa {
      color: darken($font-color,10);
      cursor: pointer;
      &:hover {
        background-color: darken($background-color,10);
      }
    }
  }
}
