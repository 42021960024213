@include cdk-overlay();
.cursor-move {
cursor: move;
}

.draggable {
  cursor: move;
  user-select: none;
}

.movable:not(.dragging) {
  transition: transform 300ms ease-in-out;
}

.tableDragBox {
  &.sortable.dragging {
    background: none;
    border: 1px dashed black;
    color: transparent;
  }
}

.dragBox {
  box-shadow: $shadow-base-extra-light;
  border-top: 1px solid darken($background-color,20);
  padding: 10px 20px;
  transition: all        0.2s;
  display: inline-block;
  width: 50vw;

  .fa-ellipsis-v {
    padding-right: 10px;
    margin-left: -10px;
  }

  app-checkbox {
    display: table-caption;
    float: right;
    padding-bottom: 0px !important;
    height: 20px;
  }

  &:hover {
    box-shadow: $shadow-base-div;
    border-left: 0.2rem solid $highlight-color ;
    border-right: 0.2rem solid $highlight-color ;
  }

  &.dragging {
    background: coral;
  }

  &.sortable.dragging {
    background: none;
    border: 1px dashed black;
    color: transparent;
  }
}

.sortableList {
  display: inline-flex;
  flex-direction: column;
  padding-left: 0px;
  margin-left: -13px;
  padding-bottom: 10px;
}

.sortableColumn {
  list-style: none;
  border: 1px solid;
  padding: 10px;
}
