.tabs {
  margin-top: 1rem;
  &.browserIsIE {
    .tabList {
      overflow: visible !important;
    }
  }

  .viewport {
    border-bottom: 1px solid $color-border;
    height: 38px;
    width: calc(50vw - 61px);
    margin-left: 30px;
    &.align-left {
      margin-left: 0px !important;
      width: 50vw
    }

    .tabList {
      padding-left: 0;
      list-style-type: none;
      overflow: hidden;
      display: inline-flex;
      position: relative;
      .listItem {
        display: block;
        float: left;
        padding: 8px;
        border-bottom: 1px solid $color-border;
        border-top: 1px solid $color-border;
        max-height: 32px;
        white-space: nowrap;
        background-color: $color-side-panel-background;
        line-height: 16px;
        margin-top: 6px;
        position: relative;
        &:after {
          content: '';
          height: 100%;
          width: 1px;
          position: absolute;
          top: 0;
          right: 0;
          background-color: $color-border;
        }
        &:first-child {
          border-left: 1px solid $color-border;
        }
        &:last-child {
          &.selected {
            border-right: 1px solid $color-border;
          }
        }
        &.hidden {
          display: none;
        }
        &.selected {
          z-index: 5;
          box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
          border-bottom-color: $background-color;
          background-color: white;
          margin-top: 3px;
          max-height: 38px;
          line-height: 18px;
          font-weight: bold;
          &:hover {
            background-color: lighten(white, 3);
          }
          &:after {
            content: '';
            height: 88%;
            width: 1px;
            position: absolute;
            top: 4px;
            right: -1px;
            background-color: $color-border;
          }
        }
        &:hover {
          cursor: pointer;
          background-color: lighten($color-side-panel-background, 3);
        }
        &.finished {
          border-top: 3px solid $color-success;
        }
      }
    }
  }

  .tabSetScrollButton {
    position: relative;
    z-index: 1;
    height: 32px;
    width: 30px;
    outline: none;
    background-color: $background-color;
    border: 1px solid $color-grey;
    &.left {
      float: left;
      margin-left: 0px;
      border-left: none;
      top: 6px;
    }

    &.right {
      float: right;
      margin-right: 0px;
      border-right: none;
      top: -32px;
    }
    &:hover {

    }
  }
}

.tabContent {
  padding-bottom: 10px;
  border-bottom: 1px solid $color-border;
  margin: 0px;

  .innerTabContent {
    margin: 10px 12px 0;

    .dropdown-container {
      width: 200px;
    }

    .placeholder-button {
      float: right;
      &.disabled {
        cursor: not-allowed;
      }
    }

    .calculation-price {
      line-height: 30px;
    }

    .supplier_logos {
      max-height: 30px;
      float: right;
      display: block;
      margin-top: 10px;
    }
  }
}

.calculation-tooltip {
  padding: 5px;
}

.pseudo-article {
  border-top: 1px solid $color-grey-light;
  border-bottom: 1px solid $color-grey-light;
  height: 100px;
  background-color: $color-breadcrump !important;
  margin-top: 10px;
  margin-bottom: -13px;

  img {
    max-width: 100px;
    max-height: 80px;
  }

  .wrapper-row {
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-top: 10px;
  }

  .base-btn {
    position: relative;
    margin-top: 0;
    float: right;
  }

  .brand, .description {
    font-weight: 300;
    color: $ci-color-1;
  }

  .brand {
    font-size: 1.15em;
    font-weight: 500;
    color: darken($ci-color-1, 25);
  }

  .product-name {
    font-style: italic;
    font-weight: 300;
  }

  .description {
    min-height: 150px;
    font-weight: 500;
    color: lighten($ci-color-1, 25);
    font-size: 1.05em;

    &.small {
      min-height: 40px;
    }
  }
}
