
.availability-panel, .supplier-row {
    margin-bottom: 5px;
    display: block;

    transition: all .15s ease-in-out;

        .available, .not-available, .other-shop, .asap, .invalid-credentials, .shipping {
            margin-bottom: 10px;
        }
        .icon {
            float: left;
            height: 30px;
            width: 20px;
            margin-right: 10px;
            position: relative;
            top: 6px;
        }

    .no-supplier-row {
        min-height: 55px;
        line-height: 45px;
        padding: 5px 0;
        border-top: 1px solid $color-grey-light;

        .base-btn {
            position: initial;
            float: right;
            margin-top: 8px;
            margin-right: -6px;
            margin-bottom: 5px;
        }
    }
    .rowTable {
        display: inline-flex;
        align-items: center;
        width: 100%;
        min-height: 55px;
        line-height: 30px;
        padding: 5px 0;
        border-top: 1px solid $color-grey-light;
        transition: all .15s ease-in-out;
        &.disabled {
            &:hover {
                cursor: not-allowed;
                border-left: 0.2rem solid $color-grey-light;
                border-right: 0.2rem solid $color-grey-light;
            }
        }
        .light{
            color:lighten($font-color,35);
        }
        .a-order{
            display: none;
            transition: all .75s ease-in-out;
            .availability-infos {
                border-bottom: 1px solid $color-grey-light;
            }
            &.button-div {
                padding-right: 0 !important;
                .text-right {
                    margin-top: -0.5rem !important;
                    padding-right: 0 !important;
                }
            }
        }
        .a-logo,
        .a-add-to-cart-btn,
        .a-price,
        .a-availability {
            width: 20%;
            float: left;
            padding: 0.1rem 0.2rem !important;
        }

        .a-availability {
            padding: 0.1rem 0.2rem !important;
            width: 40%;
            .button-div {
                padding-right: 0 !important;
                margin-top: -0.5rem !important;
            }
            .availability-line {
                display: flex;
                justify-content: center;
                flex-direction: column;
                height: 30px;
            }
        }

        &.selected{
            .a-order {
                display: block;
                width: 100%;
                padding-bottom: 5px;
                margin-top: 5px;
                border-top: 1px solid $color-grey;
            }

            .a-price-area,
            .a-button-area {
                display: inline-block;
                width: 100%;
            }

            .a-price-area {
                text-align: left !important;
            }

            .a-button-area {
                border-top: 1px solid $color-grey;
                padding-top: 10px;
            }

            .highlight-color {
                color: $highlight-color;
                font-weight: bold;
            }

            .in-cart-btn{
                background-color: $color-success;
                color: $color-success-contrast;
                font-size: 1rem;
                font-weight: 800;
                position: relative !important;
                margin-top: 0 !important;
                top: calc(50% - 10px);
                margin-right: 8px;
            }
            background-color: $highlight-color !important;
            background: $highlight-color !important;
            td{
                color: $color-white !important;
                .supplier-name,
                .price,
                .text{
                    color: $color-white !important;
                }
            }
        }
        &.selected,
        &:hover {
            background-color: white !important;
            box-shadow: $shadow-base-div;
            border-left: 0.2rem solid $highlight-color;
            border-right: 0.2rem solid $highlight-color;
            cursor: pointer;
        }
        &.selected {
            cursor: default;
        }
    }

    .quantity-change{
        padding: 1rem 0px;
        background-color: $color-grey-light;
    }

    .text {
        float: left;
        display: inline-block;
        line-height: 18px;
        vertical-align: middle;
    }

    .price{
        -webkit-transition: opacity 2s ease-in;
        -moz-transition: opacity 2s ease-in;
        -o-transition: opacity 2s ease-in;
        -ms-transition: opacity 2s ease-in;
        transition: opacity 2s ease-in;
    }

    .supplier-name{
        font-weight: 900;
        color: $font-color;
    }

    .AVAILABLE {
        .icon {
            background: $available;
        }
    }

    .NOT_AVAILABLE, .MISSING_ARTICLE_INFORMATION, .WRONG_ARTICLE_TYPE
    {
        .icon {
            background: $not-available;
        }
    }

    .BACKORDER {
        .icon {
            background: $backorder;
        }
    }

    .SHIPPING,
    .SHIPPING24,
    .SHIPPING48,
    .OTHER_SHOP {
        .icon {
            background: $other-shop;
        }
    }

    .ASAP {
        .icon {
            background: $asap;
        }
    }
    .CONNECTION_BROKEN{
        .icon {
            background: $connection-broken;
        }
    }

    .SPECIAL_ORDER{
        .icon {
            background: $special-order;
        }
    }

    .OWN_STOCK{
        .icon {
            background: $own-stock
        }
    }

    .INVALID_CREDENTIALS {
        .icon {
            background: $invalid-credentials;
        }
    }

    tr{
        &:hover{
            background: $ci-color-7;
            cursor: pointer;
        }
    }

    .loading {
        .text{
            display: none;
        }
        .icon{
            border-radius: 50%;
            height: 18px;
            width: 20px;
            border: 5px dashed $highlight-color;
            animation: loading 0.8s linear infinite;
            background: none !important;
        }
    }

    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .cartSelection{
        td{
            width: 100%;
            background: $ci-color-4;
            color: $font-color;
            border-top:2px solid $color-success;
            button{

            }
        }
    }
}

