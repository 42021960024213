  .user-menu {
    position: relative;
    float: right;
    &.lang {
      background: $color-grey-light;
      .dropdown-btn{
        min-width: 75px !important;
        padding-left: 0px;
      }
      .label{
        width: 50px !important;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
    &.themes {
      background: $color-grey-light;
      .label{
        font-weight: bold;
      }
    }
    .dropdown-btn {
      border-radius: 0px;
      vertical-align: middle;
      position: relative;
      height: 50px;
      line-height: 50px;
      color: $font-color-navigation;
      padding: 0 15px 0 15px;
      border: none;
      min-width: 170px;
      background-color: transparent;
      font-weight: 300;
      @include transition(all 0.2s ease-in-out);

      &:after {
        @include clearfix();
      }

      &:hover {
        cursor: pointer;
        background-color: darken($background-color,5);

        .label, i {
          color: $ci-color-1;
        }
      }

      &:focus {
        outline: none;
      }

      &.opened {
        box-shadow: $shadow-base;
      }
    }

    .icon, .label {
      float: left;
    }

    .label {
      padding: 0;
      font-size: 14px;
      line-height: 30px;
      vertical-align: middle;
      width: 100px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .icon {
      text-align: left;
      width: 20px;
      font-size: 20px;
      position: relative;
      margin-right: 5px;
      line-height: 30px;
      vertical-align: middle;
    }

    .elements {
      z-index: 10;
      position: relative;
      list-style-type: none;
      padding: 0;

      box-shadow: $shadow-base-light;
      li {
        background: $background-color;
        color: $ci-color-1;
        height: 30px;
        padding: 0 15px;
        margin: 0;
        line-height: normal;
        @include transition(all 0.2s ease-in-out);
        border-bottom: 1px solid $ci-color-4;

        span {
          font-size: 1rem;
          float: left;
          margin-left: 5px;
          line-height: 29px;
          vertical-align: middle;
        }

        i {
          text-align: left;
          width: 20px;
          font-size: 20px;
          float: left;
          display: block;
          line-height: 29px;
          vertical-align: middle;
        }

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          cursor: pointer;
          background-color: darken($background-color,5);
          color: $ci-color-1;
        }
      }
    }

    .fa-caret-down {
      position: absolute;
      right: 10px;
      top: 15px;
      font-size: 20px;
    }

    .tooltip {
      background-color: $ci-color-18;
    }

    &.notification-button {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 1.4rem;
      height: 50px;
      line-height: 50px;
      &.fa-envelope {
        color: $font-color;
      }

      .INFO {
        position: relative;
        top: -10px;
        right: 5px;
        border-radius: 50px;
        width: 20px;
        height: 20px;
        color: white;
        font-size: 0.75em;
        padding: 0 5px;
        background-color: $color-info;
      }

      .WARNING {
        position: relative;
        top: -10px;
        right: 5px;
        border-radius: 50px;
        width: 20px;
        height: 20px;
        color: white;
        font-size: 0.75em;
        padding: 0 5px;
        background-color: $color-warning;
      }

      .ERROR {
        position: relative;
        top: -10px;
        right: 5px;
        border-radius: 50px;
        width: 20px;
        height: 20px;
        color: white;
        font-size: 0.75em;
        padding: 0 5px;
        background-color: $color-danger;
      }

      &:hover {
        cursor: pointer;
        background-color: darken($background-color,5);
      }
      &.noMessages {
        .fa-envelope {
          color: $color-grey;
        }
      }
    }
  }

  .notification-box {
    position: absolute;

    @include transition(all 0.45s ease-in-out !important);

    //height: 50vh;
    max-height: 50vh;
    top: 38px;
    z-index: 101;
    width: 0;
    right: -810px;
    transition: all 300ms ease;

    .notification-content {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(50vh - 40px);
      .row {
        margin-left: 0;
        border-bottom: 1px solid #d6dbdc;
        &.WARNING, &.WARN {
          border-left: 5px solid $color-warning;
        }
        &.INFO {
          border-left: 5px solid $color-info;
        }
        &.ERROR {
          border-left: 5px solid $color-danger;
        }
        .notification-message {
          padding-left: 10px;
        }
        .notification-option {
          .fa-eye-slash {
            font-size: 1.6rem;
          }
        }

      }
      .alert-info {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
      }
    }

    &.showNotifications {
      width: 24.6vw;
      right: 0;
      z-index: 2000;
      background-color: $background-color;
      top: 50px;
      &.browserIsIE {
        width: 69% !important;
      }
    }
  }
