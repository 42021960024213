.maintenance {
  height: 100%;

  .content {
    display: flex;
    height: 100%;
    table-layout: unset;
    width: unset;
  }

  .left-panel, .center-panel, .right-panel {
    display: block;
    position: relative;
  }

  .inner-content {
    height: auto;
  }

  .alert-info {
    margin-top: 10px;
    margin-right: .5%!important;
  }

  .clickable {
    cursor: pointer;
  }

  .padding-top {
    padding-top: 20px;
  }

  .disabled {
    display: block;
    cursor: not-allowed !important;
    opacity: 0.5;
    &.lbl {
      width: 20vw;
    }
    &:hover {
      cursor: not-allowed !important;
    }
  }
}
