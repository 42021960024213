.template {
  .fa-trash, .fa-pencil {
    margin-right: 15px;
    cursor: pointer;
    font-size: 1.5rem;
    &:hover {
      color: $highlight-color;
    }
  }
  .fa-plus {
    margin-right: 5px;
  }

  .dropdown-btn {
    border-radius: 0px;
    vertical-align: middle;
    position: relative;
    height: 34px;
    line-height: 34px;
    color: $font-color;
    padding: 0 15px 0 15px;
    border: none;
    min-width: 170px;
    background-color: transparent;
    font-weight: 300;
    @include transition(all 0.2s ease-in-out);

    &:after {
      @include clearfix();
    }

    &:hover {
      cursor: pointer;
      background-color: darken($background-color,5);

      .label, i {
        color: $color-black;
      }
    }

    &:focus {
      outline: none;
    }

    &.opened {
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2), 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
  }

  .elements {
    z-index: 1000;
    list-style-type: none;
    padding: 0;
    width: 170px;
    position: absolute;
    right: 15px;

    box-shadow: $shadow-base-light;
    li {
      background: $background-color;
      color: $ci-color-1;
      height: 30px;
      padding: 0 15px;
      margin: 0;
      line-height: normal;
      @include transition(all 0.2s ease-in-out);
      border-bottom: 1px solid $ci-color-4;

      span {
        font-size: 1rem;
        float: left;
        margin-left: 5px;
        line-height: 29px;
        vertical-align: middle;
      }

      i {
        text-align: left;
        width: 20px;
        font-size: 20px;
        float: left;
        display: block;
        line-height: 29px;
        vertical-align: middle;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        cursor: pointer;
        background-color: darken($background-color,5);
        color: $ci-color-1;
      }
    }
  }
}