.advanced {
  .spacer {
    margin-bottom: 25px;
  }

  hr {
    margin-top: 0px;
  }

  .subHeader {
    font-size: 1.2rem;
  }
}
