.order-history {
  .orderHistoryHeader {
    h1 {
      font-weight: 600;
    }
  }
  .center-content {
    overflow-y: auto;
    overflow-x: hidden;
    .item-list {
      padding: 12px
    }
    &.hasResults {
      margin-top: 3rem;
      height: calc(100vh - 3rem - 34px - 100px);
      &.showPagination {
        height: calc(100vh - 3rem - 34px - 100px - 40px);
      }
    }
  }
  .app-articles-row {
    min-height: unset;
    .centerItem {
      margin-left: auto;
      margin-right: auto;
      font-size: 1rem;
      &.fa-spin {
        font-size: 2rem;
      }
    }
  }


}
app-loading.order-history {
  left: 0;
  right: 0;
  position: absolute;
  height: 100vh;
  &[ng-reflect-show="false"] {
    display: none;
  }
}
