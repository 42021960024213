.add-custom-comment {
  .comment-row {
    margin-top: 20px;
  }
  .back {
    margin-top: 2rem;
  }
  .textarea {
    width: 100%;
    min-height: 200px;
  }
}
