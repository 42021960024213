.firm-admin {
  .right-panel {
    .collapsible-panel {
      margin-right: 10px !important;
    }
  }
}

.firmdataCSS {
  .isActive {
    margin-top: 10px;
  }

  .rights {
    margin-top: 10px;
  }
}
