
.vinlog{
  button{
    &.disabled {
      cursor: not-allowed;
      opacity: .5;
    }
  }
  &.item-list{
    li {
      padding-right: 2rem;
      i {
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin-right: -1rem;
      }
    }
  }
  .margin{
    margin-top: 100px;
  }
}
