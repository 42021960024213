.articles {
  .change-center-height {
    &.panel-content.center-content {
      // increased cause legend and resort btn
      height: calc(93vh - 100px - 4.5rem - 4px);
    }
  }
  .show-pseudo-article {
    top: 134px !important;
    .articles-search-result {
      .app-articles-row:first-child {
        border-top: none;
      }
    }
  }

  .badge {
    font-size: 100%;
  }

  .show-quick-filter {
    // increased cause legend and resort btn
    top: 120px ;
    border-top: 1px solid $color-grey-light;
    .articles-search-result {
      .app-articles-row:first-child {
        border-top: none;
      }
    }
    &.show-pseudo-article {
      top: 189px !important;
    }
  }

  .quick-filter {
    background-color: $color-breadcrump !important;
    .row {
      margin-left: 5px;
      margin-right: 5px;
      .base-btn {
        margin-top: 0;
        right: 15px;
      }
      .first-text {
        line-height: 30px;
        margin-right: 10px;
      }
      .second-text {
        line-height: 30px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  .pseudo-article {
    border-top: 1px solid $color-grey-light;
    border-bottom: 1px solid $color-grey-light;
    height: 100px;
    background-color: $color-breadcrump !important;

    .base-btn {
      position: relative;
      margin-top: 0;
      float: right;
    }

    .brand, .description {
      font-weight: 300;
      color: $ci-color-1;
    }
    .brand {
      font-size: 1.15em;
      font-weight: 500;
      color: darken($ci-color-1,25);
    }
    .product-name{
      font-style: italic;
      font-weight: 300;
    }
    .description {
      min-height: 150px;
      font-weight: 500;
      color: lighten($ci-color-1,25);
      font-size: 1.05em;
      &.small {
        min-height: 40px;
      }
    }

    .image {
      background-size: cover;
      background-position: center center;
      height: 100vh;
      max-height: 74px;
      margin-left: 20px;
      max-width: 100%;
    }

    .value {
      font-weight: bold;
    }

    .important {
      font-weight: bold;

      .value {
        color: $ci-color-7;
      }
    }
  }

  .alert-info {
    margin-top: 10px;
  }
  .detailWidth {
    width: 25vw;
  }

  .spinner {
    margin-top: 20px;
    width: 2.5rem;
    margin-left: auto;
    margin-right: auto;
    .fa-spinner {
      font-size: 2.5rem;
    }
  }
  .searchResultSupplier {
    float: right;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;

    .fa-truck {
      margin-right: 5px;
    }

    .searchResultSupplierSelect {
      height: 34px;
      min-width: 95px;
      margin-right: -5px;
      border: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      text-align: left;
      outline: none;
      font-weight: 400 !important;
      color: #3c3c3b;
      background: transparent;
    }
    &:hover {
      background-color: darken($background-color,5);
    }
    &::after {
      content: "\f0d7";
      font-family: "FontAwesome", sans-serif;
      color: #3c3c3b;
      position: relative;
      background: transparent;
      z-index: 1;
      text-align: center;
      height: 100%;
      width: 2rem;
      pointer-events: none;
      box-sizing: border-box;
      right: 0em;
      font-size: 16px;
    }
  }
  .name-limit{
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 60px;
    overflow: hidden;
    display: inline-block;
    line-height: 10px;
  }
  @media screen and (max-width: 1700px) {
    .name-limit{
      width: 40px;
    }
    .main-search-form{
      min-width: 120px !important;
    }
  }

  @media screen and (max-width: 1550px) {
    .articleSize {
      display: none;
    }
    .name-limit{
      width: 10px;
    }
  }

  .filterBar {
    min-height: 30px;
    background-color: $color-white;
    margin-top: -5px;
    display: inline-block;
    width: 50vw;
    .fa-angle-down,
    .fa-angle-up {
      font-size: 20px;
      margin: 5px 5px 0 5px;
      line-height: 26px;

      position: absolute;
      top: 0;
      right: 20px;
      &:hover {
        cursor: pointer;

      }
    }
    .chipBar {
      width: calc(50vw - 30px);

      &.open {
        width: calc(50vw - 10px);
        padding-top: 20px;
      }
      .filterType {
        margin-left: 10px;
        font-weight: bold;
        border-bottom: 1px solid #c1c1c1;
        padding-bottom: 10px;
        padding-top: 10px;
        &:nth-last-child(1) {
          border-bottom: none;
        }
        &:nth-child(0) {
          padding-top: 0px;
        }
      }
    }
  }

  .rowSizeSelect {
    height: 34px;
    min-width: 65px;
    margin-right: -5px;
    border: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    text-align: left;
    outline: none;
    font-weight: 400 !important;
    color: $font-color;
    background: transparent;

    &::-ms-expand {
      display: none;
    }

    option {
      font-weight: normal;
      line-height: 1em;
      white-space: nowrap;
      -webkit-appearance: button;
      text-overflow: ellipsis;
      background: transparent;
    }
  }

  .logo-right-side {
    max-width: 250px;
    margin-left: 50px;
  }

  .base-btn {
    position: absolute;
    margin-top: 30px;
  }

  .disabled {
    cursor: not-allowed;
    opacity: 0.5;
    &:hover {

    }
  }

  .right-panel-empty {
    background-color: #f5f5f5;
    min-height: 45px;
    margin-top: -10px;
    margin-left: -10px;
    margin-right: -2px;
    span {
      display: block;
      line-height: 45px;
      text-align: center;
      height: 45px;
      color: #a8a8a8;
    }
  }

  .showAllArticles {
    border: none;
    background: #fafafa;
    color: #6fc0bb;
    margin-top: 0px;
    float: right;
  }

  .padding-top {
    padding-top: 10px;
  }

  .filterVar {
    margin-left: 15px;
  }

  .overflow-off {
    overflow: hidden;

  }
  height: 100%;
  .articleSize {
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 15px;
    float: right;
    .fa-list {
      margin-right: 5px;
    }
    &:hover {
      cursor: pointer;
      background-color: darken($background-color,5);
    }
    &::after {
      content: "\f0d7";
      font-family: "FontAwesome", sans-serif;
      color: $font-color;
      position: relative;
      background: transparent;
      z-index: 1;
      text-align: center;
      height: 100%;
      width: 2rem;
      pointer-events: none;
      box-sizing: border-box;
      right: 0em;
      font-size: 16px;
    }
  }
  .btn-group-xs {
    height: 34px;
    border-radius: 0px;
    .btn {
      background: transparent;
      color: $font-color;
      cursor: pointer;
      border-radius: 0px;
      &.active {
        background: transparent;
        color: $font-color-dark;
        cursor: auto;
      }
      &:active {
        box-shadow: none;
        border: none;
      }
    }

  }

  .assembly-categories-wrapper {
    //color: $ci-color-11;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .assembly-categories-tree {
      position: relative;
      min-height: 44px;

      &.large-content {
        height: calc(100%);
        transition: height 0.75s ease;
      }

      &.small-content {
        height: 60%;
        transition: height 0.75s ease;
      }

    }

    .assembly-categories-filter {
      position: relative;
      min-height: 44px;

      .expand-compress-icon {
        float: right;
        margin-right: 8px;
      }

      &.large-content {
        height: 40%;
        transition: height 0.75s ease;
      }

      &.small-content {
        height: 44px;
        transition: height 0.75s ease;
      }

    }

  }

  .clickable {
    cursor: pointer;
  }

  .dropdown-wrapper {
    padding: 8px 12px;

    .dropdown {
      .dropdown-btn {
        .placeholder {
          color: $ci-color-15;
        }
        .select-icon {
          background: transparent;
        }
      }
    }
  }

  .toggle-view {
    float: right;
    margin-top: 7px;

    div {
      float: left;
      font-size: 16px;
      margin-right: 10px;
      line-height: 30px;
      vertical-align: middle;
    }

    i {
      padding: 0 10px;
      font-size: 21px;
      float: left;
      display: inline-block;
      border: 1px solid $ci-color-4;
      line-height: 28px;
      vertical-align: middle;
      @include transition(all 0.3s ease);

      &:hover {
        cursor: pointer;
        background-color: $ci-color-3;
        color: $ci-color-6;
      }

      &.selected {
        background-color: $ci-color-4;
        color: $ci-color-6;
      }

      &:first-of-type {
        @include border-top-left-radius(5px);
        @include border-bottom-left-radius(5px);
        border-right: 0;
      }

      &:last-of-type {
        @include border-top-right-radius(5px);
        @include border-bottom-right-radius(5px);
        border-left: 0;
      }
    }
  }

  .sellin-price-switch {
    float: right;
    margin: 0 0;

    &:after {
      @include clearfix();
    }

    label {
      color: $color-white;
      float: left;
      font-size: 14px;
      margin-right: 10px;
    }
  }
}

