.app-article-details {
  .quantity-in-cart {
    font-size: 1.2rem;
    float: right;

    .fa-shopping-cart {
      font-size: 1.5rem;
    }
  }

  .disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {

    }
  }

  .margin-left {
    margin-left: 20px;
  }

  .no-margin-right {
    margin-right: 0px;
  }

  .spacer {
    padding-top: 10px;

    .base-btn {
      margin-top: 0;
      position: relative;
    }
  }

  .price {
    color: $price-color;
    font-weight: bold;
  }

  .headLine {
    color: lighten($ci-color-1, 10);
    display: block;

    &.description {
      color: lighten($ci-color-1, 30);
      font-weight: bold;
    }
  }

  .vpe-info {
    display: inline-block;
    margin-left: 5px;
  }

  .data {
    font-weight: bold;
  }

  .disclaimer {
    margin-top: 10px;
    width: 100%;
    background: $highlight-color;
    color: $background-color;
    padding: 1rem;

    span {
      padding: 1rem;
    }

    .fa-info-circle {
      float: left;
      font-size: 3rem;
      margin-right: 1rem;
      margin-top: 12px;
    }
  }

  .requestQuantity {
    background-color: $highlight-color;
    color: $highlight-color-contrast;
    border: 2px solid darken($highlight-color, 5);
    padding: 5px 5px 5px 10px;
    margin: 10px 15px -10px 0px;
  }

  .article-details-header {
    margin-bottom: 30px;
  }

  .vpe_headLine {
    color: grey;
    width: 100%;
    text-align: right;
  }

  .vpe {
    width: 100%;
    text-align: right;
    font-weight: bold;
  }

  .row {
    margin-left: 0px;
  }

  .tab-container {
    ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    &.three-tabs {
      ul {
        grid-template-columns: 1fr 1fr 0.2fr;

        li:last-child {
          a {
            background-color: $highlight-color !important;
            color: $highlight-color-contrast !important;

            &.active {
              background-color: $highlight-color-contrast !important;
              color: $highlight-color !important;
            }
          }
        }
      }

      svg {
        width: 1.5rem;
      }
    }
  }


  a {
    color: $color-neutral !important;

    &:hover {
      text-decoration: underline;
    }
  }

  .blue-tooltip {
    .tooltip-arrow {
      border-right-color: transparent;
      border-top-color: $ci-color-5 !important;
    }

    .tooltip-inner {
      max-width: unset !important;
      text-align: left;
      background-color: $ci-color-5 !important;
    }
  }

  .work-position {
    border-bottom: 1px solid white;
    padding: 5px 0;

    &:last-child {
      border-bottom: none;
    }

    .work-time {
      font-weight: bold;
    }

    .work-text {

    }
  }

  .image {
    margin-bottom: 10px;
    margin-left: auto;

    img {
      margin: 0 auto;
      display: block;
      max-width: 100%;
      height: auto;
      max-height: 150px;
      border: 1px solid $color-grey-light;
    }
  }

  .short-info {
    margin-top: 15px;
  }

  table {
    width: 100%;

    &.general-data {
      td {
        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-left: 17px;
        }

        .oe-numbers-list {
          height: 100px;
          overflow: auto;
        }
      }
    }

    td {
      padding: 0 15px;
      width: 50%;
    }

  }

  .striped-table,
  .info-table {
    width: 100%;

    .border-bottom-light-grey {
      border-bottom: 1px solid $color-grey-light;
    }

    .border-bottom-light-grey:last-child {
      border-bottom: none;
    }

    &.document-table {
      td:first-child {
        &:hover {
          cursor: pointer;
        }
      }
    }

    tbody {
      tr {

        &:nth-child(odd) {
          background-color: $ci-color-odd;
        }

        &:nth-child(even) {
          background-color: $ci-color-even;
        }

        &.loading {
          td {
            text-decoration: inherit;
          }
        }

        td {
          padding: 10px 15px;
          line-height: 2rem;

          .custom-checkbox {
            float: right;
            width: unset;
          }

          label {
            display: block !important;
          }

          &:last-child {
            i {
              @include transition(all 0.2s ease-in-out);
              color: $ci-color-8;
              font-size: 16px;
              text-align: right;

              &:hover {
                color: $ci-color-1;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .info-table {
    td {
      padding: 5px 15px !important;
      border-top: 1px solid darken($background-color, 3);
    }

    tr {
      &:first-of-type {
        td {
          border-top: none;
        }
      }
    }
  }

  .clickable {
    cursor: pointer;
  }

  .lightbox {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    outline: none;
    display: block;
    visibility: inherit !important;
    opacity: 1 !important;

    img {
      max-width: 90%;
      max-height: 80%;
      margin-top: 2%;
    }
  }

  .cart-amount-input {

    &:after {
      @include clearfix();
    }

    .value-input {
      position: relative;
      float: left;

      .fa-shopping-cart {
        position: absolute;
        left: 8px;
        top: 6px;
        color: $ci-color-4;
        font-size: 18px;
      }

      input {
        text-align: center;
        width: 45px;
        padding: 6px 3px;
        background-color: $color-white;
        border: 1px solid $ci-color-9;
        @include border-radius(5px);
      }
    }

    .amount-controls {
      float: left;
      margin: 4px 0 0 8px;

      i {
        color: $ci-color-1;
        font-size: 25px;
        display: inline-block;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        @include transition(all 0.2s ease-in-out);

        &:hover {
          cursor: pointer;
          color: $ci-color-5;
        }
      }
    }
  }

  .modelName {
    padding: 10px 15px 0px;
    display: block;
    font-weight: 900;
  }
}
