.message {
  background-color: rgba(0, 0, 0, 0.75);
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: absolute;
  z-index: 1000;

  .inner-modal {
    background-color: white;
    margin-top: 100px;
    margin-left: 24vw;
    margin-right: 25vw;
    height: 215px;
  }

  .header-title {
    border-bottom: 2px solid $highlight-color;
    color: $font-color;
    font-weight: 600;
    padding: 7px 5px 7px 0px;
    span {
      padding-left: 10px;
    }
  }

  .fa-times {
    float: right;
    font-size: 1.5rem;
    padding-right: 10px;
    &:hover {
      color: $highlight-color;
      cursor: pointer;
    }
  }

  .textFirst {
    display: block;
    padding-top: 20px;
    text-align: center;
    font-size: 1.5rem;
  }

  .textSecond {
    display: block;
    text-align: center;
    font-size: 1.5rem;
  }

  .buttonBar {
    margin-right: 20px;
    margin-top: 25px;
    .base-btn {
      margin-left: 10px;
    }
  }
}

