@import 'colors';
@import 'fonts';
@import 'variables';
@import 'animate';
@import 'availability';

*:active, *:focus {
    outline: none;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}

@mixin placeholder {
    &::placeholder               {@content}
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
}
