.card{
  margin: 1rem;
}

.card,
.center-panel{
  margin-left: 0px !important;
  z-index: 1;
  display: inline-block;
  position: relative;
  box-shadow: $shadow-base-card;
  .card-title{
    padding: 1rem;
    img{
      max-height: 40px !important;
      width: auto;
    }
  }
  &.external-tools{
    margin: 1rem 0px 1rem 0px;
    width: 100%;
    padding: 1.3rem;
    font-size: 1.3em;
    font-weight:600;
    background: $color-neutral-contrast;
    color: $highlight-color;
    .fa {
      float: left;
      font-size: 1.6rem;
      margin-right: 1rem;
    }
  }
  &.external-tools_small{
    box-shadow: none;
    cursor: pointer;
     margin: 1rem 0px 0rem 0px;
     width: 100%;
    padding: 0rem;
    border: 0px;
     font-size: 1.1em;
     font-weight:600;
     background: $color-neutral-contrast;
     color: $highlight-color;
     .fa {
       float: left;
       font-size: 1.4rem;
       margin-right: 1rem;
     }
   }
  &.warning-recall-text,
  &.article-for-work{
    &.active {
      background: $highlight-color;
      color: $highlight-color-contrast;
      hr {
        border-top: 1px solid transparentize($color-white,.4);
      }
    }
    margin: 0;
    width: 100%;
    padding: 1.3rem;
    background: $highlight-color-contrast;
    color: $highlight-color;
    hr{
      border-top: 1px solid transparentize($highlight-color,.4);
      margin: 1rem -1rem
    }
    .expired {
    }
    .warning {
      .fa{
        color: $highlight-color-contrast;
      }
    }
  }
  &.card-1 {
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    &:hover {
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
  }

  &.card-2 {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  &.card-3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }

  &.card-4 {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

  &.card-5 {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }

  &.full-width-card{
    width: 100%;
    margin-left: 0px;
  }

}

.right-panel,
.left-panel{
  .card {
    display: block;
    box-shadow: none;
    border-radius: 0;

    &.d-contents {
      display: contents !important;
    }
  }

}
