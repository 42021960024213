.club-setting-cart-limit {
  .checkbox {
    margin-left: 15px;
  }

  .cart-setting {
    margin-left: 10px;
    margin-bottom: 20px;
    .material-form-input {
      width: 150px;
      margin-right: 10px;
      display: inline-block;
    }

    .custom-dropdown {
      display: inline-block;
      width: 150px;
      margin-bottom: -14px;

      &:after {
        content: "\f0d7";
        font-family: "FontAwesome", sans-serif;
        color: #3c3c3b;
        position: inherit;
        background: transparent;
        font-size: 1.5rem;
        z-index: 1;
        text-align: center;
        width: 2rem;
        pointer-events: none;
        box-sizing: border-box;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        margin-left: -11px;
      }
    }
  }
}
