.club-setting-externaltools {
  .content {
    .no-margin {
      h1{
        margin: 0;
      }
    }

    .col-6 {
      padding-bottom: 15px;
    }

    .cursor-default {
      cursor: default;
    }

    .club-setting-title {
      margin: 12px 15px 0px;
      cursor: default;
    }

    .drag-list {
      min-height: unset;
      .drag-box {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 6px !important;
        padding: 5px 0 !important;

        .checkbox {
          display: inline-block !important;
          float: right !important;
        }
      }
    }

    .base-btn {
      margin-left: 15px;
    }
  }
}
