.setting-external-tool {
  .list-wrapper {
    margin-left: -12px;
    margin-right: -12px;
    &.drag-list {
      margin-left: -12px;
      margin-right: -12px;
      width: auto;
      .drag-box {
        margin-left: 0;
        margin-right: 0;
        padding: 12px 5px 7px !important;
      }
    }
  }
}
