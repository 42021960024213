.cart-article-selection {

  .cursor-default {
    cursor: default;
  }

  .flex-container {

    .alert-success {
      margin: 1rem;
      height: 39px;
      .base-btn {
        margin-top: -4px;
        margin-right: -10px;
      }
    }
    display: flex;
    flex-flow: row wrap;
    flex-direction: column;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    &.browserIsIE {
      display: block !important;
    }

    .static-content {
      .tabs {
        margin-top: 0;
      }
      .alert {
        margin-top: 20px;
      }
      flex: 0.5;
      width: 100%;
      &.noScrollButton {
        .tabContent {
          margin: 0px 0px 0px 0px;
          .innerTabContent {
            padding-top: 45px !important;
          }
        }
      }
    }

    .scroll-content {
      width: 100%;
      flex: 7;
      overflow-y: auto;
      overflow-x: hidden;
      padding-bottom: 20px;
      li {
        margin: 0;
      }

      .articles-loading {
        padding-top: 20px;
        .fa-spinner {
          font-size: 3rem;
        }
      }

      .alert-info {
        margin-top: 20px;
      }

      .no-results {
        .alert-danger {
          margin: 12px;
        }
      }
    }

    .articles-search-result {
      margin-top: 0;
    }

    .app-articles-row {
      margin: 0;
      &:first-child {
        border-top: none;
      }
    }
  }
}
