.login-wrapper {
  background-color: darken($background-color,5);
  color: $font-color !important;
  letter-spacing: 1px;
  height: 100vh;
  padding: 5vh;
  background: url('/assets/images/global/background_auth.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: auto;
  .label,
  h1{
    margin: 3rem 0 !important;
  }
  p{
    font-size: 0.9rem;
  }
  .login {
    margin: 50px auto;
    width: 400px;
    padding: 1rem 3rem 3rem 3rem;
    color: $ci-color-7;
    background-color: $background-color;
    box-shadow: $shadow-base-card;
    .signin-btn,
    .reset-btn{
      background-color: $highlight-color;
      border: none;
      border-radius: 3px;
      color: #f4f4f4;
      cursor: pointer;
      font-family: inherit;
      height: 50px;
      text-transform: uppercase;
      width: 100%;
      -webkit-appearance:none;
      &:disabled {
        cursor: not-allowed;
        background: $color-grey;
      }
    }
    .reset-btn{
      margin: 3rem 0 1rem 0 ;
    }

    form fieldset a {
      color: #5a5656;
      font-size: 10px;
    }

    form fieldset a:hover { text-decoration: underline; }

  }
}

