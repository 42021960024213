.wizard{
  width: 100%;
  .wizard-header{
    margin:25px -5px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 25px;
  }
  .wizard-block{
    margin: 5rem 0rem;
    padding: 1rem 0rem;
    h1{
      text-align: center;
      font-weight: 900;
    }
    h2{
      text-align: center;
      margin-top: 3rem;
      font-size: 1.35rem;
      font-weight: 900;
      display: block;
    }
    .material-form-input input{
      font-size: 2rem;
    }
    .datepicker {
      border: none;
      margin: 6px 0px;
      font-size: 2rem;
      font-weight: 400;
      text-align: center;
      width: 100%;
      &:hover, &:focus {
        cursor: pointer;
      }
    }
  }
  .wizard-footer{
    margin: 5rem 5rem;
    padding-top: 2rem;
    border-top:1px solid darken($background-color,10);
  }
}
