/*
      TODO: NEW THEME

      1. open ../styles/app.scss
      2. define new theme as class
         sample: body.themename {}
      3. import theme scss file in new class:
         sampple: @import '../theme/themename';

      ============
      result:

        body.themename {
          @import '../theme/themename';
        }

      ============
*/

// ======================= LOAD GLOBAL ELEMENTS =========================== //

@import '../styles/modules/all';

// =========================== CUSTOM STYLES ============================== //


/*
  Posible variable can be found in following SCSS Files:

  ../styles/modules/_colors.scss
  ../styles/modules/_fonts.scss
  ../styles/modules/_variables.scss
 */

.logo,
.product-logo{
  background-image: url("/assets/images/theme/rtc.png");
}

$header-background-color: #252121;
$highlight-color: #f18200;
$header-topbar-background-color: #fefefe;












// ================================ LAST ELEMENTS ========================== //

/*               MUST BE LOADED IN EVERY THEME AFTER OVERWRITES              */

// ======================================================================== //


@import 'includes';
