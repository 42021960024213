.club-admin-dashboard {
  height: calc(100vh - 100px - 2rem - 4px) !important;
  position: relative;

  .table-wrapper {
    height: calc(100% - 11rem);
    .table-header {
      line-height: 3rem;
      height: 3rem;
      border-bottom: 1px solid $color-grey;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 1rem;
    }
    .table-body {
      height: calc(100% - 4rem);
      overflow-y: auto;
      overflow-x: hidden;
      .table-body-row {
        margin-left: 0;
        margin-right: 0;
        border-bottom: 1px solid $color-grey-light;
        padding-bottom: 1rem;
        padding-top: 1rem;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .no-result {
    .panel-content {
      position: initial !important;
    }
  }

  .clickable {
    cursor: pointer;
  }

  .fa-close {
    color: $color-danger;
    padding-right: 30px;
    z-index: 5000;
  }

  .fa-check {
    color: $color-success;
  }

  .btn-default {
    background-color: transparent;
    color: $color-white;
  }

  .simple-table {
    td {
      min-width: 35px;
    }
  }

  .firmList {
    margin-top: 110px !important;
  }

  .paginationList {
    margin-bottom: 40px;
  }

  .paginationBar {
    border-top: 1px solid #cccccc;
    position: absolute;
    bottom: 10px;
    width: 50vw;
    z-index: 2;
  }

  .centerItem {
    display: block;
    text-align: center;
    padding: 5px;

    .fa-spinner {
      font-size: 2rem;
      height: 25px;
    }
  }
}
