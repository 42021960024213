.work-data {
  display: flex;
  height: 100vh;

  .alert-info {
    margin-top: 10px;
  }

  .heading {
    position: absolute !important;
  }

  .search-box-swing {
    padding-left: 10px;
    .item-list {
      margin-left: -10px;
    }
  }

  &.isPagination {
    .center-content {
      height: calc(100vh - 130px);
    }
  }

  .padding-top {
    padding-top: 20px;
  }

  .disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .flex-container {
    display: flex;
    flex-flow: row wrap;
    flex-direction: column;
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    bottom: 0;

    &.browserIsIE {
      display: block !important;
    }

    .hasArticles {
      .search-box-swing {
        margin-top: 12px;
      }
    }

    &.noScrollButton {
      .static-content {
        .tabs {
          .viewport {
            margin-left: -1px !important;
          }
        }
        .tabContent {
          .innerTabContent {
            padding-top: 45px;
          }
        }
      }
      .scroll-content {
        margin-right: 0;
        .articles-search-result {
          margin-right: 0;
          .app-articles-row {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }

    .static-content {
      flex: 0.3;
      .work-header {
        margin: -12px 0px 0px 0px;
      }

      .tabs {
        margin-top: 20px;
        .viewport {
          margin-left: 30px !important;
        }
        .tabSetScrollButton {
          &.right {
            margin-right: 0;
          }
          &.left {
            margin-left: 0;
          }
        }
      }
      .tabContent {
        margin: 0;
      }
    }
    .scroll-content {
      flex: 7;
      overflow-y: auto;
      overflow-x: hidden;
      li {
        margin: 0;
      }

      .default-loading-page {
        position: relative;
        width: 100%;
      }

      .search-box-swing {
        .work_item_title {
          margin-top: 12px;
        }
      }

      .no-results {
        .alert-danger {
          margin: 12px 12px 12px 12px;
        }
      }

      &.articleLoading {
        overflow-y: hidden;
      }

      .articles-search-result {
        .app-articles-row {
          margin-left: 0;
          margin-right: 0;
          &:first-child {
            border-top: none;
            margin-top: 1em;
          }
        }
      }
    }
  }

  .searchResultSupplier {
    float: right;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;

    .fa-truck {
      margin-right: 5px;
    }

    .searchResultSupplierSelect {
      height: 34px;
      min-width: 95px;
      margin-right: -5px;
      border: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      text-align: left;
      outline: none;
      font-weight: 400 !important;
      color: #3c3c3b;
      background: transparent;
    }
    &:hover {
      background-color: darken($background-color,5);
    }
    &::after {
      content: "\f0d7";
      font-family: "FontAwesome", sans-serif;
      color: #3c3c3b;
      position: relative;
      background: transparent;
      z-index: 1;
      text-align: center;
      height: 100%;
      width: 2rem;
      pointer-events: none;
      box-sizing: border-box;
      right: 0em;
      font-size: 16px;
    }
  }

  .articleSize {
    padding-right: 10px;
    padding-left: 10px;
    float: right;
    .fa-list {
      margin-right: 5px;
    }
    &:hover {
      cursor: pointer;
      background-color: darken($background-color,5);
    }
    &::after {
      content: "\f0d7";
      font-family: "FontAwesome", sans-serif;
      color: $font-color;
      position: relative;
      background: transparent;
      z-index: 1;
      text-align: center;
      height: 100%;
      width: 2rem;
      pointer-events: none;
      box-sizing: border-box;
      right: 0em;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1700px) {
    .searchResultSupplier {
      display: none;
    }
  }

  @media screen and (max-width: 1450px) {
    .articleSize {
      display: none;
    }
  }

  .ovh {
    overflow: scroll;
  }

  .filterTab {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .toggle {
    margin-left: 13px;
    margin-top: 10px;
    display: inline-block;
  }

  .work_item_title{
    margin: 0px 0px 30px 0px;
    h1{

      strong{
        font-weight: 600;
      }
    }
  }
  .striped{
    padding: 5px;
    &:nth-of-type(odd){
      background: $ci-color-even;
    }
  }

  &.small {
    .technical-pos-table {
      tr td {
        font-size: 13px;
      }
    }

    .left-sidebar h3 {
      font-size: 13px;
    }

    .left-tree {
      li {
        padding-top: 2px;
        padding-bottom: 2px;

        &.children {
          padding-top: 2px;
          padding-bottom: 2px;
        }

        span {
          font-size: 13px !important;
        }
      }
    }

    table {

      th {
        font-size: 13px;
      }

      tr td {
        font-size: 13px;
      }
    }
  }

  .clickable {
    cursor: pointer;
  }

  .center-content > .clean {
    background-color: white;
    h4 {
      color: $ci-color-1;
      background-color: $ci-color-10;
      display: block;
      line-height: 2.4em;
      margin-top: -5px;
      margin-bottom: 5px;
      padding: 10px 15px 5px 15px;
    }
    h3 {
      padding-left: 50px;
      font-weight: 600;
      font-size: 1.5em;
      color: $ci-color-5;
    }
  }

  .left-sidebar {
    h4 {
      color: $ci-color-1;
      background-color: $ci-color-10;
      display: block;
      line-height: 2.4em;
      margin-top: -5px;
      margin-bottom: 5px;
      padding: 10px 15px 5px 15px;
    }

    h3 {
      margin-top: 15px;
      padding-left: 10px;
      font-weight: 500;
      font-size: 16px;
      color: $ci-color-1;
    }

  }
  .sidebar-opener-wrapper {
    .default-loading-page {
      position: relative !important;
      width: 25vw;
    }
  }
}
