.admin {
  height: 100%;

  .clubselection {
    margin-right: 1rem;
  }

  .collapsible-panel {
    margin-right: 10px !important;

    .right-panel-setting {

      .checkbox {
        display: inline-block;
        margin-right: 20px;
      }

      .custom-dropdown {
        display: inline-block;
        width: 210px;
        margin-bottom: -10px;
        &:after {
          margin-left: -20px;
          margin-top: 10px;
          top: auto;
          right: auto;
        }
      }
    }
  }
  .disabled-list-element {
     cursor: default;
     pointer-events: none;
     color: #bababa;
   }


  .usermanagement {

    .deactivated-user {
      height: 50px !important;
      opacity: 0.6 !important;
      filter: grayscale(100%) !important;
    }
  }
}
