.mail {
  position: absolute;
  width: 50vw;
  margin-left: -12px;
  padding: 15px 25px;
  background: #fafafa;
  z-index: 100;
  margin-top: -200px;
  box-shadow: 0px 5px 20px 5px rgba(0,0,0,0.25);
  transition: all 0.8s ease-in-out;
  &.show {
    margin-top: -20px;
  }
  .base-btn {
    float: right;
    &.success {
      margin-right: 10px;
    }
  }
}