@keyframes fadeIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.ovh {
  overflow: hidden;
}

.item-list {
  .fa-caret-right {
    margin-left: 10px;
    &:hover {
      color: $font-color !important;
    }
  }
}

.search-box-swing {
  width: 100%;
  height: calc(100% - 50px);
  transform: perspective(1000px);
  transition: all 0.6s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  display: block;
  &.move {
    pointer-events: none!important;
    cursor: default!important;
    height: 1px!important;
    transform: perspective(1000px) translate3d(0, 50%, 0) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
    overflow: hidden;
    z-index: -1;
  }
}

#search-open,
#search-close {
  color: $font-color;
  font-size: 1rem;
  cursor: pointer;
  float: right;
  font-weight: 300;
  padding: 0.3rem 0 0.3rem 0.5rem;
  line-height: 2rem;
  position: absolute;
  right: 18px;
  .caption {
    color: $font-color;
    font-size: 1rem;
    position: relative;
    top: -0.2rem;
    line-height: 1.5rem;
  }
}

#search-container {
  .searchResult {
    margin-left: 10px;
    z-index: 998;
    h1 {
      padding: 0.5rem 0;

      strong {
        font-weight: 600;
      }
    }

    .alert-info {
      margin-right: 10px;
    }
  }
  width: 100%;
  margin: 25px auto 0 auto;
  overflow-x: hidden;
  transition: 0.1s all;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

}
.search-bar {
  margin-top: .25rem;
}
.main-search-form {
  transition: 0.1s all cubic-bezier(0.2, 1, 0.3, 1);
  width: 0px;
  overflow: hidden;
  float: left;
  opacity: 0;

  &.show-search {
    opacity: 0;
    min-width: 200px;
    display: block;
    animation: fadeIn 0.5s;
    animation-fill-mode: forwards;
  }

}

.search-input {
  font-size: 1rem;
  width: 100%;
  padding: 0.5em 0 !important;
  background-color: transparent !important;
  border: 0;
  border-bottom: 1px solid darken($background-color, 10);
  outline: 0;
  &:focus,
  &:active {
    box-shadow: none;
    border: none;
    outline: none;
    border-bottom: 1px solid darken($background-color, 10);
  }
}
