.ui-dialog.ui-widget .ui-dialog-content,
.car-warning-modal{
    padding: 50px 5px 5px 5px !important;
    height: 100%;
    overflow: hidden;
    border-radius: 0px;

    .modal-header {
        margin: -5px;
        color: $color-white;
        font-size: 20px;
        height: 44px;
        line-height: 44px;
        vertical-align: middle;
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        background-color: $header-background-color;

    }

    .close-modal {
        position: absolute;
        right: 15px;
        top: 0;
        font-size: 20px;
        color: $font-color;
        @include transition(all 0.2s ease-in-out);

        &:hover {
            cursor: pointer;
            color: $color-danger;
        }
    }

    .modal-content{
    }
    h5 {
        color: $ci-color-1;
        float: left;
        font-weight: 800;
        font-family: $font-navigation !important;
    }
    .classification {
        font-weight: 600;
        color: $color-warning;
        &.RECALLCAMPAIGN {
            color: $color-danger;
        }
    }
    .valid-date {
        color: $color-warning;
        float: right;
        &.RECALLCAMPAIGN {
            color: $color-danger;
        }
    }
    .info-text {
        clear: both;
        font-style: italic;
    }
}

.car-warning-modal {

    .modal-header,
    .modal-footer {
        background: $highlight-color;
        .close {
            color: $ci-color-10;
        }
        .modal-title {
            font-size: 1.3rem;
            color: $ci-color-10;
            font-family: $font-navigation !important;
        }
    }
    .modal-header {
        padding: 10px 10px 10px 15px;
    }

}