$icon-color: $ci-color-5;
$list-font-color: $ci-color-7;
$list-hover-color: $ci-color-7;
$list-hover-weight: 600;


.tree-container {
    padding-top: 15px;
    .node-wrapper{
        padding: 2px 0px 2px 15px !important;
        background: transparent;
    }
    .tree-children{
        padding-left: 0px !important;
        span{
            margin-left: 10px;
        }
    }
    .tree-node-level-3{
        span{
            margin-left: 15px;
        }
    }
    .tree-node-level-4{
        span{
            margin-left: 20px;
        }
    }

}
.tree{

    .toggle-children-wrapper-expanded{
        .toggle-children{
        }
    }
    .toggle-children-wrapper-collapsed{
        .toggle-children{
        }
    }

    .node-content-wrapper-active,
    .node-content-wrapper.node-content-wrapper-active:hover,
    .node-content-wrapper-active.node-content-wrapper-focused
    {
        background: transparent;
        box-shadow: none;
        font-weight: 900;
        color: $ci-color-7;
    }
  .node-content-wrapper{
    width: 100%;
    padding: 0px 0px 0px 5px;
      //font-size: 16px;
      font-weight: normal;
      word-wrap: break-word;
    line-height: 20px;
    color: $font-color;
      font-family: $font-navigation;
    background: transparent;
    &:hover{
      background: transparent;
      color: $ci-color-14;
      border:none;
      box-shadow:none;
    }
  }
    .tree-children{
        //padding-left: 15px !important;
        span{
            //font-size: 14px !important;
        }
    }
}


.left-tree {
    tree-node-expander{
        height: 20px;
    }
    padding:15px 0px 0px 15px;
    ul{
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    li {
        margin-right: 0px;
        cursor: pointer;
        span{
            padding-left: 3px;
            cursor: pointer;
            li{
                span{
                    padding-left: 55px;
                    cursor: pointer;
                }
            }
        }
        &:hover{
            span{
                color: $list-hover-color;
            }
            color: $list-hover-color;
            font-weight: $list-hover-weight;
        }
        &.selected,
        &.active{
            span{
                color: $list-hover-color;
            }
            color: $list-hover-color;
            //background-color: $ci-color-3;
            font-weight: $list-hover-weight;
        }

        &.children,
        &.file{
            margin-left:10px;
            b{
                display: block;
                margin-left: 20px;
            }
        }

        &.file{
            padding:5px 10px 5px 10px;
        }
        display: none;
        i{
            padding-left: 0px;
            color: $icon-color;
            width: 15px;
            text-align: center;
            &.fa-file-text-o{
                margin-left: 18px;
            }
        }
        span{
            padding-left: 0px;
            cursor: pointer;
            li{
                span{
                    padding-left: 55px;
                    cursor: pointer;
                }
            }
        }
        &.show{
            display: block;

        }
    }
    .folder-area {
        float: left;
    }
    .name-area {
        display: block;
        width: 100%;
    }

    .edit-firm{
        padding-right: 10px;
        color: $ci-color-4;
        cursor: pointer;
    }

    .icon-placeholder {
        display: inline-block;
        width: 15px;
    }

    .children {
        list-style-type: none;
        margin: 0;
        padding: 0;
        i{
            //margin-right:5px;
        }
        li {
            padding-left: 40px;

            &:hover {
                cursor: pointer;
            }
        }
    }
}
.work-list{
    list-style-type: none;
    margin: 5px 0px;
    padding: 0;
    color: $ci-color-1;
    li {
        margin:0px 5px;
        padding: 10px 10px 10px 10px;
        line-height: 20px;
        cursor: pointer;
        display: block;
        &.show{
            display: block;
            &:nth-child(odd) {
                background-color: $ci-color-10;

            }
        }
        &:hover{
            background: $cl-hover-background !important;
        }
        i.fa{
            padding-left: 0px;
            color: $icon-color;
            width: 15px;
            text-align: center;
            &.fa-shopping-cart{
                font-size: 1.2em;
                margin-right: 10px;
            }
        }
        &.worklist{
            padding-left: 30px;
            color: $ci-color-1;
        }
        &.work{
            padding-left: 30px;
            color: $ci-color-1;
        }
        &.parts{
            padding-left: 67px;
            padding-top: 5px;
            padding-bottom: 5px;
            color: $ci-color-1;
            background: $ci-color-3 !important;
            cursor: auto;
        }
        &.part{
            padding-left: 67px;
            color: $ci-color-1;
            background-color: $ci-color-10;
        }
    }
}
.additional{
    li{
        &.work{
            padding-left: 48px !important;
        }
    }
}
.center-tree {
    ul{
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    li {
        padding:5px 10px;
        margin-right: 0px;
        cursor: pointer;
        &.selected {
            background-color: $cl-active-background !important;
        }
        &:hover{
            background: $cl-hover-background !important;
        }
        &.children{
            padding-left: 50px;
        }
        display: none;
        span{
            cursor: pointer;
            color: $ci-color-1;
        }
        i.fa{
            color: $icon-color;
        }
        &.show{
            display: block;
            &:nth-child(odd) {
                background-color: $ci-color-10;

            }
        }
    }
    .folder-area {
        float: left;
    }

    .name-area {
        display: block;
        width: 100%;
    }

    .edit-firm{
        padding-right: 10px;
        color: $ci-color-4;
        cursor: pointer;
    }

    .icon-placeholder {
        display: inline-block;
        width: 12px;
    }

    .children {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            padding-left: 40px;

            &:hover {
                cursor: pointer;
            }
        }
    }
}
.tree-sidebar{
    //background: darken($background-color,5) !important;
    right:0px !important;
    bottom: 0px !important;
}
/*
    FireFox Fix:
*/
.toggle-children-wrapper{
    padding: 0px !important;
}
