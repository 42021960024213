.content {
	height: 100%;
	display: table;
	table-layout: fixed;
	width: 100%;

	&.browserIsIE {
		.left-panel {
			height: calc(100vh - 100px) !important;
		}
		.center-panel {
			height: calc(100vh - 100px) !important;
		}
		.right-panel {
			height: calc(100vh - 100px) !important;
		}
	}

	&:after {
		@include clearfix();
	}

}

.split-panel {
	padding: 0 10px 0 0;
	height: 100%;

	&.right {
		padding: 0 0 0 10px;
	}

	.top-section, .bottom-section {
		position: relative;
		height: 50%;
		//background-color: $ci-color-3;

		.heading {
			left: 0;
			right: 0;
		}
	}

}

.no-float {
	float: none !important;
	display: table-cell;
	position: relative;
	height: 100%;
	min-height: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.no-float {
		display: block !important;
		height: 100% !important;
		min-height: 100% !important;
		float: left !important;
	}
}

.panel-content {
	position: absolute;
	top: 34px;
	bottom: 0px;
	left: 0;
	right: 0;
	overflow: auto;
	overflow-x: hidden;

    &.no-scroll {
        overflow: hidden;
    }

	&.white-background {
		background-color: $background-color !important;
	}

	&.left-sidebar {
		overflow: inherit;
		height: 100%;
	}

	&.right-sidebar {
	}

	&.center-content {
		padding: 1rem;
		height: calc(100vh - 100px - 2rem - 4px);
	}

	.inner-content {
		padding: 0px;
		height: 100%;

		&.smaller-height {
			height: calc(100% - 50px) !important;
		}
	}
}
.col-12{
	.panel-content{
		top: 0px;
		&.center-content {
			padding: 0rem !important;
		}
	}
}


.form-group {
	clear: both;
	padding-top: 10px;
}



