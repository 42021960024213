.drag-list {
  width: 100%;
  border-bottom: solid 1px #ccc;
  border-top: solid 1px #ccc;
  display: block;
  background: white;
  overflow: hidden;
  margin-bottom: 15px;
}

.drag-box {
  padding: 10px 5px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  .row {
    width: 100%;
    line-height: 44px;
    margin-left: 0;
    margin-right: 0;
  }

  &.clickable {
    cursor: pointer !important;
  }

  .checkbox {
    display: inline-block !important;
    float: right !important;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
  .partner-logo {
    height: auto;
    width: auto;
    max-width: 50px;
    max-height: 50px;
    background: $background-color;
  }
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-box:last-child {
  border: none;
}

.cdk-drag-disabled {
  cursor: default !important;
}

.drag-list.cdk-drop-list-dragging .drag-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
