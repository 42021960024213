app-cart-list {

  .pagination {
    margin: 5px -5px 0px -5px !important;
  }

  .custom-tree {
    margin-top: 10px;
  }

  .saved-carts {
    .panel-content {
      //  padding-left: 0px !important;
      //  ul {
      //    display: table;
      //    width: 100%;
      //    li {
      //      display: inline-table;
      //      width: 100%;
      //      margin: 0 !important;
      //      &:first-of-type{
      //        border-top: none;
      //      }
      //      span {
      //        line-height: normal;
      //        text-align: left;
      //        padding: 0.5rem 0 !important;
      //        height: unset !important;
      //        cursor: pointer;
      //        display: table-cell;
      //        &:first-of-type{
      //          text-align: center;
      //        }
      //        &:last-child {
      //          text-align: right;
      //          padding-right: 0.5rem !important;
      //        }
      //        i {
      //          &:hover {
      //            cursor: pointer;
      //          }
      //        }
      //
      //      }
      //    }
      //  }
    }

    .noResult {
      margin-left: 15px;
      margin-right: 15px;
    }

    .cartName {
      display: block;
    }

    .cartSearch {
      .material-form-input {
        margin-left: 15px;
        margin-right: 15px;
        margin-top: -20px;
        margin-bottom: 15px;
      }

      .fa-search, .fa-times {
        float: right;
        margin-right: 15px;
        margin-top: -40px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .add-cart-container {
      position: absolute;
      right: 15px;
      top: 15px;

      .add-cart-btn {
        cursor: pointer;
        border: none;
        color: $font-color;
        //font-size: 1.5rem;
        background: transparent;

        &:hover {
          color: $highlight-color;
        }
      }
    }

    .cart-name-input {
      position: absolute;
      top: 0px;
      left: 15px;
      right: 45px;

      input {
        width: 100%;
        //background-color: $color-white;
        border: none;
        background: transparent;
        padding: 7px 15px;
        @include border-radius(5px);

        &:focus, &:active {
          outline: none;
        }
      }

      i.fa-cloud-upload {
        display: block;
        position: absolute;
        right: 11px;
        top: 6px;
        font-size: 20px;
        color: $ci-color-1;

        &.disabled {
          color: $ci-color-4;

          &:hover {
            cursor: default;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

app-checkout, .checkout {
  .card-title {
    background: darken($background-color, 5);
  }

  .cartSum {
    font-size: 1.2em;
    height: 40px;
    box-shadow: inset 0px 2px 0px 0px rgba(0, 0, 0, 0.25);

    td {
      font-weight: 600;
    }
  }

  .custom-dropdown {
    &.right {
      max-width: 250px;
      float: right;
    }
  }

  .order-btn {
    margin: 2rem auto !important;
  }

  .right-panel {
    input {
      background: transparent;
    }

    .simple-table {
      tr {
        background: transparent !important;
      }

    }
  }
}

.centerToolTip {
  &::after {
    top: -12px;
    left: 50%;
    border-style: solid;
    border-color: transparent transparent $font-color transparent;
  }
}

app-success,
app-retry {
  display: block;
  background: white;
  opacity: 1;

  .retry-overlay {
    opacity: 1;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    left: 0;
    top: 100px;
    position: fixed;
    background: transparentize($background-color, .1);
  }

  .center {
    position: absolute;
    top: 15%;
    left: 0;
    right: 0;
    margin: auto;
  }

  .conf-modal {
    width: 700px;
    max-width: 80vw;
    position: fixed;
    height: 425px;
    z-index: 1000;
    opacity: 1;
    background-color: $background-color;
    border-radius: 3px;
    box-shadow: 0 12px 36px 16px rgba(0, 0, 0, 0.24);
  }

  .conf-modal h1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 10px;
    display: inline-block;
  }

  .title-text {
    display: inline-block;
    line-height: 37px;
    margin-left: 72px;
    margin-top: 22px;
    font-weight: 900;
  }

  .success {
    h1 {
      color: $color-success;
      font-weight: 900;
    }

    .title-icon {
      color: $color-success;
    }
  }

  .warning {
    h1 {
      color: $color-warning;
      font-weight: 900;
    }

    .title-icon {
      color: $color-warning;
    }
  }

  .title-icon {
    width: 27px;
    height: 27px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 30px;
    margin-top: 30px;
    position: absolute;
  }

  .conf-modal p {
    color: $font-color;
    padding: 15px 30px;
    font-size: 16px;
    line-height: 24px;
  }

  .modal-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .base-btn {
    float: right;
  }

}

.cart {
  .discount {
    .custom-dropdown {
      margin: 0.5rem !important;
      border: none!important;
      select {
        border-bottom: 1px solid #bcbcba !important;
      }
    }
    select, input {
      padding: 6px;
      height: 2.75rem !important;
    }
  }


  .raw-tool-image {
    max-height: 100px !important;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .alert {
    b {
      font-size: 1.2rem !important;
    }
  }

  .add-supplier {
    display: inline-block;
  }

  .not-orderable {
    margin-left: -15px;
    margin-right: -15px;
    padding: 1rem;
    font-size: 1.2rem;
  }

  .cart-price-spinner {
    text-align: center;
    padding: 20px;
    font-size: 2rem;
  }

  .heading {
    &.blue {
      position: absolute !important;
      z-index: 1;
    }
  }

  .tyre-info {
    margin-left: 15px;
    margin-top: 10px;

    .headerType {
      font-weight: bold;
    }
  }

  .optionList {
    position: absolute;
    right: 13px;
    margin-top: 30px;
  }

  .elements {
    z-index: 10;
    position: relative;
    list-style-type: none;
    padding: 0;

    box-shadow: $shadow-base-light;

    li {
      background: $background-color;
      color: $ci-color-1;
      height: 30px;
      padding: 0 15px;
      margin: 0;
      line-height: normal;
      border-bottom: 1px solid $ci-color-4;

      span {
        font-size: 1rem;
        float: left;
        margin-left: 5px;
        line-height: 29px;
        vertical-align: middle;
      }

      i {
        text-align: left;
        width: 20px;
        font-size: 20px;
        float: left;
        display: block;
        line-height: 29px;
        vertical-align: middle;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        cursor: pointer;
        background-color: darken($background-color, 5);
        color: $ci-color-1;
      }
    }
  }

  &.cursor {
    cursor: pointer;
  }

  .cart-comment {
    min-height: 45px;
    margin-left: 0;
    margin-right: 0;
    border-bottom: 1px solid #cccccc;

    .col-10 {
      cursor: default;
      padding-left: 0;
      padding-right: 0;

      span {
        line-height: 45px;
      }
    }

    .col-1 {
      text-align: center;

      i {
        font-size: 1.8rem;
        color: #696F71;
        position: relative;
        top: calc(50% - 12px);

        &:hover {
          cursor: pointer;
          color: darken(#696F71, 10);
        }
      }
    ;
    }
  }

  .addNewArticle {
    text-align: center;
    cursor: pointer;
    height: 45px;
    border-bottom: 1px solid #cccccc;
  }

  .textarea-row {
    .commenting-icon {
      cursor: pointer;
    }

    .fa-trash-o {
      font-size: 20px;
      color: #696F71;
      margin-left: 15px;
      cursor: pointer;
    }

    .textarea {
      width: 100%;
      min-height: 70px;
      cursor: default;
    }
  }

  .cartSum {
    font-size: 1.2em;
    height: 40px;
    box-shadow: inset 0px 2px 0px 0px rgba(0, 0, 0, 0.25);

    td {
      font-weight: 600;
    }
  }

  .calculation-info {
    .collapsible-panel {
      margin-right: 12px !important;
    }

    .button-row {
      margin-top: 15px;
      margin-bottom: 10px;
    }
  }

  .fixprice-positions {
    .collapsible-panel {
      margin-right: 12px !important;
    }

    .checkbox {
      float: right;
      margin-right: -15px;
    }
  }

  .carsInCart {
    margin-top: 30px;

    .carHeader {
      font-weight: bold;
      margin-left: 1em;
    }

    .row {
      margin-left: 1em;
      margin-right: 1em;
      margin-top: 5px;
      border-top: 1px solid $color-grey;
      padding-bottom: 5px;
      padding-top: 5px;

      .col-8 {
        margin-left: -15px;
        margin-right: 15px;
        padding-right: 0px;

        &:hover {
          cursor: pointer;
          font-weight: bold;
        }

        .carName {
          &.selected {
            font-weight: bold;
            color: $highlight-color;
          }
        }
      }

      .col-4 {
        .servicePlanButton {
          width: 10rem !important;
          height: 3rem !important;

          i {
            margin-top: -2px;
          }

          .fa-times {
            color: $color-danger;
          }

          &.disabled {
            cursor: not-allowed;
            color: lighten($font-color, 50)
          }
        }
      }
    }
  }

  .history {
    position: absolute;
    bottom: 0px;
    height: 60px;
    border-top: 1px solid $color-grey;
    width: 100%;
    margin-left: -10px;

    button {
      float: right;
      margin: 1rem;
    }
  }

  .panel-content {
    //overflow: hidden !important;
  }

  ngb-tabset {
    .nav-tabs {
      margin-left: -10px;
      margin-right: 0px !important;
      z-index: 1 !important;
    }
  }

  height: 100%;

  .saved-carts {
    height: 100%;
  }

  .saved-carts.card {
    margin: 0px !important;
    box-shadow: none;
    border: none;
    padding: 0px !important;
  }

  app-add-custom-article {
    //height: 100%;
    //width: 100%;
    //position: absolute;
  }

  .clickable {
    cursor: pointer;
  }

  .verbundarbeit {
    float: right;
    position: absolute;
    right: 40px !important;

    label {
      input {
        margin-top: 8px !important;
      }
    }
  }

  .wkChange {
    cursor: pointer;

    &.long {
      input {
        width: 175px;
      }
    }

    input {
      height: 27px;
      border: none;
      margin-bottom: -5px;
      background-color: darken($background-color, 2%);

      &:hover, &:focus {
        border-bottom: 2px solid $highlight-color;
      }
    }

    input::-ms-clear {
      display: none;
    }
  }

  .d-inline-block {
    position: absolute;
    right: 10px;

    .dropdown-toggle {
      background-color: #fafafa;
      height: 32px;
      line-height: 0px;

      &:after {
        display: none;
      }

      &:focus {
        box-shadow: none;

        i {
          color: $highlight-color;
        }
      }
    }

    .dropDownMenu {
      //left: -113px !important;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
      border: none;
      border-radius: 0px;

      .dropdown-item {
        //padding: 1rem 1.5rem;
        cursor: pointer;

        i {
          padding-right: 10px;
        }

        &:focus {
          box-shadow: none;
          outline: none;
        }

        &:active {
          background-color: $color-side-panel-background;
          color: $font-color;
        }

        &:hover {
          i {
            color: $highlight-color;
          }
        }
      }
    }

    .fa-ellipsis-v {
      font-size: 20px;
      color: $ci-color-12;
    }
  }

  .compositeWorkTime {
    float: right;

    .toggle-btn {
      height: 2rem;
      margin-top: 5px;
    }
  }
}

.right-panel {
  padding-left: 0px;
  padding-right: 0px;

  .top-section {
    position: inherit;
    height: 100%;

    .price-table {
      float: right;
      margin-right: 46px;
      width: 200px;
      font-size: 16px;
      margin-bottom: 7px;

      tbody {

        tr {
          &:last-child {
            font-weight: bold;
          }
        }

        td {
          padding: 0;

          &:first-child {
            text-align: left;
          }

          &:last-child {
            text-align: right;
          }
        }
      }
    }

  }

  .bottom-section {
    .btn-bluegray-mid {
      float: left;
      margin-top: 10px;
    }
  }

  .preview-text {
    margin: 3rem 1rem;

    .small {
      font-size: 12px;
    }
  }

  .order-btn {
    &.outline {
      background-color: transparent;
      border: 2px solid $color-success;
      color: $color-success;
    }

    &.preview {
      background: $highlight-color;
    }
  }
}

.export-modal {
  height: 60px;

  .export-modal-content {
    margin-top: 70px;
  }

  button {
    text-align: center;
  }
}

.add-custom-article-modal {
  overflow: hidden;
}

.cart-empty-message {
  text-align: center;
  font-size: 20px;
  margin: 20px;
}

.article-count {
  //font-size: 18px;
  margin: 2rem auto;
  //width: 280px;
}

@keyframes showCartOverlay {
  from {
    margin-top: 20px;
    opacity: 0.5
  }
  to {
    margin-top: 40px;
    opacity: 1
  }
}

.close-cart-overlay, .show-supplier-selection {

  &.fix-background {
    background: none !important;
  }

  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.75);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;

  .center-wrapper {
    background-color: $color-white;
    margin-left: auto;
    margin-right: auto;
    width: 50vw;
    margin-top: 40px;
    animation-name: showCartOverlay;
    animation-duration: 350ms;
    opacity: 1;
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.25);

    .header {
      height: 34px;
      border-bottom: 2px solid $highlight-color;

      .fa-shopping-cart, .fa-th {
        margin-left: 10px;
      }

      .title {
        margin-left: 10px;
        line-height: 34px;
        font-weight: bold;
      }

      .fa-times {
        margin-right: 10px;
        line-height: 34px;
        float: right;
        font-size: 1.5rem;

        &:hover {
          color: $color-danger;
          cursor: pointer;
        }
      }
    }

    .supplier-change-content {
      .loading-supplier {
        margin-top: 20px;
        margin-bottom: 20px;

        .fa-spinner {
          font-size: 3rem;
        }
      }

      .supplier-change-list {
        padding: 10px;
        overflow-y: auto;
        max-height: 75vh;

        .supplier-row {
          display: flex !important;
          margin-left: -10px;
          margin-right: -10px;
          padding: 12px 5px 8px;
          border-top: 1px solid $color-grey-light;

          .supplier-icon {
            img {
              max-width: 80px;
              max-height: 50px;
            }
          }

          .price {
            font-weight: bold;
            color: $highlight-color;
          }

          .availability {
            .a-availability {
              .info-text {
                line-height: 28px;
              }
            }
          }

          .checkbox {
            float: right;
            margin-right: -9px;
          }
        }
      }

      .button-row {
        border-top: 1px solid $color-grey-light;
        padding-top: 10px;
        padding-bottom: 10px;

        .change-supplier {
          margin-left: 10px;
        }

        .select-supplier {
          float: right;
          margin-top: 0px;
          margin-right: 10px;
        }

        .abort {
          position: absolute;
          right: 25vw;
          margin-top: 24px;
          margin-right: 10px;
        }

        .continue {
          margin-left: 10px;
        }
      }
    }
  }
}

.discount-validation-error {
  border-bottom: 1px solid $color-danger !important;
}

