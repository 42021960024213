ul.item-list{
  list-style: none;
  padding: 0px;
  li{

    padding: 0.5rem 1rem;
    margin: 0 -1rem;
    box-shadow: $shadow-base-extra-light;
    border-top: 1px solid darken($background-color,20);
    transition:
            all        0.2s;
    i{
      color: $font-color;
      margin-right: 0.5rem;
    }
    span{

    }
    &.active{
      //border-left: 0.2rem solid $highlight-color ;
      //border-right: 0.2rem solid $highlight-color ;
    }
    &.selected{
      background-color: darken($background-color,2);
      border-left: 0.2rem solid $highlight-color;
      border-right: 0.2rem solid $highlight-color;
    }
    &:hover{
      background-color: darken($background-color,5);
      cursor: pointer;
    }
    &:first-of-type{
      border-top: 1px solid darken($background-color,15);
    }
  }
}

.dropdown-list {
  position: absolute;
  top: 3rem;
  background-color: $dropdown-background;
  border-left: 1px solid $font-color;
  border-right: 1px solid $font-color;
  border-bottom: 1px solid $font-color;

  ul {
    list-style: none;
    padding-left: 1rem;
    padding-right: 1rem;
    width: fit-content;
    max-height: 20rem;
    height: fit-content;
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 0;
    li {
      cursor: pointer;
    }
  }
}
