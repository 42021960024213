// Base Colors
$color-white: #fff;
$color-black: #3c3c3b;
$color-grey-dark: #666;
$color-grey: #ccc;
$color-grey-light: #e5e5e5;
$background-color: #fff;
$right-tab-background-color: $color-white;
$color-side-panel-background: #f8f8f8;
$color-breadcrump: #fafafa;
$color-tab-border-inactive: #e8e8e8;
$color-tab-background-inactive: #f0f0f0;
$color-border: rgba(0,0,0,0.125);
$dropdown-background: #f5f5f5;


$ci-color-1: #383e42;
$ci-color-2: #31393C;
$ci-color-3: #dfe6ed;
$ci-color-4: #b1c1ce;
$ci-color-5: $ci-color-1;
$ci-color-6: $ci-color-1;
$ci-color-7: $color-black;
$ci-color-8: #808080;
$ci-color-9: #e6e6e6;
$ci-color-10: #ecf0f4;
$ci-color-11: #8E9394;
$ci-color-12: #696F71;
$ci-color-13: #b2b2b2;
$ci-color-14: #31393C;
$ci-color-15: $ci-color-1;
$ci-color-16: #d4d4d4;
$ci-color-17: #DBDFAC;
$ci-color-18: #6D8198;

$highlight-color: #5CB8B2;
$highlight-color-contrast: $color-white;


// Topic colors
$color-danger: #D80032;
$color-success: #39ab6d;
$color-warning: #dd8211;
$color-neutral: #3C3C3B;
$color-info: #0e75a2;

// Topic contrast colors
$color-danger-contrast: $color-white;
$color-success-contrast: $color-white;
$color-warning-contrast: $color-white;
$color-neutral-contrast: $color-white;

// ODD EVEN colors
$ci-color-odd: $background-color;
$ci-color-even: $background-color;

// center List (Tree in center) used in Class .center-tree
$cl-hover-background: $highlight-color;
$cl-active-background: $highlight-color;



$header-background-color: $color-black;
$header-topbar-background-color: $color-white;

// Price Settings

$price-color: $highlight-color;
