.car-history-dropdown {
  .nx-dropdown-button{
    width: 24vw !important;
    &.show-external-tools {
      width: calc(24vw - 30px) !important;
      &.hasSelectedCar {
        width: 381px !important;
      }
    }
    &.hasSelectedCar {
      width: 381px !important;
    }
    .selected{
      overflow:hidden;
      white-space:nowrap;
      text-overflow: ellipsis;
      display: block;
      padding-right: 15px;

    }
  }
}
app-car-history {

  .clickable {
    cursor: pointer;
  }

  display: block;
  margin-top: 5px;

  .external-tool-single {
    position: absolute;
    left: 450px;
    color: $color-white;
    top: 15px;
    cursor: pointer;

    &.no-car-selected {
      left: calc(24vw - 5px) !important;
    }
  }

  .external-tools-menu {
    background-color: white;
    z-index: 2000;
    position: absolute;
    padding: 10px 15px;
    left: calc(24vw - 10px);
    box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.25);
    min-width: 200px;

    &.selected-car {
      left: 440px !important;
    }

    .row {
      padding: 5px 0px;
      &:first-of-type {
        margin-top: 10px;
      }
    }

    .title {
      color: $highlight-color;
      font-weight: bold;
      padding-bottom: 10px;
    }

    .fa {
      font-size: 1.5rem;
      color: $highlight-color;
    }

    .tool-name {
    }
  }


  //float: right;
  .car-history-area{
    position: relative;
    display: inline-block;
  }
  .car-history-dropdown {
    ng-busy {
      .container-box {
        background: none !important;
        width: 40% !important;
        left: 217px !important;
        top: -278px !important;
      }
    }

    .fa-spinner {
      color: $color-white;
    }

    .eject_icon {
      left: 403px;
      display: inline-block;
      position: absolute;
      top: 15px;
    }

    .info_icon {
      left: 426px;
      position: absolute;
      display: inline-block;
      top: 15px;
    }

    margin-right:10px;
    padding-right: 10px;
    .eject{
      position: relative;
      top: 0px;
    }
    .fa-caret-down,
    .fa-th-list {
      position: absolute;
      right: 0;
      top: 0;
      height: $form-element-height;
      line-height: 30px;
      color: $background-color;
    }
    .fa-info-circle{
      font-size: 1.2rem;
      color: $color-white;
      cursor: pointer;

    }
    .external-tools_icon {
      position: absolute;
      left: 450px;
      color: $color-white;
      top: 15px;
      cursor: pointer;
      &.no-car-selected {
        left: calc(24vw - 5px) !important;
      }
    }
    .fa-plus {
      color: white;
    }

    .nx-dropdown-button {

      &:disabled {
        opacity: 0.5 !important;
        cursor: not-allowed !important;
        border-radius: 0px !important;
      }

      position: relative;
      border: 0px solid transparent;
      border-bottom: 1px solid $background-color;
      border-radius: 0px;
      background-color: transparent;
      //height: $form-element-height;
      font-size: $form-element-fontsize;
      width: 300px;
      height: 30px;
      padding: 0 30px 0 0;
      text-align: left;
      overflow: hidden;

      &.red-border {
        //border-bottom-color: $color-danger;
      }

      &:focus{
        outline: none;
        border:0px;
        border-bottom: 1px solid $background-color;
      }

      &:hover {
        cursor: pointer;
      }



      span.selected {
        color: $background-color;
        font-weight: 600;
        font-size: 0.95rem;
      }

      span.placeholder-text {
        color: $ci-color-7;
        padding-right: 30px;
      }

      &:active, &:focus {
        outline: none;
        border-bottom: 1px solid $background-color;

        @include border-bottom-left-radius(0);
        @include border-bottom-right-radius(0);
      }

      &.with-label {
        display: inline-block;
        width: unset;
      }
    }

    .nx-dropdown-menu {
      position: absolute;

      height: 50vh;
      top: 38px;
      z-index: 101;
      width: 0;
      left: -810px;
      transition: all 450ms ease-in-out;

      &.can-deselect {
        height: 54vh;
      }

      &.history{
        width: 24.6%;
        left: 0;
        z-index: 2000;
      }
      &.infos{
        width: 800px;
        left: 0;
        z-index: 101;
        &.small-info{
          width: 400px;
        }
      }


      background-color: $background-color;
      box-shadow: $shadow-base-car;

      >.row{
        margin: 0px !important;
        .col{
          overflow: auto;
        }
        .car-list {
          padding: 0px;
          height: 50vh;
        }
        .car-selection {
          padding-top: 4px;
        }
      }
      .deselect{
        color: $highlight-color-contrast;
        text-align: center;
        background-color: $highlight-color;
        height: 45px;
        cursor: pointer;
        padding: 1rem;
        &:hover{
          font-weight: 400;
        }
      }
      ul {
        height: 100%;
        border: 0px solid #ccc;
        border-right: 1px solid darken($background-color,20);
        border-top: none;
        z-index: 9999;
        margin: 0;
        list-style-type: none;
        font-size: 0.95rem;
        padding: 0px;
        color: $font-color;
        li {
          box-shadow: $shadow-base-extra-light;
          border-top: 1px solid darken($background-color,20);
          padding: 0.5rem 1rem;
          transition: all 0.2s ease-in-out;
          font-weight: 400;
          &:last-of-type{
            border-bottom: 0px solid darken($background-color,15);
          }
          &:hover {
            color: $font-color-dark;
            cursor: pointer;
            background-color: darken($background-color,5);
          }

        }
      }
    }
  }

  .warning-area {
    min-width: 30px;
    width: 30px;

    i {
      vertical-align: middle;
      font-size: 24px;
      cursor: pointer;

      line-height: 35px;

      &.current-info {
        color: $color-danger;
      }

      &.expired-info {
        color: $color-neutral;
      }
    }
  }

  .warning-area, .options-area {
    padding-top: 2px;
  }

  .car-history-area {
    float: left;
    vertical-align: middle;
    position: relative;
  }

  .round-btn {
    display: inline-block;
    margin-left: 8px;

    &:hover {
      cursor: pointer;
    }
  }

  .tooltip-style {
    left: 0;
  }
}
