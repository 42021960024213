.firm-calculation {
  .calc-head {
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 300;
    margin-left: -12px !important;
    margin-right: -12px !important;
  }

  .calc-highlighted {
    border-left: 1.5rem solid $highlight-color !important;
    border-right: 1.5rem solid $highlight-color !important;
  }

  .icon-btn {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}