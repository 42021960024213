.club-notifications {

  .create-notification {
    margin-bottom: 2rem;
    .notification-label {
      line-height: 45px;
    }
    .dropdown-container {
      margin-top: 8px;
    }
    .checkbox {
      margin-top: 15px;
    }
    .base-btn {
      margin-top: 1rem;
    }

    .form-inline {
      width: 100%;
      .form-group {
        width: 100%;
        .input-group {
          width: 100%;
          outline: none;
          &:focus {
            border-bottom: 2px solid $highlight-color;
          }
          .form-control {
            text-align: center;
            height: 35px;
            font-size: 2rem;
            box-sizing: border-box;
            width: 100%;
            padding: 12px 10px 8px;
            border: none;
            border-radius: 0;
            box-shadow: none;
            border-bottom: 1px solid lighten($font-color,50);
            font-weight: 400 !important;
            color: #3c3c3b !important;
            outline: none;
            cursor: text;
            background-color: $color-white;
            padding-bottom: 0.6rem !important;
          }

          .btn-outline-secondary {
            outline: none;
            border-top: none;
            border-right: none;
            border-left: none;
            border-bottom: 1px solid lighten($font-color,50);
            font-size: 1.2rem;
            border-radius: 0;
            &:hover {
              background-color: $color-white;
              color: $highlight-color;
            }
          }
        }
      }
    }
  }

  .notification-row {
    border-top: 1px solid #d6dbdc;
    padding-top: 10px;
    padding-bottom: 10px;
    &.WARNING, &.WARN {
      border-left: 10px solid $color-warning;
    }
    &.INFO {
      border-left: 10px solid $color-info;
    }
    &.ERROR {
      border-left: 10px solid $color-danger;
    }
    .notification-message {
      padding-left: 10px;
    }
    .notification-option {
      font-size: 1.6rem;
    }
    &:last-of-type {
      border-bottom: 1px solid #d6dbdc;
    }
  }
}
