$custom-tree-color: $font-color;
$custom-tree-font-size: 1rem;

ul.custom-tree {
  list-style: none;
  padding: 0rem;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 3rem);
  button {
    color: $custom-tree-color;
    span {
      font-size: 1rem;
    }
  }
  /*
    main cat overwrite
  */
  li {
    font-size: 1.05rem;
    line-height: 1.2;
    position: relative;
    &.open, .open {
      font-weight: bold;
      color: $highlight-color !important;
      i {
        color: $highlight-color !important;
      }
      span {
        color: $highlight-color !important;
      }
    }
    span{
      margin-left: -10px;
      display: inline-block;
      padding-left: 25px;
    }
    i {
      font-size: 0.5rem;
      position: absolute;
      left: 13px;
      top: 5px;
      &.fa-spinner {
        font-size: 1em !important;
        position: inherit !important;
        margin-left: 5px !important;
      }
    }
    i, span {
      cursor: pointer;
      color: $custom-tree-color;
      &:hover {
        color: darken($custom-tree-color, 50);
      }
    }
    &.list{
      padding: 0 0 0.7em 0.7em;
    }
    &.active{
      i, span {
        color: $font-color-dark;
        opacity: 1;
      }
    }
    &.disabled {
      cursor: not-allowed;
      i, span {
        opacity: 0.5;
      }
    }
  }
  /*
    only for children
  */
  ul {
    list-style: none;
    padding: 0 1rem;
    li {
      span {
        margin-left: -10px;
        display: inline-block;
        padding-left: 28px;
        &.file-label{
          padding-left: 15px;
        }
        &:after {
          position: absolute;
          top: 0.6em;
          left: -0.5em;
          display: block;
          height: 0em;
          width: 1em;
          border-bottom: 1px solid darken($background-color, 15);
          content: '';
        }
      }
      i {

        font-size: 0.5rem;
        position: absolute;
        left: 13px;
        top: 5px;
        &:after {
          border-top: 1px solid #777;
          border-right: 1px solid #777;
          border-bottom: 0;
          border-left: 0;
          bottom: 0;
          top: 0.5em;
          height: auto;
        }
      }
      i, span {
        cursor: pointer;
        color: $custom-tree-color;
        &:hover {
          color: $font-color-dark;
        }
      }
      .file {
        position: absolute;
        left: 13px;
        top: 4px;
        color: $highlight-color;
      }
      .active {
        width: 100%;
        span {
          color: $font-color-dark !important;
          font-weight:600;
          &:hover {
            color: $font-color-dark; //
          }
        }
        .file {
          //font-size: 0.8rem;
        }
      }
      // Vertikale linie
      &:before {
        position: absolute;
        top: -5.6px;
        bottom: 0px;
        left: -0.5em;
        display: block;
        width: 0;
        border-left: 1px solid darken($background-color, 15);
        content: "";
      }
      &:first-child {
        margin-top: 0.5rem;
        &:before {
        }
      }
      &:last-child {
        &:before {
          height: 1em;
          bottom: auto;
        }
      }


      font-size: $custom-tree-font-size !important;
    }
  }
}
