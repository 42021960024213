// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .card{
    margin: 1rem 0;
    .availability-panel{
      .a-logo,
      .a-price{
        width: 50%;
      }
      .a-availability{
        width: 100%;
      }
    }
  }

}
