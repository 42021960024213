.global-notifications {
  .global-notifications-content {

    h1 {
      margin-top: 10px;
      margin-left: 5px;
    }

    .base-btn {
      margin-top: 1rem !important;
    }

    .create-new-notification {

      margin-left: 5px;
      margin-right: 5px;

      .notification-label {
        line-height: 45px;
      }

      .date-row {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .form-inline {
        width: 100%;

        .form-group {
          width: 100%;

          .input-group {
            width: 100%;
            outline: none;

            &:focus {
              border-bottom: 2px solid $highlight-color;
            }

            .form-control {
              text-align: center;
              height: 35px;
              font-size: 2rem;
              box-sizing: border-box;
              width: 100%;
              padding: 12px 10px 8px;
              border: none;
              border-radius: 0;
              box-shadow: none;
              border-bottom: 1px solid lighten($font-color, 50);
              font-weight: 400 !important;
              color: #3c3c3b !important;
              outline: none;
              cursor: text;
              background-color: $color-white;
              padding-bottom: 0.6rem !important;
            }

            .btn-outline-secondary {
              outline: none;
              border-top: none;
              border-right: none;
              border-left: none;
              border-bottom: 1px solid lighten($font-color, 50);
              font-size: 1.2rem;
              border-radius: 0;

              &:hover {
                background-color: $color-white;
                color: $highlight-color;
              }
            }
          }
        }
      }
      .dropdown-container {
        margin-top: 8px;
      }

      .checkbox {
        margin-top: 15px;
      }

      .club-restriction {
        margin: 10px -25px 0;

        .material-form-input {
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 10px;

          input {
            padding-left: 0;
          }
        }

        .checkbox {
          display: inline-block;
          margin-left: 15px;
        }

        .toggle-switch {
          display: inline-block;
          padding-top: 10px;
        }

        .fa-times {
          position: absolute;
          color: $color-danger;
          top: 20px;
          right: 25px;
          font-size: 1.5rem;
          cursor: pointer;
        }

        .resetButton {
          margin-left: 10px;
        }

        .arrowButton {
          i {
            font-size: 1.5rem;
            font-weight: bold;
            line-height: 30px;
          }
        }

        @media (max-width: 1560px) {
          .arrowButton {
            margin-left: auto;
            margin-right: auto;
            float: none !important;
            display: block;
            margin-bottom: 10px;
          }
        }

        .dataSupplier {
          border: 1px solid $color-grey;
          margin-left: 10px;

          .dataSupplierBox {
            .loading-clubs {
              margin-top: 10px;
              text-align: center;

              .fa-spinner {
                font-size: 2rem;
              }
            }

            overflow-y: scroll;
            height: 400px;

            .dataSupplierItem {
              padding: 0.5rem 1rem;
              border-top: 1px solid $color-grey;

              &:hover {
                background-color: #f2f2f2;
                cursor: pointer;
              }

              &:last-child {
                border-bottom: 1px solid $color-grey;
              }

              &.selected {
                background-color: #fafafa;
                border-left: 0.2rem solid #5CB8B2;
                border-right: 0.2rem solid #5CB8B2;
              }
            }
          }
        }

        .restriction {
          border: 1px solid $color-grey;
          margin-right: 10px;

          .fa-times {
            margin-right: 10px;
          }

          .restrictionBox {
            overflow-y: scroll;
            height: 400px;

            .restrictionItem {
              padding: 0.5rem 1rem;
              border-top: 1px solid $color-grey;

              &:hover {
                background-color: #f2f2f2;
                cursor: pointer;
              }

              &:last-child {
                border-bottom: 1px solid $color-grey;
              }

              &.selected {
                background-color: #fafafa;
                border-left: 0.2rem solid #5CB8B2;
                border-right: 0.2rem solid #5CB8B2;
              }
            }
          }
        }
      }
    }

    .active-global-notifications {
      .notification-row {
        border-top: 1px solid #d6dbdc;
        padding-top: 10px;
        padding-bottom: 10px;

        &.WARNING, &.WARN {
          border-left: 10px solid $color-warning;
        }

        &.INFO {
          border-left: 10px solid $color-info;
        }

        &.ERROR {
          border-left: 10px solid $color-danger;
        }

        .notification-message {
          padding-left: 10px;
        }

        .notification-clubs {
          .clubs::after {
            content: ", ";
          }

          .clubs:last-of-type::after {
            content: "";
          }
        }

        .notification-option {
          font-size: 1.6rem;
        }

        &:last-of-type {
          border-bottom: 1px solid #d6dbdc;
        }
      }
    }
  }
}
