.print-content{
  background: #ccc;
  &.setting{
    background: $background-color !important;
    .print-settings{
      box-shadow: none !important;
    }
  }
  .print-preview{
    margin: 2rem -2rem;
    position:relative;
    top: -275px;
    padding-top: 20px;
    min-height: calc(100vh - 2rem - 220px);
    .page{
      margin: 1rem auto;
    }
  }
  .print-settings{
    background: $background-color;
    position: relative;
    margin: -1rem;
    padding: 1rem;
    box-shadow: $shadow-base-div;
  }
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .print-content{
    .print-settings{
      margin: 1.5rem -1rem !important;
    }
  }
}
