.extended-order-details {
  margin-bottom: 20px;

  .col {
    padding-left: 28px;
  }

  .details-sum {
    font-size: 1.2em;
    height: 40px;
    box-shadow: inset 0px 2px 0px 0px rgba(0, 0, 0, 0.25) !important;
  }

  .order-details-header {
    color: $ci-color-14;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0 10px 0;
    padding: 0 15px;
  }
}

.order-details {
  .padding-top {
    padding-top: 20px;
  }

  .referenceHeader {
    font-weight: bold;
  }

  .collapsible-panel {
    margin-right: 1rem !important;
  }
}

.global-order-details {
  .font-color-green {
    color: $color-success;
  }
  .font-color-red {
    color: $color-danger;
  }
  .padding-top {
    padding-top: 20px;
  }

  .referenceHeader {
    font-weight: bold;
  }

  .collapsible-panel {
    margin-right: 1rem !important;

    button {
      margin-top: 15px;
      margin-bottom: 10px;
      width: 100%;
    }
  }
}
