.maintenance-wizard{
  .wizard-header{
    display: none;
  }
  .calendar {
    position: absolute;
    float: right;
    margin-top: 25px;
    &:hover {
      cursor: pointer;
      color: $highlight-color;
    }
  }

  .form-inline {
    width: 100%;
    .form-group {
      width: 100%;
      .input-group {
        width: 100%;
        outline: none;
        &:focus {
          border-bottom: 2px solid $highlight-color;
        }
        .form-control {
          text-align: center;
          height: 35px;
          font-size: 2rem;
          box-sizing: border-box;
          width: 100%;
          padding: 12px 10px 8px;
          border: none;
          border-radius: 0;
          box-shadow: none;
          border-bottom: 1px solid lighten($font-color,50);
          font-weight: 400 !important;
          color: #3c3c3b !important;
          outline: none;
          cursor: text;
          background-color: $color-white;
          padding-bottom: 0.6rem !important;
        }

        .btn-outline-secondary {
          outline: none;
          border-top: none;
          border-right: none;
          border-left: none;
          border-bottom: 1px solid lighten($font-color,50);
          font-size: 1.2rem;
          border-radius: 0;
          &:hover {
            background-color: $color-white;
            color: $highlight-color;
          }
        }
      }
    }
  }
}

