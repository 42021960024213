$btn-height: 2.5rem;




.round-btn {
    background-color: $highlight-color;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    text-align: center;
    color: $font-color;
    border: none;
    position: relative;
    @include transition(all 0.2s ease-in-out);

    i {
        position: absolute;
        top: 7px;
        left: 12px;
        font-size: 16px;
        display: block;
        text-align: center;

        &.fa-eject {
            top: 7px;
            left: 8px;
        }
    }

    &:hover {
        background-color: $ci-color-5;
    }
}



.btn-bluegray-light {
    @extend .base-btn;
    background-color: $ci-color-3;
    color: $font-color;
    border: 1px solid $ci-color-3;
    width: 70%;
    padding: 0 10px;
    text-align: left;
    height: 34px;
    margin-bottom: 15px;

    &:hover {
        background-color: $ci-color-6;
        color: $color-white;
    }

    span {
        display: inline-block;
        font-size: 14px;
        font-weight: bold !important;
    }

    span, i {
        height: 34px;
        font-weight: normal;
        line-height: 34px;
        vertical-align: middle;
    }

    &[disabled], &:disabled {
        background-color: $ci-color-12;
        color: $ci-color-16;
    }

}

.btn-bluegray-mid {
    @extend .btn-bluegray-light;
    background-color: $ci-color-4;
    width: 100%;

    &[disabled] {
        background-color: $ci-color-12;
        color: $ci-color-16;
    }
}

.btn-green {
    padding: 0 10px;
    text-align: left;
    height: 34px;
    margin-bottom: 15px;
    border: 1px solid $ci-color-3;
    background-color: $color-success;
    color: $color-white;

    &:hover {
        background-color: lighten($color-success, 10%);
        color: $color-white;
    }

    span {
        display: inline-block;
        font-size: 14px;
        font-weight: bold !important;
    }

    span, i {
        height: 34px;
        font-weight: normal;
        line-height: 34px;
        vertical-align: middle;
    }

    &[disabled], &:disabled {
        background-color: $ci-color-12;
        color: $ci-color-16;
    }
}

.delete-item-btn {
    font-size: 20px;
    color: $ci-color-12;
    margin-left: 15px;

    i {
        @include transition(all 0.2s ease-in-out);

        &:hover {
            color: $ci-color-7;
            cursor: pointer;
        }
    }
}

.option-btn {
    font-size: 20px;
    color: $ci-color-12;
    margin-left: 15px;

    i {
        @include transition(all 0.2s ease-in-out);

        &:hover {
            color: $font-color;
            cursor: pointer;
        }
    }
}
.header-button {
    float: right;
    margin-top: 7px;
    margin-left: 7px;
    background-color: $ci-color-4;
    color: $font-color;
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
    border: 1px solid $ci-color-4;
    border-radius: 5px;
    padding-right: 10px;

    &:hover {
        cursor: pointer;
        background-color: $ci-color-3;
        color: $font-color;
    }

    &.selected {
        background-color: $ci-color-10;
        color: $font-color;
    }

    &:disabled {
        background-color: $ci-color-12;
        color: $ci-color-16;
    }

    &:focus, &:active {
        outline: none;
    }

    i {
        padding: 0 10px;
        font-size: 21px;
        float: left;
        line-height: 28px;
        display: inline-block;
        vertical-align: middle;
        @include transition(all 0.3s ease);



    }
}

.filter-reset-btn {
    &:focus {
        outline: none;
    }
    &:hover {
        outline: 1px solid;
        outline-color: $highlight-color;
    }
    color: $highlight-color;
    width: 100%;
    box-shadow: $shadow-base-card;
}

.partlist-btn {
    height: 26px;
    line-height: 26px;
    padding: 0 10px;
    color: $color-white;
    border: none;
    background-color: $ci-color-1;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }
}
