.partnermanagement {

  .alert {
    text-align: center;
    font-size: medium;
    padding-top: 10px;

    .fa-close {
      color: $color-danger;
    }
  }

  .collapsible-panel {
    width: 100%;
    .collapsible-panel-content {
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }

  .title {
    span {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
    }
  }

  .title-input {
    .material-form-input {
      width: 300px;
      display: inline-block;
      margin-right: 10px;
      margin-top: -20px;
    }
    .fa-check {
      color: $color-success;
      font-size: 18px;
      vertical-align: middle;
    }
  }

  .partner-logo {
    height: auto;
    width: auto;
    max-width: 50px;
    max-height: 50px;
    background: $background-color;
  }

}

