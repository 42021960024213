// ======================= LOAD GLOBAL ELEMENTS =========================== //

@import '../styles/modules/all';

// =========================== CUSTOM STYLES ============================== //


/*
  Posible variable can be found in following SCSS Files:

  ../styles/modules/_colors.scss
  ../styles/modules/_fonts.scss
  ../styles/modules/_variables.scss
 */

.logo,
.product-logo {
  background-image: url("/assets/images/theme-reiff/reiff.png");
}

.header {
  .main-nav ul li:first-of-type {
    &:hover {
      background-color: white !important;
    }
  }
}

.card {
  .logo {
    width: 300px !important;
  }
}

$highlight-color: #E10A1A;
$header-background-color: #E10A1A;

$car-selection-background: url('/assets/images/global/background_auth.jpg') no-repeat center center fixed;


.center-panel {
  .heading {
    position: relative !important;
    top: -5px !important;
    z-index: 3;
  }

  .center-content {
    margin-top: -5px;
  }
}

.articleTabSet .nav.nav-tabs .nav-link.active, .left-panel .nav.nav-tabs .nav-link.active {
  border-bottom: none !important;
}

select::-ms-expand {
  display: none;
}

.header {
  .top-header {
    .right-header {
      .cooperation-image {
        display: none;
      }
    }

    .left-header {
      .burger-nav {
        .cooperation-image {
          display: none;
        }
      }
    }
  }
}

.card {
  .cooperation {
    display: none;
  }
}


// ================================ LAST ELEMENTS ========================== //

/*               MUST BE LOADED IN EVERY THEME AFTER OVERWRITES              */

// ======================================================================== //

@import 'includes';

