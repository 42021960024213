.transferLog {
  background-color: rgba(0, 0, 0, 0.75);
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: absolute;
  z-index: 1000;

  .background {
    background-color: white;
    overflow-x: auto;
    max-height: 82vh;
  }

  .transferLog-button {
    background-color: transparent;
    border: none;
    color: $font-color;
    float: right;
    font-size: 20px;
    &:hover {
      cursor: pointer;
      color: $highlight-color;
    }
    &:focus,
    &:active {
      outline: none;
    }
  }

  .request {
    margin-top: 20px;
  }
  .response {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .header-title {
    border-bottom: 2px solid $highlight-color;
    color: $font-color;
    font-weight: 600;
  }

  .transferLog-center {
    margin-top: 100px;
    margin-left: 24vw;
    margin-right: 25vw;
  }
}
