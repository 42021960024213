.form-input {
  width: 100%;
  float: left;
  font-size: 12px;
  margin-bottom: 15px;
  position: relative;

  i {
    display: block;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: $font-color;
    transition: all 0.2s ease-in-out;

    &.fa-times {
      position: absolute;
      right: 10px;

      &:hover {
        color: $color-danger;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .requiredBorder {
    background-color: $ci-color-7;
    border-color: $ci-color-7;
    color: $color-white;
  }

  label {
    text-transform: uppercase;
    padding-right: 10px;
    line-height: 29px;
    vertical-align: middle;
    margin-bottom: 0;
  }

  input, textarea {
    float: right;
    border: 1px solid #cccccc;
    padding: 0 5px;
    height: 30px;
    width: 100%;

    &.ng-invalid.ng-touched {
      border-color: $ci-color-7;
    }

    &:focus, &:active {
      outline: none;
    }

    &::-ms-clear {
      display: none;
    }
  }

  select {
    width: 100%;
    border: 1px solid #cccccc;
    height: 30px;
  }

}

.popup-form-input {
  width: 100%;
  float: left;
  font-size: 12px;
  margin-bottom: 15px;

  &.short {
    input, textarea, .nx-dropdown {
      width: 55%;
    }
  }

  &.colored {
    input, textarea, .nx-dropdown .dropdown-btn {
      background-color: $ci-color-3;
    }
  }

  &.with-tooltip {
    position: relative;

    input, textarea {
      float: left;
      width: 55%;
    }

    i {
      width: 5%;
      display: block;
      float: left;
      font-size: 18px;
      line-height: 34px;
      text-align: center;
      color: $font-color;
      transition: all 0.2s ease-in-out;

      &.fa-times {
        position: absolute;
        right: 30px;

        &:hover {
          color: $color-danger;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.double-input {
    input {
      float: left;
      width: 20%;
    }

    .nx-dropdown, .custom-dropdown {
      width: 30%;

      .dropdown {
        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
          border: 1px solid $ci-color-4;
          background-color: $color-white;
          //width: 37%;
          white-space: nowrap;
          position: absolute;
          top: 30px;
          left: unset;
          right: 0;
          z-index: 99;
          height: auto;
          max-height: 260px;
          overflow-y: auto;
        }
      }

      .dropdown-btn {
        float: left;
        //width: 37%;
        margin-left: 3%;
        height: 34px;

        span {
          min-width: unset;
        }

        .select-icon {
          height: 34px;
          line-height: 34px;

          i {
            line-height: 34px;
          }
        }
      }

    }
  }

  label {
    float: left;
    padding-right: 10px;
    line-height: 34px;
    vertical-align: middle;
    margin-bottom: 0;
    width: 40%;
    font-size: 14px;
    font-weight: normal;
    color: $font-color;
  }

  .nx-dropdown {
    float: left;
    width: 60%;

    .dropdown-btn {
      span {
        min-width: unset !important;
      }
    }
  }

  .custom-checkbox {
    float: left;
    padding: 0;
    width: 60%;
  }

  .file-upload {
    width: 60%;
    float: right;
  }

  input, textarea, .file-upload-input {
    float: right;
    padding: 0 7px;
    height: 34px;
    width: 60%;
    border: 1px solid transparent;
    font-weight: normal;
    @include border-radius(5px);

    &::-ms-clear {
      display: none;
    }

    &.ng-invalid.ng-touched {
      border-color: $ci-color-7;
    }

    &:focus, &:active {
      outline: none;
    }
  }

  textarea {
    resize: none;
    padding: 10px;
    height: unset;
  }

}

.search-field {
  position: relative;
  color: $font-color;
  line-height: normal;

  input {
    //height: 30px;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    padding: 0 10px;
    width: 100%;

    &::-ms-clear {
      display: none;
    }
  }

  i {
    display: block;
    position: absolute;
    right: 5px;
    top: 4px;
    color: $font-color;
    font-size: 20px;
  }
}

.form-control {
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0px;
  padding: 0px !important;
  color: $font-color;
  background: transparent !important;
  font-weight: 500;

  &:focus {
    border-color: $highlight-color;
  }
}

.numberType {
   text-align: right;
 }

input,
select {
  font-weight: 600;
  border-bottom: 1px solid lighten($font-color, 10);
  //padding: 1rem 0rem !important;
  &:-ms-input-placeholder {
    color: $ci-color-16 !important;
  }

  &::-moz-placeholder {
    color: $ci-color-16 !important;
  }

  &::-webkit-input-placeholder {
    color: $ci-color-16 !important;
  }

  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
    &:-ms-input-placeholder {
      color: lighten($font-color, 30);
    }

    &::-moz-placeholder {
      color: lighten($font-color, 30);
    }

    &::-webkit-input-placeholder {
      color: lighten($font-color, 30);
    }
  }
}

.material-form-input {
  position: relative;
  padding: 0;
  margin: 0.5rem 0;
  border: none;
  overflow: visible;

  input {
    background-color: $color-white;
  }

  .validationErrorMessage {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: $color-danger;
  }

  &.disabled {
    &::after {
      font-family: 'FontAwesome', serif;
      content: '\f023';
      position: absolute;
      right: 1rem;
      top: 1rem;
      color: grey;
    }
  }

  .numberType {
    input {
      text-align: right;
    }
  }

  &.show-validation {
    &.ng-touched {
      &.ng-invalid {
        input {
          border-bottom: 2px solid $color-danger !important;
        }

        hr {
          background: $color-danger !important;
        }
      }
    }

    &.show-error {
      input {
        border-bottom: 2px solid $color-danger !important;
      }

      hr {
        background: $color-danger !important;
      }
    }
  }


  //&::after {
  //  font-family: 'FontAwesome', serif;
  //  content: '\f023';
  //  position: absolute;
  //  right: 0;
  //  top: 1rem;
  //  color: grey;
  //}

  input {
    box-sizing: border-box;
    width: 100%;
    padding: 8px 10px 8px;
    border: none;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid lighten($font-color,50);
    font-size: 1rem;
    font-weight: 400 !important;
    color: $font-color !important;
    outline: none;
    cursor: text;
    padding-bottom: 0.6rem !important;

    &::-webkit-input-placeholder {
      transition: color 300ms ease;
    }
    &:not(:focus)::-webkit-input-placeholder {
      color: transparent;
    }
    &:disabled {
      color: grey;
    }

  }

  hr {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 2px;
    border: none;
    background: $highlight-color;
    font-size: 1px;
    will-change: transform, visibility;
    transition: all 200ms ease-out;
    transform: scaleX(0);
    visibility: hidden;
    z-index: 10;
  }
  input:focus ~ hr {
    transform: scaleX(1);
    visibility: visible;
  }

  label {
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 1rem;
    font-weight: 400;
    transform-origin: 0 -150%;
    transition: transform 300ms ease;
    pointer-events: none;
    color: lighten($font-color,25);
  }
  input:focus ~ label,
  input:disabled ~ label,
  input:invalid ~ label,
  input:valid ~ label {
    transform: scale(0.7);
  }
}

.search-input {
  //background: darken($background-color,3);
  border-bottom: 1px solid darken($background-color, 3);

  .search-label {
    color: $font-color-dark !important;
    margin: 1rem 0rem;
  }
}
.firmdataCSS {

  .hover_red {
    &:hover {
      color: $color-danger;
    }
  }

  .datepicker {
    border: none;
    background-color: white;
    color: black;
    font-weight: 600;
    font-size: 17px;
    border-bottom: 1px solid #b8bec2;
    padding-bottom: 0.5rem !important;
    width: calc(25vw - 58px);
  }

  .input-group-addon {
    cursor: pointer;
    border: none;
    border-bottom: 1px solid #b8bec2;
    background-color: transparent;
    color: black;
    &:focus {
      border: none;
      border-bottom: 1px solid #b8bec2;
    }
    &:active {
      border: none;
      border-bottom: 1px solid #b8bec2;
    }
  }

  .input-group {
    &.showDisabled {
      &:after {
        content: "\f023" !important;
        font-family: "FontAwesome", sans-serif;
        color: grey;
        font-size: 1rem;
        right: 0.5rem;
        border-bottom: 1px solid #b8bec2;
      }
      .form-control {
        border-bottom: 1px solid #b8bec2 !important;
        color: grey;
        font-weight: 600;
        font-size: 17px;
        padding-bottom: 0.5rem !important;
      }
    }
    margin-top: 10px;
    .form-control {
      border-bottom: 1px solid #b8bec2 !important;
      color: black;
      font-weight: 600;
      font-size: 17px;
      padding-bottom: 0.5rem !important;
    }
  }
  .input-group-label {
    font-weight: 400;
    transform: scale(0.7);
    color: #b7bec2;
    transform-origin: 0 0;
    position: absolute;
  }

  .clickable {
    cursor: pointer;
  }

  .dropdown-label {
    font-weight: 400;
    transform: scale(0.7);
    color: #b7bec2;
    transform-origin: 0 0;
    position: absolute;
  }
  .custom-dropdown {
    &.icon_lock {
      &:after {
        content: "\f023" !important;
        font-family: "FontAwesome", sans-serif;
        color: grey;
        font-size: 1rem;
        right: 0.5rem;
      }
    }
    select:disabled {
      color: grey;
      //font-weight: 600 !important;
      //font-size: 17px;
    }
    select {
      //color: black;
      //font-weight: 600 !important;
      //font-size: 17px;
    }
  }
}
.form-control {
  &[disabled], &:disabled {
    color: #b7bec2 !important;
  }
}
.global-supplier {
  input#file {
    margin-left: 2rem;
  }
}
