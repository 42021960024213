.setting-list {

  .setting-tabset {
    .tab-content {
      .tab-pane {
        margin-right: -13px;
      }
    }
  }

  .list-wrapper {
    margin-left: -12px;
    margin-right: -12px;
    &.drag-list {
      margin-left: -12px;
      margin-right: -12px;
      width: auto;
      .drag-box {
        margin-left: 0;
        margin-right: 0;
        padding: 12px 5px 7px !important;

        .club-logo {
          margin-top: 5px;
          border-top: 1px solid #cccccc;
          .checkbox {
            input {
              margin-top: 15px;
            }
          }

          .club-logo-img {
            margin-top: 0 !important;
          }
        }

        .multiple-settings-icon {
          .fa-cog {
            margin-right: 0.5rem;
            font-size: 1.7rem;
            &:hover {
              cursor: pointer;
              color: $highlight-color;
            }
            &.active {
              color: $highlight-color;
            }
          }
        }
      }
    }
  }
}
