@mixin placeholder {
  &::placeholder               {@content}
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

app-direct-search,
.direct-search{
  button {
    border-right: none;
    border-left: unset;
    border-top: unset;
  }


  .nx-dropdown {
    .nx-dropdown-button button {
      border: 1px solid red;
    }
    .nx-dropdown-menu {
      ul {
        font-size: 1rem;
        font-weight: 600;
        top: 29px;
        margin-left: -1px;
        text-align: right;
        border: 1px solid white;
        border-top: none;
        width: calc(100% + 1px);
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
        li {
          padding: 5px 10px;
          text-align: center;
        }
      }
    }
  }

  width: 24vw;
  //padding-left: 18px;


  .search-field {
    position: relative;

    .fa-times {
      right: 30px;
      top:10px;
      transition: all 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        color: $color-danger;
      }
    }

    app-dropdown {
      width: 40%;
      float: left;
      background-color: transparent;
      color: $color-black;
      padding-top: 5px;
      .nx-dropdown{
        .nx-dropdown-button {
          height: 30px;
          background-color: #f5f5f5;
          color: $color-black;
          border: 1px solid $color-white;
          border-right: none;
          font-size: 1rem;
          font-weight: 600;
          text-align: center;
          padding: 0 10px;
          span {
            float: unset;
            margin-right: 0;
          }
          .fa-caret-down {
            background-color: #f5f5f5;
            color: $color-black;
            height: 28px;
            width: 25px;
            &:before {
              position: absolute;
              margin-top: -6px;
              font-size: 20px;
              right: 6px;
            }
          }
        }
        .nx-dropdown-menu {
          background-color: $color-white;
          border: 1px solid $color-black;
          color: $color-black;
        }
      }
    }
    input {
      float: right;
      width: 60%;
      padding-right: 50px;
      border-radius: 0px;
      padding-left: 10px!important;
      font-weight: 600;
      height: 30px;
      margin-top: 5px;
      border: 1px solid $background-color;
      color: $color-white;
      font-size: 1rem;
      @include placeholder {
        color: $color-white ;
      }
      &:focus{
        color: $color-white;
      }
    }

    input::placeholder {
      opacity: 1 !important;
      color: $color-white !important;
      padding-left: 15px;
    }

    i {
      top: 6px;
      right: 0px;
      font-size: 1.3rem;
      &.fa-search{
        cursor: pointer;
        color: $color-white;
        &:before {
          margin-top: 5px;
          left: -22px;
          position: absolute;
        }
      }
    }
  }

  .search-history {
    position: absolute;
    top: 40px;
    width: 100%;
    z-index: 999;

    ul {  list-style: none;
      padding: 0px;
      box-shadow: $shadow-base-light;
      background-color: $background-color;
      li {
        padding: 0.5rem 1rem;
        box-shadow: $shadow-base-extra-light;
        border-top: 1px solid darken($background-color,20);
        transition:
                all        0.2s;
        &:hover {
          background-color: white;
          cursor: pointer;
        }

        &:nth-child(2) {
          padding-top: 10px;
        }
      }
    }

    .search-history-label {
      display: inline-block;
      font-size: 10px;
    }

    span {
      font-size: 10px;
      margin-right: 10px;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
